import { gql, useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';
import { Alert, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExtendedProcessItem, ProcessItem } from '../../../../../__generated__/graphql';

const REMOVE_ITEMS = gql(`
    mutation DeleteProcessItemAndDocs($processItemId: ID!, $workspaceId: String!) {
        deleteProcessItemAndDocs(processItemId: $processItemId, workspaceId: $workspaceId)
    }
`);

const ItemCardComponent: React.FC<{
    item: ProcessItem | ExtendedProcessItem,
    remove: boolean,
    inCompleteFlow: boolean,
    refetchGetItems?: () => void;
    loadingOnStep?: boolean;
    continueFlow?: (id: string) => void;
    loadingContinueFlow?: boolean;

}> = ({ item, remove, inCompleteFlow, refetchGetItems, loadingOnStep, continueFlow, loadingContinueFlow }) => {
    const { workspaceId } = useParams() as { workspaceId: string };
    const [removeProcessItem, { loading, error }] = useMutation(REMOVE_ITEMS);
    const [openDialog, setOpenDialog] = useState(false);

    const handleRemoveItem = (id: string) => {
        if (!id) return;

        removeProcessItem({
            variables: {
                processItemId: id,
                workspaceId,
            },
            onCompleted: () => {
                setOpenDialog(false);
                if (refetchGetItems) {
                    refetchGetItems();
                }
            },
        });
    };

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const statusColor = item.status ===
        'UNPROCESSED' ? '#FFA5000' :
        item.status === 'PROCESSING' ? '#08CD92' :
            item.status === 'LOADED' ? 'blue' :
                item.status === 'ERROR' ? 'red' : 'inherit';

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '570px',
                    background: '#FBFBFB',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                    borderRadius: 3,
                    gap: 1,
                    mb: 2,
                    mt: 2,
                    p: 1.5,
                    pl: 2,
                    position: 'relative'
                }}
            >
                <Box sx={{ position: 'relative', m: 1 }}>
                    <FolderIcon sx={{ width: '30px', height: 'auto' }} />
                    {item.status === 'PROCESSING' && (
                        <Box sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 7,
                            width: 11,
                            height: 11,
                            border: '2px solid #FFFFFF',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#00e083',
                        }} />)}
                </Box>
                <Box>
                    <Typography variant='subtitle1' fontWeight={500}>
                        {item.title || 'Folder Title'}
                    </Typography>
                    {remove && (
                        <IconButton disabled={loading} onClick={handleClickOpen} sx={{ position: 'absolute', right: 6, top: 6 }}>
                            <CloseIcon sx={{ width: '20px' }} />
                        </IconButton>
                    )}
                    {loadingOnStep ? (
                        <LinearProgress variant='indeterminate' sx={{ width: '100%', borderRadius: 5 }} />
                    ) :
                        <Typography variant='body2' fontWeight={500} fontSize={13} sx={{ color: statusColor }}>
                            {item.status === 'UNPROCESSED' ? 'Unprocessed' : item.status === 'PROCESSING' ? 'Processing' : item.status === 'LOADED' ? 'Loaded' : item.status}
                            {item.status === 'ERROR' && <span style={{ fontWeight: 'normal' }}>{` ${item.statusCode}`}</span>}
                        </Typography>
                    }
                    <Box >
                        <Typography variant='body2' fontSize={11} marginTop={0.5} marginBottom={0.5} sx={{ textOverflow: 'ellipsis', }}>
                            {item.url}
                        </Typography>
                        {inCompleteFlow && (item.status !== 'PROCESSING' && item.status !== 'ERROR') && continueFlow && (
                            <Button
                                onClick={() => continueFlow(item.id)}
                                variant='contained'
                                size='small'
                                disabled={loading || loadingContinueFlow}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    mt: 0.5,
                                    backgroundColor: 'orange',
                                    pt: 0,
                                    pb: 0,
                                    '&:hover': {
                                        backgroundColor: '#e87305',
                                    }
                                }}>
                                Continue flow
                            </Button>
                        )}
                    </Box>
                </Box>
            </Card>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete the folder {/*or the folder*/} and all its documents?
                    </DialogContentText>
                    {error && <Alert severity="error">{error.message}</Alert>}
                </DialogContent>
                <DialogActions sx={{ pb: 3, display: 'flex', justifyContent: 'center' }}>
                    {/* <Button variant='contained' disabled={loading} onClick={() => handleRemoveItem(item.id)}>Just the folder</Button> */}
                    <Button variant='contained' disabled={loading}
                        sx={{ backgroundColor: 'red', ':hover': { backgroundColor: '#cc0808' } }}
                        onClick={() => handleRemoveItem(item.id)} autoFocus>
                        Folder and all related documents
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ItemCardComponent;
