import { useMutation } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '../../../../../../__generated__/gql';
import { ExtendedProcessItem } from '../../../../../../__generated__/graphql';
import { DocumentsCards } from '../../Integrations';
import LeftSideComponent from '../../LeftSideComponent';
import FifthStepItem from './FifthStepItem';
import FirstStepItem from './FirstStepItem';
import FourthStepItem from './FourthStepItem';
import SecondStepItem from './SecondStepItem';
import ThirdStepItem from './ThirdStepItem';

const GOOGLE_FOLDER_TO_DATABASE = gql(`
    mutation GoogleFolderToDataBase($url: String!, $integrationId: ID!, $workspaceId: String!) {
        googleFolderToDataBase(url: $url, integrationId: $integrationId, workspaceId: $workspaceId) {
        url
        updatedAt
        title
        statusCode
        status
        integrationId
        id
        externalId
        createdAt
        }
    }
`);


const GET_GOOGLE_FILES_FROM_THE_FOLDER = gql(`
mutation GetGoogleFilesFromTheFolder($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {
    getGoogleFilesFromTheFolder(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {
      id
      title
      integrationId
      externalId
      status
      statusCode
      url
      createdAt
      updatedAt
      processItemDocument {
        id
        processItemId
        author
        title
        uri
        source
        type
        externalId
        status
        statusCode
        metadata
        documentId
      }
    }
  }
`);

const PUT_GOOGLE_DOCUMENT_CONTENT_TO_DB = gql(`
mutation PutGoogleDocumentContentToDB($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {
    putGoogleDocumentContentToDB(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {
      id
      title
      integrationId
      externalId
      status
      statusCode
      url
      createdAt
      updatedAt
      processItemDocument {
        id
        processItemId
        author
        title
        uri
        source
        type
        externalId
        status
        statusCode
        metadata
        documentId
      }
    }
  }
`);

const LEARN_FROM_GOOGLE_DOCUMENTS = gql(`
    mutation LearnFromGoogleDocuments($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {
        learnFromGoogleDocuments(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {
        id
        title
        integrationId
        externalId
        status
        statusCode
        url
        createdAt
        updatedAt
        processItemDocument {
            id
            processItemId
            author
            title
            uri
            source
            type
            externalId
            status
            statusCode
            metadata
            documentId
        }
        }
    }
`);
interface AddItemComponentProps {
    integration: DocumentsCards;
    refetchIntegrations: () => void;
    onCancel: () => void;
    refetchGetItems: () => void;
    onClose: () => void;
    processItemContinue?: ExtendedProcessItem;
    continueStep?: string;
}

const AddItemComponent = ({ continueStep, processItemContinue, onClose, integration, refetchIntegrations, onCancel, refetchGetItems }: AddItemComponentProps) => {
    const [step, setStep] = useState('addLink');
    const { workspaceId } = useParams();
    const integrationId = integration?.integration?.id;
    const [googleFolderToDataBase, {
        data: googleFolderToDBData,
        loading: googleFolderToDBLoading,
        error: googleFolderToDBError }] = useMutation(GOOGLE_FOLDER_TO_DATABASE);

    const [getDocsList, {
        data: getDocsListData,
        loading: getDocsListLoading,
        error: getDocsListError }] = useMutation(GET_GOOGLE_FILES_FROM_THE_FOLDER);

    const [putGoogleDocumentContentToDB, {
        data: contentToDBData,
        loading: contentToDBLoading,
        error: contentToDBError }] = useMutation(PUT_GOOGLE_DOCUMENT_CONTENT_TO_DB);

    const [learnFromGoogleDocuments, {
        data: learnData,
        loading: learnLoading,
        error: learnError }] = useMutation(LEARN_FROM_GOOGLE_DOCUMENTS);

    const handleLinkSubmit = (link: string) => {
        googleFolderToDataBase({
            variables: {
                url: link,
                integrationId: integrationId!,
                workspaceId: workspaceId!,
            },
        }).then((response) => {
            if (response?.data?.googleFolderToDataBase) {
                setStep('confirmLinkAdnExtractFiles');
            }
        }).catch((error) => {
            console.error("Error executing mutation:", error);
        });
    };

    useEffect(() => {
        if (continueStep) {
            setStep(continueStep);
        }
    }, [continueStep]);

    const handleGetDocsList = () => {
        getDocsList({
            variables: {
                processItemId: googleFolderToDBData?.googleFolderToDataBase.id! || processItemContinue?.id!,
                integrationId: integrationId!,
                workspaceId: workspaceId!,
            },
        }).then((response) => {
            if (response?.data?.getGoogleFilesFromTheFolder) {
                setStep('confirmExtractedFilesAndExtractContent');
            }
        }).catch((error) => {
            console.error("Error executing mutation:", error);
        });
    };

    const handleContentToDB = () => {
        putGoogleDocumentContentToDB({
            variables: {
                processItemId: googleFolderToDBData?.googleFolderToDataBase.id! || processItemContinue?.id!,
                integrationId: integrationId!,
                workspaceId: workspaceId!,
            },
        }).then((response) => {
            if (response?.data?.putGoogleDocumentContentToDB) {
                setStep('confirmExtractedContentAndLearn');
            }
        }).catch((error) => {
            console.error("Error executing mutation:", error);
        });
    };

    const handleLearn = () => {
        learnFromGoogleDocuments({
            variables: {
                processItemId: googleFolderToDBData?.googleFolderToDataBase.id! || processItemContinue?.id!,
                integrationId: integrationId!,
                workspaceId: workspaceId!,
            },
        }).then((response) => {
            if (response?.data?.learnFromGoogleDocuments) {
                setStep('confirmLearnDone');
            }
        }).catch((error) => {
            console.error("Error executing mutation:", error);
        });
    };

    const handleLearnDone = () => {
        refetchGetItems();
        onCancel();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <LeftSideComponent
                integration={integration}
                refetchIntegrations={refetchIntegrations}
                onClose={onClose}
            />
            <Grid item xs={12} sm={10} sx={{ padding: 4, overflow: 'scroll', height: '500px' }}>
                {step === 'addLink' &&
                    <FirstStepItem
                        onLinkSubmit={handleLinkSubmit}
                        onCancel={onCancel}
                        loading={googleFolderToDBLoading}
                        error={googleFolderToDBError?.message}
                    />}

                {step === 'confirmLinkAdnExtractFiles' &&
                    <SecondStepItem
                        onItemSumbit={handleGetDocsList}
                        data={processItemContinue || googleFolderToDBData?.googleFolderToDataBase}
                        loading={getDocsListLoading}
                        error={getDocsListError?.message}
                        onBack={() => setStep('addLink')}
                    />}

                {step === 'confirmExtractedFilesAndExtractContent' &&
                    <ThirdStepItem
                        onDocumentsSubmit={handleContentToDB}
                        data={processItemContinue || getDocsListData?.getGoogleFilesFromTheFolder}
                        onBack={() => setStep('confirmLinkAdnExtractFiles')}
                        loading={contentToDBLoading}
                        error={contentToDBError?.message}
                    />}
                {step === 'confirmExtractedContentAndLearn' &&
                    <FourthStepItem
                        contentToDBData={processItemContinue || contentToDBData?.putGoogleDocumentContentToDB}
                        handleLearn={handleLearn}
                        onBack={() => setStep('confirmExtractedFilesAndExtractContent')}
                        learnLoading={learnLoading}
                        learnError={learnError?.message}
                    />}
                {step === 'confirmLearnDone' &&
                    <FifthStepItem
                        handleLearnDone={handleLearnDone}
                        learnedContent={learnData?.learnFromGoogleDocuments}
                        onBack={() => setStep('confirmExtractedContentAndLearn')}
                    />}
            </Grid>
        </Box>
    );
};

export default AddItemComponent;
