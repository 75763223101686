import { useMutation } from '@apollo/client';
import { Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '../../../../__generated__/gql';
import { useAuth } from '../../../../context/AuthContext';
import { useUserWorkspaces } from '../../../../context/WorkspaceContext';
import { stringAvatar } from '../../../Common/Generator';

const DELETE_WORKSPACE = gql(`
  mutation DeleteWorkspace($workspaceId: String!) {
    deleteWorkspace(workspaceId: $workspaceId)
  }
`);

const CHANGE_WORKSPACE_TITLE = gql(`
mutation ChangeWorkspaceTitle($newTitle: String!, $workspaceId: String!) {
  changeWorkspaceTitle(newTitle: $newTitle, workspaceId: $workspaceId)
}
`);

const WorkspaceSettings = () => {
  const navigate = useNavigate();
  const { activeWorkspace } = useUserWorkspaces();
  const { user } = useAuth();
  const [titleInput, setTitleInput] = useState(activeWorkspace?.title || '');
  const [openDialog, setOpenDialog] = useState(false);

  const [deleteWorkspace, { loading: deletionLoading }] = useMutation(DELETE_WORKSPACE, {
    variables: { workspaceId: activeWorkspace?.workspaceId! },
    onCompleted: () => {
      navigate('/workspaces');
    },
  });

  const [changeWorkspaceTitle, { loading: changeTitleLoading }] = useMutation(CHANGE_WORKSPACE_TITLE);

  const handleDeleteWorkspace = () => {
    setOpenDialog(true);
  };

  const confirmDeleteWorkspace = () => {
    deleteWorkspace().catch(error => {
    });
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close dialog without deleting
  };

  const handleChangeWorkspaceTitle = (newTitle: string) => {
    changeWorkspaceTitle({
      variables: { workspaceId: activeWorkspace?.workspaceId!, newTitle: newTitle },
    });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleInput(event.target.value);
  };

  return (
    <Box sx={{ mt: 5, mb: 5, p: 1 }}>
      <Typography variant='h5' fontWeight={500} mb={1}> Workspace Settings: </Typography>
      <Card sx={{ p: 3, position: 'relative' }}>

        <Stack spacing={2} sx={{ p: 2 }}>
          <Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant='subtitle1' fontWeight={500} mb={1}> Workspace name: </Typography>
              <Typography variant='body2' color={'grey'}> ID: {activeWorkspace?.workspaceId} </Typography>
            </Box>
            <Box display={'flex'} gap={2} alignItems={'center'}>
              <Avatar {...stringAvatar(activeWorkspace?.title ?? "WS", false)} />
              <TextField
                value={titleInput}
                onChange={handleTitleChange}
                disabled={changeTitleLoading || deletionLoading}
                variant="outlined"
                fullWidth
              />
            </Box>
            {titleInput !== activeWorkspace?.title && titleInput.trim() !== '' && (
              <Button fullWidth disabled={deletionLoading || changeTitleLoading} onClick={() => handleChangeWorkspaceTitle(titleInput)} sx={{ mt: 2 }} variant="contained">
                {changeTitleLoading ? 'Loading..' : `Change Title`}
              </Button>
            )}
          </Box>

          {/* <Typography variant='body1'>
            Status: {activeWorkspace?.subscription?.status}
          </Typography> */}

          {user?.role === 'SUPERADMIN' && (
            <>
              <Button
                onClick={handleDeleteWorkspace}
                disabled={deletionLoading}
                sx={{
                  color: 'red',
                  '&:hover': {
                    backgroundColor: '#fcf0f0',
                  },
                }}>
                {deletionLoading ? `Deletion in process..` : `Delete workspace`}
              </Button>
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Workspace Deletion"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this workspace? This action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={confirmDeleteWorkspace} sx={{ color: 'red' }} autoFocus>
                    Confirm Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
}

export default WorkspaceSettings;
