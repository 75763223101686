import { useMutation } from "@apollo/client";
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Form } from "react-router-dom";
import * as Yup from "yup";
import { gql } from "../../__generated__";
import { logEvent } from "../../analytics";

const INVITE_USER = gql(`
  mutation InviteUsersToWorkspace($emails: [String!]!, $workspaceId: String!) {
    inviteUsersToWorkspace(emails: $emails, workspaceId: $workspaceId) {
      workspaceId
      userId
      role
      joined
      joinedAt
      createdAt
    }
  }
`);

export function WorkspaceInviteUserDialog({
  onSubmit,
  onClose,
  open,
  workspaceId,
}: {
  onSubmit: (meeting: any | undefined | null) => Promise<void>;
  onClose: () => void;
  open: boolean;
  workspaceId: string;
}) {
  const [inviteUsers, { loading, error }] = useMutation(INVITE_USER);

  const formik = useFormik({
    initialValues: {
      emails: [''],
    },
    validationSchema: Yup.object({
      emails: Yup.array().of(Yup.string().email('Invalid email').required('Required')),
    }),
    onSubmit: async ({ emails }) => {
      const res = await inviteUsers({
        variables: {
          emails,
          workspaceId,
        },
      });
      await onSubmit(res.data);
      logEvent('invite_users', 'invite_users_to_workspace');
    },
  });

  const handleEmailChange = (index: number, email: string) => {
    const newEmailList = [...formik.values.emails];
    newEmailList[index] = email;
    formik.setFieldValue('emails', newEmailList);
  };

  const addEmailField = () => {
    formik.setFieldValue('emails', [...formik.values.emails, '']);
  };

  const removeEmailField = (index: number) => {
    const newEmailList = [...formik.values.emails];
    newEmailList.splice(index, 1);
    formik.setFieldValue('emails', newEmailList);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>Invite users to workspace</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter email addresses of the users you are inviting to the workspace</DialogContentText>
            {formik.values.emails.map((email, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <TextField
                  type="email"
                  fullWidth
                  placeholder="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  error={!!(formik.touched.emails && formik.errors.emails && formik.errors.emails[index]) || !!error}
                  helperText={(formik.touched.emails && formik.errors.emails && formik.errors.emails[index]) || (error?.message || null)}
                  autoComplete="off"
                />
                {index > 0 && (
                  <IconButton onClick={() => removeEmailField(index)} sx={{ color: "#adacac" }}>
                    <CancelIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button onClick={addEmailField} sx={{ mt: 2 }}>
              Add Another Email
            </Button>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button disabled={formik.isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={loading} disabled={!formik.isValid || !formik.dirty}>
              Invite Users
            </LoadingButton>
          </DialogActions>
        </Box>
      </Form>
    </Dialog>
  );
}