import { gql, useMutation } from '@apollo/client';
import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert, Box, Button, IconButton, LinearProgress, List, ListItem, ListItemText, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { filesize } from 'filesize';
import { useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DocumentStatus } from '../../../__generated__/graphql';
import { logEvent } from '../../../analytics';
import { getConfig } from '../../../config';
import { auth } from '../../../firebase';
import AiIllustrationIcon from "../../Common/images/AiIllustrationIcon.svg";
import DocLoadB from "../../Common/images/docLoadB.png";
import useDragAndDrop from '../../Common/useDragAndDrop';
import { SuccessDialog, TrainingDialog } from './LoadDataDislog';

const UPLOAD_AND_TRAIN_MUTATION = gql`
  mutation UploadAndTrain($workspaceId: String!, $documents: [DocumentInput!]!) {
    uploadAndTrain(workspaceId: $workspaceId, documents: $documents)
  }
`;

const LIST_UNPROCESSED_LOCAL_DOCUMENTS = gql`
    mutation ListUnprocessedLocalDocuments($workspaceId: String!) {
        listUnprocessedLocalDocuments(workspaceId: $workspaceId) {
            title
            status
            size
            id
        }
    }
`;

interface File {
    name: string;
    size: string;
    status?: DocumentStatus;
    progress: number;
    uniqueId?: string;
    isCancelling?: boolean;
    error?: { code: number; message: string } | null;
}

interface UploadedFile {
    status: DocumentStatus;
    originalname: string;
    id: string;
}

interface UploadError {
    code: number;
    originalname: string;
    message: string;

}

const LoadDataScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { workspaceId } = useParams();
    const [user] = useAuthState(auth);
    const [uploadAndTrain, { loading: trainLoading, error: trainingError }] = useMutation(UPLOAD_AND_TRAIN_MUTATION);
    const [listUnprocessedDocuments, {
        loading: unprocessedDocumentsLoading
    }] = useMutation(LIST_UNPROCESSED_LOCAL_DOCUMENTS);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [authToken, setAuthToken] = useState('');
    const [isUploadProcessing, setIsUploadProcessing] = useState(false);
    const [isRemoveProcessing, setisRemoveProcessing] = useState(false);

    const onTrainingSuccess = () => {
        setShowSuccessDialog(true);
        logEvent('upload_and_train', 'upload_and_train_success');
    };

    //    if (user) { user.getIdToken().then(token => { setAuthToken(token) }); }
    const tokenFetched = useRef(false);
    if (user && !tokenFetched.current) {
        user.getIdToken().then(token => {
            setAuthToken(token);
            tokenFetched.current = true;
        });
    }

    const documentsFetched = useRef(false);
    if (workspaceId && !documentsFetched.current) {
        documentsFetched.current = true;
        listUnprocessedDocuments({ variables: { workspaceId } })
            .then(response => {
                const unprocessedDocumentsData = response.data;
                setIsUploadProcessing(true);
                if (unprocessedDocumentsData) {
                    const newFiles = unprocessedDocumentsData.listUnprocessedLocalDocuments.map((doc: { title: string; id: string; size: number; status: DocumentStatus }) => ({
                        name: doc.title,
                        size: filesize(doc.size),
                        sizeInBytes: doc.size,
                        status: doc.status,
                        progress: 100,
                        uniqueId: doc.id,
                        isCancelling: false,
                        error: null
                    }));
                    setLoadedFiles(currentFiles => [...currentFiles, ...newFiles]);
                }
                setIsUploadProcessing(false);
            })
            .catch(error => {
                documentsFetched.current = false;
            });
    }


    const initialFiles = location.state?.files?.map((file: { name: any; size: number; }) => ({
        name: file.name,
        size: filesize(file.size),
        progress: 0,
    })) || [];

    const [loadedFiles, setLoadedFiles] = useState<File[]>(initialFiles);
    const [uploadErrors, setUploadErrors] = useState<string[]>([]);
    const [currentTotalSize, setCurrentTotalSize] = useState(0);
    // const maxBatchSize = 31457280; 
    const maxBatchSize = 157286400;

    const validateFiles = (newFiles: globalThis.File[], existingFiles: File[]): File[] => {
        let totalBatchSize = existingFiles.reduce((acc, file) => acc + parseInt(file.size), 0);

        const validatedFiles = newFiles.map(file => {
            let error = null;

            if (existingFiles.some(f => f.name === file.name)) {
                error = { code: 409.1, message: `File with this name already exists in the batch.` };
            } else if (file.size > maxBatchSize) {
                error = { code: 413, message: "The file size limit is 30MB." };
            } else if (!/\.(txt|pdf|doc|docx|zip)$/i.test(file.name)) {
                error = { code: 415, message: "Unsupported media type. Accepted: .txt, .pdf, .docx, .zip." };
            } else if ((totalBatchSize + file.size) > maxBatchSize) {
                error = { code: 413, message: "Batch size limit reached. (30MB)" };
            }

            totalBatchSize += file.size;

            return {
                name: file.name,
                size: filesize(file.size),
                progress: 0,
                error: error
            };
        });

        return [...existingFiles, ...validatedFiles];
    };

    const shouldShowBox = (file: File) => {
        return file.progress < 100;
    };


    const uploadFilesToServer = async (files: globalThis.File[]) => {
        setIsUploadProcessing(true);
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));
        console.log('Files:', files)
        console.log('Load files:', loadedFiles)
        if (workspaceId) {
            formData.append('workspaceId', workspaceId);
        }

        const updateProgressToComplete = () => {
            setLoadedFiles(currentFiles => currentFiles.map(file => ({ ...file, progress: 100 })));
        };

        try {
            const response = await fetch(`${getConfig().api.baseUrl}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
            });

            const result = await response.json();

            if (result.ok && result.uploadedFiles) {
                setLoadedFiles(currentFiles => currentFiles.map(file => {
                    const uploadedFile = result.uploadedFiles.find((uf: UploadedFile) => uf.originalname === file.name);
                    return uploadedFile ? { ...file, uniqueId: uploadedFile.id, progress: 100, error: null } : file;
                }));
                updateProgressToComplete();
            } else if (!result.ok && result.uploadErrors && result.uploadedFiles) {
                setLoadedFiles(currentFiles => {
                    return currentFiles.map(file => {
                        const uploadError = result.uploadErrors.find((error: UploadError) => error.originalname === file.name);
                        const uploadedFile = result.uploadedFiles.find((uf: UploadedFile) => uf.originalname === file.name);

                        return {
                            ...file,
                            uniqueId: uploadedFile ? uploadedFile.id : file.uniqueId,
                            error: uploadError ? { code: uploadError.code, message: uploadError.message } : null
                        };
                    });
                });
                updateProgressToComplete();
            }

        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadErrors(prevErrors => [...prevErrors, 'Unknown error occurred during file upload']);
            setLoadedFiles(currentFiles => currentFiles.map(file => ({
                ...file,
                error: { code: 500, message: "Unexpected loading error" },
                progress: 100
            })));
        } finally {
            setIsUploadProcessing(false);
            updateProgressToComplete();
        }
    };


    const handleCancel = async (fileOrFiles: string | File[]) => {
        setisRemoveProcessing(true);
        let filesToCancel: File[] = [];

        if (typeof fileOrFiles === 'string') {
            filesToCancel = loadedFiles.filter(file => file.name === fileOrFiles);
        } else {
            filesToCancel = fileOrFiles;
        }

        setLoadedFiles(currentFiles =>
            currentFiles.map(file =>
                filesToCancel.some(f => f.uniqueId ? f.uniqueId === file.uniqueId : f.name === file.name) ? { ...file, isCancelling: true } : file
            )
        );

        await Promise.all(filesToCancel.map(async (file, index) => {
            try {
                if (file.uniqueId) {
                    await fetch(`${getConfig().api.baseUrl}/upload/delete/${file.uniqueId}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                    });
                }
            } catch (error) {
                console.error(`Error cancelling file ${file.name}:`, error);
                setLoadedFiles(currentFiles => currentFiles.map((f, idx) =>
                    f.uniqueId ? (f.uniqueId === file.uniqueId ? { ...f, error: { code: 500, message: `Error cancelling file ${file.name}:`, error } } : f) :
                        (f.name === file.name && idx === index ? { ...f, error: { code: 500, message: `Error cancelling file ${file.name}:`, error }, isCancelling: false } : f)
                ));
            }
        }));

        setLoadedFiles(currentFiles => currentFiles.filter((file, idx) =>
            !filesToCancel.some(f => (f.uniqueId && f.uniqueId === file.uniqueId) ||
                (!f.uniqueId && f.name === file.name && idx === loadedFiles.indexOf(f)))
        ));

        setLoadedFiles(currentFiles => {
            const newTotalSize = currentFiles.reduce((acc, file) => acc + parseInt(file.size), 0);
            setCurrentTotalSize(newTotalSize);
            return currentFiles;
        });

        setisRemoveProcessing(false);
    };



    //D&D
    const handleFilesDropped = async (droppedFiles: globalThis.File[]) => {
        setUploadErrors([]);

        const updatedFiles = validateFiles(droppedFiles, loadedFiles);
        setLoadedFiles(updatedFiles);

        const validFilesToUpload = droppedFiles.filter(file => {
            const correspondingFileObject = updatedFiles.find(f => f.name === file.name);
            return correspondingFileObject && correspondingFileObject.error === null;
        });

        let newSize = currentTotalSize + validFilesToUpload.reduce((acc, file) => acc + file.size, 0);
        setCurrentTotalSize(newSize);

        await uploadFilesToServer(validFilesToUpload);
    };

    const { bind, SuperDiv } = useDragAndDrop(handleFilesDropped);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            handleFilesDropped(files);
        }
    };

    const handleStartLearning = async () => {
        const documents = loadedFiles
            .filter(file => file.uniqueId && file.error === null)
            .map(file => ({
                id: file.uniqueId,
                originalname: file.name
            }));

        if (documents.length === 0 || !workspaceId) {
            console.error('No documents to process or missing workspace ID');
            return;
        }

        try {
            const response = await uploadAndTrain({
                variables: {
                    workspaceId: workspaceId,
                    documents: documents
                }
            });
            if (response.data.uploadAndTrain) {
                onTrainingSuccess();
            }
            console.log('Upload and Train response', response);
        } catch (error) {
            console.error('Error in upload and train', error);
        }
    };

    const progress = currentTotalSize / maxBatchSize * 100;

    return (
        <Box sx={{ m: 3 }} {...bind}>

            <SuperDiv />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                onChange={handleFileSelect}
            />

            <Box>
                <Typography variant='h6' fontSize={18}>Title</Typography>
                <TextField
                    // placeholder="Enter text here"
                    placeholder="Conten classifying coming soon.."
                    fullWidth
                    disabled
                    margin="normal"
                    variant="outlined"
                />
            </Box>

            <Box mt={2}>
                <Typography variant='h6' fontSize={18}>What is this dataset about?</Typography>
                <TextField
                    // placeholder="Enter multiple lines here"
                    placeholder="Conten classifying coming soon.."
                    multiline
                    disabled
                    fullWidth
                    rows={4}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        style: {
                            padding: '2px',
                        },
                    }}
                />
            </Box>

            <Box mt={3} display={'flex'} flexDirection={'row'} justifyContent={'center'} height={500} gap={2}>

                <Box
                    height={'98%'}
                    width={'60%'}
                    display={'flex'}
                    justifyContent="center"
                    alignItems={'center'}
                    flexDirection={"column"}
                    mr={1}

                    sx={{
                        border: '2px dashed',
                        borderColor: 'primary.main',
                        borderRadius: 3,
                        m: 1

                    }}
                >
                    {/*Drag & Drop*/}
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} minWidth={200}>
                        <Typography variant="h6" fontWeight={500}>
                            Upload files
                        </Typography>
                        <Typography variant="subtitle1" color={'#929292'}>
                            Drop your files here
                        </Typography>
                    </Box>

                    <img src={DocLoadB} alt="docLoad" height={50} />

                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Typography variant="body2" color={'#929292'}>
                            .docx, .pdf, .txt
                        </Typography>

                        <Button
                            variant="contained"
                            sx={{ margin: 1, background: '#708FFF', boxShadow: 'none', textTransform: 'none' }}
                            onClick={() => {
                                if (fileInputRef.current) {
                                    fileInputRef.current.click();
                                }
                            }}
                        >
                            Browse Files
                        </Button>

                    </Box>
                </Box>

                {/* Files list */}

                <Box sx={{
                    maxWidth: 600,
                    height: 500,
                    backgroundColor: 'white',
                    boxShadow: '0 1px 6px rgba(32, 33, 36, 0.15)',
                    borderRadius: '10px',
                    position: 'relative'
                }}>
                    <Box width={'100%'} sx={{ p: 2, backgroundColor: '#edeff2', pl: 4, width: 600, mb: 1, display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='h6' fontSize={16} fontWeight={500}> Files List</Typography>

                        <Box>
                            <LinearProgress
                                variant='determinate'
                                value={progress}
                                sx={{
                                    backgroundColor: progress >= 90 ? '#f5aeb8' : progress >= 70 ? '#fcf2b8' : '#b5d0f5',

                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: progress >= 90 ? '#f71b3c' : progress >= 70 ? '#ffde26' : '#1c73ed',

                                    }
                                }}
                            />
                            <Typography variant="body1" color="#999999" fontSize={13}>
                                Files uploaded: {filesize(currentTotalSize)}/{filesize(maxBatchSize)}
                                {/* Files uploaded: {customFilesize(currentTotalSize)}/{customFilesize(maxBatchSize)} */}
                            </Typography>
                        </Box>
                    </Box>
                    {loadedFiles.length === 0 && !unprocessedDocumentsLoading && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            opacity: '60%',
                        }}>
                            <Typography variant="h6" fontWeight={400} color="textSecondary" align='center'>
                                {`We process text data only from the`}<br /> {`.txt, .docx, .pdf, or .zip under 30MB`}
                            </Typography>

                        </Box>
                    )}
                    <Box sx={{
                        maxWidth: 600,
                        height: '87%',
                        width: '100%',
                        maxHeight: '100%',
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',

                    }}>

                        {/* Errors Alert */}
                        {uploadErrors.length > 0 && (
                            <Alert
                                severity="error"
                                icon={false}
                                sx={{
                                    background: '#fce6e6',
                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                                    borderRadius: 3, mb: 2, width: 550,
                                    position: 'relative',
                                    zIndex: 1
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', ml: 2, mb: 2 }}>
                                    Upload Errors
                                </Typography>

                                <IconButton
                                    aria-label="close"
                                    onClick={() => setUploadErrors([])}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: 'red',
                                        size: 'small',
                                        zIndex: 1,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>

                                <List sx={{ pt: 0, pb: 0, }}>
                                    {uploadErrors.map((error, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                fontSize: '0.9em',
                                                display: 'flex',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <ErrorOutlineIcon sx={{ color: 'red', fontSize: '1em', mr: '0.5rem', mt: '0.2rem' }} />
                                            {error}
                                        </ListItem>
                                    ))}
                                </List>
                            </Alert>
                        )}

                        {/* Files with error */}
                        <List>
                            {loadedFiles.filter(file => file.error !== null).map((file, index) => (
                                <ListItem key={index} sx={{ background: '#f5e4e4', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)', borderRadius: 3, mb: 2, width: 550 }}>
                                    <ArticleIcon sx={{ color: "#a80505", fontSize: 28, mr: 1 }} />
                                    <Box display="flex" width="100%" justifyContent={'space-between'}>
                                        <Box display="flex" flexDirection="column" >
                                            <Typography variant="body1" color="#a80505" fontSize={14} fontWeight={500}>{file.name}</Typography>
                                            <Typography variant="body2" color="#a80505" fontSize={11}>{file.error?.code} : {file.error?.message}</Typography>
                                            <Typography variant="body2" color="#b57474" fontSize={11}>{file.size}</Typography>
                                        </Box>
                                        <IconButton aria-label="cancel" onClick={() => handleCancel(file.name)} sx={{ m: '10px' }}>
                                            <CancelIcon sx={{ color: "#a80505", fontSize: 15 }} />
                                        </IconButton>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>

                        {/* Files List */}
                        <List>
                            {unprocessedDocumentsLoading && (
                                [...Array(5)].map((_, rowIndex) => (
                                    <ListItem key={rowIndex} sx={{ background: '#FBFBFB', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)', borderRadius: 3, mb: 2, width: 550 }}>
                                        <Skeleton width={'100%'} />
                                    </ListItem>
                                ))
                            )}
                            {loadedFiles.filter(file => file.error === null).map((file, index) => (
                                <ListItem key={index} sx={{ background: '#FBFBFB', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)', borderRadius: 3, mb: 2, width: 550 }}>

                                    <ArticleIcon sx={{ fontSize: 28, mr: 1 }} />
                                    {shouldShowBox(file) ? (
                                        <Box display="flex" flexDirection="column" width="100%">
                                            <Box display="flex" alignItems="center">
                                                <ListItemText primary={file.name} primaryTypographyProps={{ sx: { fontSize: 14, fontWeight: 500 } }} />
                                                <IconButton aria-label="cancel" disabled={file.isCancelling} onClick={() => handleCancel(file.name)} sx={{ m: '1px' }}>
                                                    <CancelIcon sx={{ fontSize: 18 }} />
                                                </IconButton>

                                            </Box>

                                            <LinearProgress variant="indeterminate" sx={{ flexGrow: 1, borderRadius: '5px', height: '4px', '& .MuiLinearProgress-bar': { borderRadius: '4px' } }} />
                                            <Box display="flex" alignItems="center">
                                                <ListItemText secondary={<Typography variant="body2" color="#999999" fontSize={11}>{file.size}</Typography>} />
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box display="flex" width="100%" justifyContent={'space-between'}>
                                            <Box display="flex" flexDirection="column" >
                                                <Typography variant="body1" fontSize={14} fontWeight={500}>{file.name}</Typography>
                                                {/*place for showing the file erro */}
                                                {/* <Typography variant='body2' fontSize={12} fontWeight={400} color='#999999'>{file.status}</Typography> */}
                                                <Typography variant="body2" color="#999999" fontSize={11}>{file.size}</Typography>
                                            </Box>
                                            <IconButton aria-label="cancel" disabled={file.isCancelling} onClick={() => handleCancel(file.name)} sx={{ m: '1px' }}>
                                                <CancelIcon sx={{ fontSize: 18 }} />
                                            </IconButton>
                                        </Box>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Box>

            <Stack width={'100%'} justifyContent={'space-between'} display={'flex'} flexDirection={'row'} mt={3}>
                <Button
                    disabled={isUploadProcessing || isRemoveProcessing}
                    variant="contained"
                    sx={{
                        margin: 1,
                        left: 0,
                        background: '#BCBCBC',
                        textTransform: 'none'
                    }}
                    onClick={() => {
                        handleCancel(loadedFiles);
                        navigate(-1);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    onClick={handleStartLearning}
                    disabled={
                        isUploadProcessing ||
                        isRemoveProcessing ||
                        loadedFiles.filter(file => file.error === null).length === 0
                    } sx={{

                        margin: 1,
                        right: 0,
                        textTransform: 'none',
                    }}
                    startIcon={<img src={AiIllustrationIcon} alt="Icon" width={20} />}
                >
                    Start learning
                </Button>
            </Stack>

            <TrainingDialog
                trainLoading={trainLoading}
                trainError={trainingError}
                onClose={() => {
                    setShowSuccessDialog(false);
                }}
            />
            <SuccessDialog open={showSuccessDialog} />
        </Box>
    );
};

export default LoadDataScreen;
