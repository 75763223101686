export function getTabIndexSettings(pathname: string, drawerOpen: boolean): number {
  if (drawerOpen) return 1.1;

  if (pathname.indexOf("/settings/integrations") >= 0) {
    return 1.1;
  } else if (pathname.indexOf("/settings/plans") >= 0) {
    return 1.2;
  } else if (pathname.indexOf("/settings/users") >= 0) {
    return 1.3;
  } else if (pathname.indexOf("/settings/support") >= 0) {
    return 1.4;
  } else if (pathname.indexOf("/settings/workspace") >= 0) {
    return 1.5;
    // } else if (pathname.indexOf("/settings") >= 0) {
    //   return 4;
  }
  return 0;
}
