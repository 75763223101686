import { ApolloError } from '@apollo/client';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MachineSuccess from '../../Common/images/MachineSuccess.jpg';
import StartLearningImage from '../../Common/images/StartLearningImage.jpg';

interface TrainingDialogProps {
  trainLoading: boolean;
  trainError: ApolloError | undefined;
  onClose: () => void;
}

export const TrainingDialog: React.FC<TrainingDialogProps> = ({ trainLoading, trainError, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => {
    setIsOpen(false);
    onClose(); // Call the onClose prop to update the parent component's state
  };

  useEffect(() => {
    // Sync local state with props
    setIsOpen(trainLoading || !!trainError);
  }, [trainLoading, trainError]);


  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {trainError ? (
          <Alert severity="error">{trainError.message}</Alert>
        ) : (
          <Box gap={3} display={'flex'} flexDirection={'column'} alignItems={'center'} p={2}>
            <img src={StartLearningImage} alt="Learning" width={400} />
            <LinearProgress variant="indeterminate" sx={{ width: '80%', borderRadius: 5, mt: 1 }} />
            <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
              <Typography variant='h5' sx={{ fontWeight: 600 }}> We start the learning process...</Typography>
              <Typography variant='h6'>Usually, it takes up to 3 minutes. Please don't close the tab</Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {trainError && (
          <Button onClick={handleClose} color="primary">
            Cancel Training
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface SuccessDialogProps {
  open: boolean;
}

export const SuccessDialog: React.FC<SuccessDialogProps> = ({ open }) => {
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  return (
    <Dialog open={open}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={MachineSuccess} alt="Learning" width={400} />
        <Typography variant='h5' sx={{ fontWeight: 600, mt: 2 }}> Success! </Typography>
        {/* <Typography variant='h6'>Usually, it takes up to 3 minutes. Please don't close the tab</Typography> */}
        <DialogActions>
          <Button
            variant='contained'
            sx={{ mt: 1 }}
            onClick={() => {
              navigate(`/workspaces/${workspaceId}/data`);
              window.location.reload();
            }}
          >
            Got it</Button>

        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

