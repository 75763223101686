import { Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import DnD from "../Common/images/D&D.png";

//USAGE const {bind, SuperDiv } = useDragAndDrop(); ->> {..bind} </SuperDiv>

const useDragAndDrop = (onFilesDropped: (files: File[]) => void) => {
    const [isDragActive, setIsDragActive] = useState(false);
    const dragDepth = useRef(0);

    const handleDragEnter = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        dragDepth.current += 1;
        if (dragDepth.current === 1) {
            setIsDragActive(true);
        }
    }, []);

    const handleDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        dragDepth.current -= 1;
        if (dragDepth.current === 0) {
            setIsDragActive(false);
        }
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
    }, []);

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsDragActive(false);
        dragDepth.current = 0;

        let droppedFiles = e.dataTransfer.files;
        console.log(droppedFiles);
        onFilesDropped(Array.from(droppedFiles));
    }, [onFilesDropped]);

    const SuperDiv = () => {
        if (!isDragActive) {
            return null;
        }

        return (
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                pointerEvents: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 2
            }}>
                <img src={DnD} alt="Descriptive Alt Text" width={300} />
                <Typography variant="h5" fontWeight={700}>
                    Drop files
                </Typography>
                <Typography variant="h6">
                    Add files to create your dataset
                </Typography>
            </div>
        );
    };

    return {
        isDragActive,
        bind: {
            onDragEnter: handleDragEnter,
            onDragOver: handleDragOver,
            onDragLeave: handleDragLeave,
            onDrop: handleDrop
        },
        SuperDiv
    };
};

export default useDragAndDrop;
