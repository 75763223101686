import { Box, Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavbarSideSettings from "./Settings-Navigation/NavbarSideSetttings";

interface SettingsPageProps {
  children?: React.ReactNode;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: '100%'
      }}
    >
      <Container
        sx={{
          pl: {
            xs: 1,
            sm: '60px',
            md: '60px',
            lg: '60px',
          },
          flexGrow: 1,
        }}
      >
        <NavbarSideSettings />
        {children}
        <Outlet />
      </Container>
    </Box>
  );
};

export default SettingsPage;
