
import { Alert, Box, Button, Typography } from '@mui/material';
import { ExtendedProcessItem, ProcessItem } from '../../../../../../__generated__/graphql';
import ItemCardComponent from '../ItemCardComponent';
import ProgressLine from '../ProgressLine';

interface SecondStepItemProps {
    onBack: () => void;
    data?: ProcessItem | ExtendedProcessItem;
    loading: boolean;
    error?: string | null;
    onItemSumbit: () => void;
}

const SecondStepItem = ({ onItemSumbit, onBack, data, loading, error }: SecondStepItemProps) => {

    return (
        <>
            <ProgressLine currentStep={2} />
            <Typography variant='h6'>
                Processing folder
            </Typography>

            <Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
                We process only Google Documents, PDFs, DOCX files. Other extensions will be ignored.
            </Typography>

            {data && (<ItemCardComponent item={data} remove={true} inCompleteFlow={false} loadingOnStep={loading} />)}
            {error && <Alert severity="error">{error}</Alert>}
            <Box position={'absolute'} bottom={30} display={'flex'} width={'580px'} justifyContent={'space-between'}>
                <Button
                    variant='contained'
                    disabled={loading}
                    sx={{ backgroundColor: 'grey' }}
                    onClick={() => {
                        onBack();
                    }}
                >
                    Back
                </Button>
                <Button variant='contained' disabled={loading} onClick={onItemSumbit}>
                    {loading ? 'Adding...' : `Get the files ->`}
                </Button>
            </Box>
        </>
    );
};

export default SecondStepItem;