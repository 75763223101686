import { Box, Button, List, Typography } from "@mui/material";
import { useState } from "react";
import { ExtendedProcessItem } from "../../../../../../__generated__/graphql";
import ItemCardComponent from "../ItemCardComponent";
import ProgressLine from "../ProgressLine";
import FileListItem from './FilesComponent';

interface ThirdStepItemProps {
    learnedContent?: ExtendedProcessItem;
    onBack: () => void;
    handleLearnDone: () => void;
}

const FifthStepItem = ({ handleLearnDone, learnedContent, onBack }: ThirdStepItemProps) => {

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCopyLink = async (link: string) => {
        try {
            await navigator.clipboard.writeText(link);
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const processItem = learnedContent;
    const filesWithError = processItem?.processItemDocument?.filter(file => file.status === 'ERROR') || [];
    const filesReadyForProcessing = processItem?.processItemDocument?.filter(file => file.status !== 'ERROR') || [];

    return (
        <>

            {/* !learnLoading ? (
                    <Box display='flex' flexDirection={'column'} alignItems={'center'} justifyContent='center' position='absolute' top="23%" left="40%" gap={2}>
                        <img src={StartLearningImage} alt="Learning" width={400} />
                        <Typography variant='h6'> Success! </Typography>
                        <LinearProgress variant="determinate" value={100} sx={{ width: '100%', maxWidth: 400, height: 5, borderRadius: 5 }} />
                    </Box>
                ) */}
            <ProgressLine currentStep={5} />

            <Typography variant='h6'>
                Completely learned
            </Typography>

            <Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
                -
            </Typography>
            {processItem && (<ItemCardComponent item={processItem} remove={false} inCompleteFlow={false} />)}

            {filesReadyForProcessing.length > 0 && <Typography variant='h6' fontSize={15}>Found files in the folder and subfolders</Typography>}
            <List>
                {filesReadyForProcessing.map(file => (
                    <FileListItem key={file.id} file={file} onCopyLink={handleCopyLink} openSnackbar={openSnackbar} />
                ))}
            </List>

            {filesWithError.length > 0 && <Typography variant='h6' >Files with errors</Typography>}
            <List>
                {filesWithError.map(file => (
                    <FileListItem key={file.id} file={file} onCopyLink={handleCopyLink} openSnackbar={openSnackbar} />
                ))}
            </List>


            <Box position={'absolute'} bottom={30} display={'flex'} width={'580px'} justifyContent={'space-between'}>
                <Button
                    variant='contained'
                    sx={{ backgroundColor: 'grey' }}
                    onClick={() => {
                        onBack();
                    }}
                >
                    Back
                </Button>
                <Button
                    variant='contained'
                    onClick={handleLearnDone}
                >
                    {`Complete`}
                </Button>
            </Box>
        </>
    )

}

export default FifthStepItem;