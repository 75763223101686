import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Stack,
  SvgIcon,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";

import { useState } from "react";
import { useUserWorkspaces } from "../../../context/WorkspaceContext";
import { stringAvatar } from "../../Common/Generator";
import { getTabIndex } from "../../Common/NavBarUtil";
import { ReactComponent as AChat } from '../../Common/images/Menu/Chat/achat.svg';
import { ReactComponent as IChat } from '../../Common/images/Menu/Chat/ichat.svg';
import { ReactComponent as AData } from '../../Common/images/Menu/Data/adata.svg';
import { ReactComponent as IData } from '../../Common/images/Menu/Data/idata.svg';
import { ReactComponent as ASearch } from '../../Common/images/Menu/Search/asearch.svg';
import { ReactComponent as ISearch } from '../../Common/images/Menu/Search/isearch.svg';
import { ReactComponent as ASettings } from "../../Common/images/Menu/Settings/asettings.svg";
import { ReactComponent as Isettings } from '../../Common/images/Menu/Settings/isettings.svg';
import { Drawer } from "../Drawer";

export default function NavbarBottom() {
  const { pathname } = useLocation();
  const { workspaceId } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const tabIndex = getTabIndex(pathname, drawerOpen);
  const { activeWorkspace } = useUserWorkspaces();

  return (
    <Stack>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: { sm: "none" },
        }}
        elevation={3}
      >

        <BottomNavigation showLabels value={tabIndex} sx={{ zIndex: 9999999 }}>
          <BottomNavigationAction
            icon={tabIndex === 2 ? <SvgIcon component={AData} style={{ overflow: "visible" }} /> : <SvgIcon component={IData} style={{ overflow: "visible" }} />}
            component={Link}
            to={`/workspaces/${workspaceId}/data`}
            onClick={() => setDrawerOpen(false)}
          />
          <BottomNavigationAction
            icon={tabIndex === 0 ? <SvgIcon component={ASearch} style={{ overflow: "visible" }} /> : <SvgIcon component={ISearch} style={{ overflow: "visible" }} />}
            component={Link}
            to={`/workspaces/${workspaceId}`}
            onClick={() => setDrawerOpen(false)}
          />
          <BottomNavigationAction
            icon={tabIndex === 1 ? <SvgIcon component={AChat} style={{ overflow: "visible" }} /> : <SvgIcon component={IChat} style={{ overflow: "visible" }} />}
            component={Link}
            to={`/workspaces/${workspaceId}/chat`}
            onClick={() => setDrawerOpen(false)}
          />
          <BottomNavigationAction
            icon={tabIndex === 4 ? <SvgIcon component={ASettings} style={{ overflow: "visible" }} /> : <SvgIcon component={Isettings} style={{ overflow: "visible" }} />}
            component={Link}
            to={`/workspaces/${workspaceId}/settings`}
            onClick={() => setDrawerOpen(false)}
          />
          <BottomNavigationAction
            icon={<Avatar {...stringAvatar(activeWorkspace?.title ?? "WS", false)} />}
            onClick={() => setDrawerOpen(!drawerOpen)}
          ></BottomNavigationAction>
        </BottomNavigation>
      </Paper>
      <Drawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        side="bottom"
      />
    </Stack>
  );
}
