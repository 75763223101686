import { Box, Stack, Typography } from "@mui/material";
import logo from "./images/logo.svg";

const LogoTitle = () => {
  return (
    <Box>
      <Stack direction="row" display={'flex'} justifyContent="center" spacing={1}>
        <img src={logo} className="App-logo" alt="logo" style={{ width: '35px', height: '35px', marginTop: 2 }} />
        <Typography
          variant="h1"
          component="h2"
          sx={{ fontFamily: "Roboto", fontWeight: 500, fontSize: "30px", letterSpacing: "0.16em" }}
        >
          VOL AI
        </Typography>
      </Stack>
    </Box>
  );
};

export default LogoTitle;
