import { useMutation, useQuery } from "@apollo/client";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Avatar, Box, Button, Checkbox, Chip, FormControl, FormHelperText, IconButton, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { differenceInMinutes } from 'date-fns';
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { gql } from "../../../__generated__/gql";
import { DocumentResult, DocumentSource, DocumentType } from "../../../__generated__/graphql";
import { useUserWorkspaces } from "../../../context/WorkspaceContext";
import getSourceIcon from "../../Common/SourcesIconTitleIdentify";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import EmptyData from "./EmptyData";

const GET_DATA_LIST = gql(/* GraphQL */`
  query GetDataList($workspaceId: String!) {
    getDataList(workspaceId: $workspaceId) {
      id
      source
      type
      author
      title
      createdAt
    }
  }
`);

const DELETE_DOCUMENTS = gql(/* GraphQL */`
  mutation DeleteDocuments($documentIds: [String!]!, $workspaceId: String!) {
    deleteDocuments(documentIds: $documentIds, workspaceId: $workspaceId)
  }
`);

interface GroupedDocument {
  title: string;
  files: string;
  uploadDate: string;
  status: string;
  documents: DocumentResult[];
}

const groupDocuments = (documents: DocumentResult[]): GroupedDocument[] => {
  const grouped: DocumentResult[][] = [];

  const sortedDocuments = [...documents].sort((a, b) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  let currentGroup: DocumentResult[] = [];
  sortedDocuments.forEach(doc => {
    if (currentGroup.length === 0 ||
      differenceInMinutes(new Date(currentGroup[0].createdAt), new Date(doc.createdAt)) <= 5) {
      currentGroup.push(doc);
    } else {
      grouped.push(currentGroup);
      currentGroup = [doc];
    }
  });

  if (currentGroup.length > 0) {
    grouped.push(currentGroup);
  }


  return grouped.map((group, index) => {
    const latestDate = group[group.length - 1].createdAt;
    return {
      title: `Load Batch ${grouped.length - index}`,
      files: `${group.length} ${group.length === 1 ? 'file' : 'files'}`,
      uploadDate: new Date(latestDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }),
      status: 'Learned',
      documents: group,
    };
  });
};


const getUniqueDocuments = (documents: DocumentResult[]): DocumentResult[] => {
  return documents.filter((doc, index, self) =>
    index === self.findIndex((d) => (
      d.source === doc.source && d.type === doc.type
    ))
  );
};

export default function DataPage() {
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [isModifyMode, setIsModifyMode] = useState(false);
  const [deleteMutation, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DOCUMENTS);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { activeWorkspace } = useUserWorkspaces();
  const isSubscriptionActive = activeWorkspace?.subscription?.status;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelecteds = [];
    if (!event.target.checked || selectedDocuments.length > 0) {
      setSelectedDocuments([]);
      setIsModifyMode(false);
    } else {
      newSelecteds = rows.flatMap(row => row.documents.map(doc => doc.id));
      setSelectedDocuments(newSelecteds);
      setIsModifyMode(true);
    }
  };

  const handleRowCheckboxChange = (row: GroupedDocument, event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelected: string[] = [];
    if (event.target.checked) {
      newSelected = [...selectedDocuments, ...row.documents.map(doc => doc.id)];
    } else {
      newSelected = selectedDocuments.filter(docId => !row.documents.some(doc => doc.id === docId));
    }
    setSelectedDocuments(newSelected);
    setIsModifyMode(newSelected.length > 0);
  };


  const handleDeleteConfirmation = async () => {
    try {
      await deleteMutation({
        variables: { documentIds: selectedDocuments, workspaceId: workspaceId },
      });
      await refetch();
      setSelectedDocuments([]);
      setIsModifyMode(false);
      setShowDeleteConfirmation(false)
    } catch (error) {
    }
  };

  const handleDelete = async () => {
    setShowDeleteConfirmation(true);
  };

  const navigate = useNavigate();
  const params = useParams<{ workspaceId?: string }>();
  const workspaceId = params.workspaceId || 'defaultWorkspaceId';
  const { loading: loadingGetData, data, refetch } = useQuery(GET_DATA_LIST, {
    variables: { workspaceId: workspaceId },
  });

  let rows: GroupedDocument[] = [];
  if (data && data.getDataList) {
    rows = groupDocuments(data.getDataList);
  }
  const [selectedGroupedDocument, setSelectedGroupedDocument] = useState<GroupedDocument | null>(null);

  const handleRowClick = (groupedDocument: GroupedDocument) => {
    setSelectedGroupedDocument(groupedDocument);
  };

  const renderDetailedTable = (groupedDocument: GroupedDocument) => {
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <IconButton onClick={() => setSelectedGroupedDocument(null)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ marginLeft: 1 }}>
            {groupedDocument.title}
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={{ borderRadius: 3, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Uploaded Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedDocument.documents.map((doc, index) => (
                <TableRow
                  key={index}
                  onClick={() => navigate(`/workspaces/${workspaceId}/search/${doc.id}`)}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  <TableCell sx={{ maxWidth: 200 }}>{doc.title}</TableCell>
                  <TableCell>{doc?.author ? doc?.author.split('::')[1] : 'Unknown'}</TableCell>
                  <TableCell >
                    <Box display={'flex'} alignItems={'center'} gap={0.5}>
                      <Avatar
                        src={getSourceIcon({ source: doc?.source as DocumentSource, type: doc?.type as DocumentType, intention: 'icon' })}
                        sx={{
                          width: 25,
                          height: 'auto'
                        }}
                      />
                      <Typography>{getSourceIcon({ source: doc?.source as DocumentSource, type: doc?.type as DocumentType, intention: 'title' })}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {new Date(doc.createdAt).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <>
      {rows.length === 0 && !loadingGetData ? <EmptyData /> :

        <Box sx={{ backgroundColor: "transparent", mt: 5, mb: 5, p: 1 }}>

          <Stack display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'} mb={2}>

            <Typography variant="h6">
              Data upload history
            </Typography>
            <FormControl>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    margin: 1,
                    background: '#FFF',
                    textTransform: 'none',
                    color: '#000',
                    boxShadow: '0px 0px 2.855975866317749px rgba(0, 0, 0, 0.15)',
                    '&:hover': { color: '#FFF', backgroundColor: "black" }
                  }}
                  onClick={() => navigate(`/workspaces/${workspaceId}/settings/integrations/`)}>
                  Integrations
                </Button>

                <Button
                  variant="contained"
                  disabled={!isSubscriptionActive}
                  sx={{
                    margin: 1,
                    textTransform: 'none',
                    boxShadow: '0px 0px 2.8px rgba(0, 0, 0, 0.15)'
                  }}
                  onClick={() => navigate('../load-data')}
                >
                  + Upload data
                </Button>
              </Box>
              {!isSubscriptionActive && (
                <FormHelperText variant='standard' sx={{ m: 0, color: 'orange', fontSize: 12, alignSelf: 'center', justifySelf: 'center' }}>
                  You haven't active subscription
                </FormHelperText>
              )}
            </FormControl>

          </Stack>
          {selectedGroupedDocument ?
            renderDetailedTable(selectedGroupedDocument) :
            (
              <TableContainer component={Paper} sx={{ borderRadius: 3, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow sx={isModifyMode ? { backgroundColor: '#F1F3F5' } : {}}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={selectedDocuments.length > 0 && selectedDocuments.length < rows.flatMap(row => row.documents).length}
                          checked={!loadingGetData && selectedDocuments.length === rows.flatMap(row => row.documents).length}
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      {isModifyMode && (
                        <TableCell colSpan={5}>
                          <Button
                            onClick={handleDelete}
                            sx={{
                              color: deleteLoading ? 'grey' : 'red',
                              pointerEvents: deleteLoading ? 'none' : 'auto',
                              '&:hover': {
                                backgroundColor: deleteLoading ? '' : '#ffd6d6',
                              },
                            }}
                            disabled={deleteLoading}
                          >
                            <DeleteForeverIcon
                              sx={{
                                width: 18,
                                mr: 0.5,
                                color: deleteLoading ? 'grey' : 'red',
                              }}
                            />
                            Delete Selected
                          </Button>
                        </TableCell>
                      )}
                      {!isModifyMode && (
                        <>
                          <TableCell align="left">Title</TableCell>
                          <TableCell align="left">Files</TableCell>
                          <TableCell align="left"> Source</TableCell>
                          <TableCell align="left">Upload Date</TableCell>
                          <TableCell align="left">Status</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {/* Skeletons */}
                    {loadingGetData && (
                      [...Array(15)].map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {[...Array(6)].map((_, cellIndex) => (
                            <TableCell key={cellIndex}>
                              <Skeleton variant="rounded" width="100%" />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    )}

                    {/* Rows with data */}
                    {rows.map((row, rowIndex) => (
                      <TableRow
                        key={row.title}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          },
                        }}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Checkbox
                            checked={selectedDocuments.some(docId => row.documents.some(doc => doc.id === docId))}
                            onChange={(event) => handleRowCheckboxChange(row, event)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ maxWidth: 190, fontWeight: 500 }}>
                          {row.title}
                        </TableCell>
                        <TableCell align="left">{row.files}</TableCell>
                        <TableCell align="left">
                          <Box display={'flex'} alignItems={'center'} flexWrap="wrap">
                            {getUniqueDocuments(row.documents).map((doc, docIndex) => (
                              <Avatar
                                key={docIndex}
                                src={getSourceIcon({ source: doc.source as DocumentSource, type: doc.type as DocumentType, intention: 'icon' })}
                                sx={{ width: 25, height: 'auto', marginRight: 0.5 }}
                              />
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell align="left">{row.uploadDate}</TableCell>
                        <TableCell align="left">
                          <Chip
                            label={row.status}
                            sx={{
                              borderRadius: '10px',
                              color: (theme) => ({
                                'Error': '#FF1212',
                                'Learned': '#0CC155',
                                'Processing': '#C1A40C',
                                'Deleted': '#7C7C7C'
                              })[row.status] || theme.palette.text.primary,
                              backgroundColor: (theme) => ({
                                'Error': 'rgba(255, 18.06, 18.06, 0.10)',
                                'Learned': 'rgba(12, 193, 85, 0.10)',
                                'Processing': 'rgba(193, 164.04, 12, 0.10)',
                                'Deleted': 'rgba(128, 128, 128, 0.2)'
                              })[row.status] || 'inherit'
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }
        </Box>

      }

      <DeleteConfirmationPopup
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirmation}
        isLoading={deleteLoading}
        error={deleteError}
      />
    </>
  );
};

