import { Box, Grid } from '@mui/material';
import { IntercomProvider } from 'react-use-intercom';
import ChatIcon from '../../../Common/images/Support/chaticon.png';
import DataIcon from '../../../Common/images/Support/dataicon.png';
import OtherIcon from '../../../Common/images/Support/othericon.png';
import PrivacyIcon from '../../../Common/images/Support/privacyicon.png';
import SearchIcon from '../../../Common/images/Support/searchicon.png';
import SecurityIcon from '../../../Common/images/Support/securityicon.png';
import IntercomChat from './IntercomChat';
import SupportCard from './SupportCard';

const INTERCOM_APP_ID = 'o1o21o56';

type CardsData = {
  title: string;
  subtitle: string;
  Icon: React.ElementType; // Assuming the icons are from @mui/icons-material
};

const iconWidth = 35;
const iconHeight = 35;

const Support = () => {
  const cards: CardsData[] = [
    {
      "title": "Data",
      "subtitle": "Assistance with data integration and management",
      "Icon": () => <img src={DataIcon} width={iconWidth} height={iconHeight} alt="Data" />
    },
    {
      "title": "Chat",
      "subtitle": "Support for chat functionalities and integration",
      "Icon": () => <img src={ChatIcon} width={iconWidth} height={iconHeight} alt="Chat" />
    },
    {
      "title": "Search",
      "subtitle": "Help with search capabilities and optimization",
      "Icon": () => <img src={SearchIcon} width={iconWidth} height={iconHeight} alt="Search" />
    },
    {
      "title": "Security",
      "subtitle": "Guidance on securing your data and applications",
      "Icon": () => <img src={SecurityIcon} width={iconWidth} height={iconHeight} alt="Security" />
    },
    {
      "title": "Privacy",
      "subtitle": "Privacy policies and data protection",
      "Icon": () => <img src={PrivacyIcon} width={iconWidth} height={iconHeight} alt="Privacy" />
    },
    {
      "title": "Other",
      "subtitle": "Support for miscellaneous inquiries and issues",
      "Icon": () => <img src={OtherIcon} width={iconWidth} height={iconHeight} alt="Other" />
    }
  ];

  return (

    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      {/* <Typography variant='h4' fontWeight={500}> Support</Typography> */}
      <Grid container spacing={3}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <SupportCard info={card} />
          </Grid>
        ))}
      </Grid>

      <IntercomProvider appId={INTERCOM_APP_ID}>
        <IntercomChat />
      </IntercomProvider>
    </Box>
  )
};

export default Support;
