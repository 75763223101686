import { useMutation } from "@apollo/client";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { gql } from "../../__generated__";
import { useUserWorkspaces } from "../../context/WorkspaceContext";
import { auth } from "../../firebase";
import { getUserAvatar, stringAvatar } from "../Common/Generator";
import { WaitlistDialog } from "./WaitlistDialog";
import { WorkspaceCreateDialog } from "./WorkspaceCreateDialog";

const ACCEPT_WORKSPACE = gql(/* GraphQL */ `
  mutation AcceptWorkspace($data: AcceptWorkspaceInput!) {
    acceptWorkspace(data: $data) {
      workspaceId
      userId
      role
      createdAt
      joinedAt
      workspace {
        id
        title
        createdAt
        updatedAt
      }
    }
  }
`);

const REJECT_WORKSPACE = gql(/* GraphQL */ `
  mutation RejectWorkspace($data: AcceptWorkspaceInput!) {
    rejectWorkspace(data: $data) {
      workspaceId
      userId
      role
      createdAt
      joinedAt
      workspace {
        id
        title
        createdAt
        updatedAt
      }
    }
  }
`);

export function Workspaces() {
  const [
    acceptWorkspace,
    { error: acceptWorkspaceError, loading: acceptWorkspaceLoading },
  ] = useMutation(ACCEPT_WORKSPACE);
  const [
    rejectWorkspace,
    { error: rejectWorkspaceError, loading: rejectWorkspaceLoading },
  ] = useMutation(REJECT_WORKSPACE);
  const { data, refetch, loading: refetchLoading } = useUserWorkspaces();
  const [confirmAccept, setConfirmAccept] = useState<string | null>(null);
  const [confirmReject, setConfirmReject] = useState<string | null>(null);
  const [showWaitlistDialog, setShowWaitlistDialog] = useState(false);
  const [showWorkspaceCreateDialog, setShowWorkspaceCreateDialog] = useState(false);
  const navigate = useNavigate();
  const workspaces = data?.user?.workspaces;
  const invitedWorkspaces = workspaces
    ? workspaces.filter((workspace) => !workspace.joined)
    : null;
  const memberWorkspaces = workspaces
    ? workspaces.filter((workspace) => workspace.joined)
    : null;
  const [user] = useAuthState(auth);

  async function acceptWorkspaceClick(workspaceId: string) {
    try {
      await acceptWorkspace({ variables: { data: { workspaceId } } });
      await refetch();
    } catch (_) { }
  }

  async function rejectWorkspaceClick(workspaceId: string) {
    try {
      await rejectWorkspace({ variables: { data: { workspaceId } } });
      await refetch();
    } catch (_) { }
  }

  const [signOutUser] = useSignOut(auth);

  const confirmSignOut = () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      signOutUser();
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ display: "flex", minHeight: "100%", alignItems: "center" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          refetchLoading || acceptWorkspaceLoading || rejectWorkspaceLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={2} flex={1} sx={{ alignItems: "center", mb: 4 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{ fontSize: 35, fontWeight: 500, fontFamily: "Helvetica Neue" }}
        >
          Workspaces
        </Typography>

        <CardHeader
          avatar={
            user?.photoURL ? (
              <Avatar alt="profile photo" src={user?.photoURL} />
            ) : (
              <Avatar
                src={getUserAvatar(user?.displayName || user?.email || "P")}
              />
            )
          }
          title={`${user?.email}`}
          subheader={user?.displayName || undefined}
          action={
            <IconButton
              sx={{ mt: '10%', mb: '10%' }}
              onClick={confirmSignOut}>
              <LogoutIcon />
            </IconButton>
          }
        />

        {acceptWorkspaceError && (
          <Alert severity="error">Error accepting workspace invitation</Alert>
        )}
        {rejectWorkspaceError && (
          <Alert severity="error">Error rejecting workspace invitation</Alert>
        )}
        {memberWorkspaces &&
          memberWorkspaces.length > 0 &&
          memberWorkspaces.map(({ workspace, workspaceId }) => {
            const title = workspace?.title || "Untitled Workspace";
            const split = title.toUpperCase().split(" ");
            const abbreviation =
              split.length > 1
                ? `${split[0].substring(0, 1)}${split[1].substring(0, 1)}`
                : split[0].substring(0, 1);
            return (
              <Card
                key={workspaceId}
                sx={{
                  "&:hover": { boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)" },
                }}
              >
                <CardActionArea
                  onClick={() => navigate(`/workspaces/${workspaceId}`)}
                >
                  <CardHeader
                    title={
                      <Typography fontWeight="500" fontSize={17}>
                        {title}
                      </Typography>
                    }
                    avatar={
                      <Avatar {...stringAvatar(title, false)}>
                        {abbreviation}
                      </Avatar>
                    }
                    action={
                      <Stack
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ flex: 1, padding: 2 }}
                      >
                        <ArrowForwardIcon />
                      </Stack>
                    }
                  />
                </CardActionArea>
              </Card>
            );
          })}
        {invitedWorkspaces &&
          invitedWorkspaces.length > 0 &&
          invitedWorkspaces.map(({ workspace, workspaceId }) => {
            const title = workspace?.title || "Untitled Workspace";
            const split = title.toUpperCase().split(" ");
            const abbreviation =
              split.length > 1
                ? `${split[0].substring(0, 1)}${split[1].substring(0, 1)}`
                : split[0].substring(0, 1);
            return (
              <Card key={workspaceId}>
                <CardHeader
                  title={<Typography fontWeight="500" fontSize={17}>{title}</Typography>}

                  avatar={
                    <Avatar {...stringAvatar(title, false)}>{abbreviation}</Avatar>
                  }
                  action={
                    <Stack
                      spacing={1}
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ flex: 1, padding: 1 }}
                    >
                      <Button onClick={() => setConfirmReject(workspaceId)}>
                        Reject
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setConfirmAccept(workspaceId)}
                      >
                        Accept
                      </Button>
                    </Stack>
                  }
                />
              </Card>
            );
          })}

        <Card sx={{
          "&:hover": { boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)" },
        }}>
          <Box
            onClick={() => {
              setShowWorkspaceCreateDialog(true);
            }}
            sx={{ backgroundClip: "#FFFFFF" }}
          >
            <CardHeader
              style={{ cursor: 'pointer' }}
              title={
                <Typography fontWeight={400}>
                  Create new workspace
                </Typography>
              }
              avatar={
                <IconButton sx={{ backgroundColor: "#e6e8f7" }}>
                  <AddIcon color="primary" />
                </IconButton>
              }
              action={
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ flex: 1, padding: 1 }}
                >
                </Stack>
              }
            />
          </Box>
        </Card>

        <WorkspaceCreateDialog
          open={showWorkspaceCreateDialog}
          onClose={() => setShowWorkspaceCreateDialog(false)}
          onSubmit={async (res) => {
            console.log("new workspace res", res);
            setShowWorkspaceCreateDialog(false);
            await refetch();
            const newWorkspace = res?.createWorkspace.workspace;
            if (newWorkspace) {
              navigate(`/workspaces/${newWorkspace.id}`);
            }
          }}
        />
        <WaitlistDialog
          open={showWaitlistDialog}
          onClose={() => setShowWaitlistDialog(false)}
        />
        <Dialog
          open={!!confirmAccept || !!confirmReject}
          fullWidth
          maxWidth="sm"
          onClose={() => {
            setConfirmAccept(null);
            setConfirmReject(null);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {!!confirmAccept ? "Confirm Accept" : "Confirm Reject"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!!confirmAccept
                ? "Are you sure you want to accept the invitation into workspace?"
                : "Are you sure you want to reject the invitation into workspace?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmAccept(null);
                setConfirmReject(null);
              }}
            >
              No
            </Button>
            <Button
              onClick={async () => {
                setConfirmAccept(null);
                setConfirmReject(null);
                if (!!confirmAccept) {
                  await acceptWorkspaceClick(confirmAccept);
                }
                if (!!confirmReject) {
                  await rejectWorkspaceClick(confirmReject);
                }
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Container>
  );
}
