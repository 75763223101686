import ArticleIcon from '@mui/icons-material/Article';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, ListItem, Snackbar, Typography } from '@mui/material';
import React from 'react';
import { ProcessItemDocument } from '../../../../../../__generated__/graphql';

interface FileListItemProps {
    file: ProcessItemDocument;
    onCopyLink: (link: string) => void;
    openSnackbar: boolean;
}

const FileListItem: React.FC<FileListItemProps> = ({ file, onCopyLink, openSnackbar }) => (
    <ListItem key={file.id} sx={{ background: file.status === 'ERROR' ? '#ffe3e3' : '#FBFBFB', boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.10)', borderRadius: 3, mb: 2, width: 550 }}>
        <ArticleIcon sx={{ fontSize: 28, mr: 1 }} />
        <Box display="flex" width="100%" justifyContent={'space-between'}>
            <Box display="flex" flexDirection="column" >
                <Typography variant="body1" fontSize={14} fontWeight={500}>{file.title}</Typography>
                <Box display={'flex'}>
                    <Typography
                        variant='body2'
                        fontSize={12}
                        mr={0.5}
                        sx={{
                            color:
                                file.status === 'ERROR' ? 'red' :
                                    file.status === 'LOADED' ? 'blue' : file.status === 'PROCESSING' ? '#08CD92' : 'black'
                        }}>
                        <span style={{ fontWeight: 400 }}>
                            {file.status === 'UNPROCESSED' ? 'Unprocessed'
                                : file.status === 'PROCESSING' ? 'Processing'
                                    : file.status === 'LOADED' ? 'Loaded'
                                        : file.status === 'ERROR' ? 'Error' : file.status}
                        </span>
                        <span style={{ fontWeight: 300 }}>
                            {file.statusCode ? ':' : ''}&nbsp;{file.statusCode}
                        </span>
                    </Typography>
                </Box>
            </Box>
            <IconButton onClick={() => onCopyLink(file.uri)}>
                <LinkIcon />
            </IconButton>
            <Snackbar
                open={openSnackbar}
                message="Link copied!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </Box>
    </ListItem>
);

export default FileListItem;
