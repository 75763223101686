import { useQuery } from '@apollo/client';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { gql } from '../../../../__generated__/gql';
import { IntegrationWithAuthorized } from '../../../../__generated__/graphql';
import { logEvent } from '../../../../analytics';
import GoogleDriveGallery1 from '../../../Common/images/Integrations/IntegtationCover/Gallery/GoogleDrive/GoogleDriveGallery1.jpg';
import HubSpotGallery1 from '../../../Common/images/Integrations/IntegtationCover/Gallery/HubSpot/HubSpotGallery1.jpeg';
import NotionGallery1 from '../../../Common/images/Integrations/IntegtationCover/Gallery/Notion/NotionGallery1.jpg';
import GallerySlack1 from '../../../Common/images/Integrations/IntegtationCover/Gallery/Slack/GallerySlack1.jpeg';
import GallerySlack2 from '../../../Common/images/Integrations/IntegtationCover/Gallery/Slack/GallerySlack2.jpg';
import ZoomGallery1 from '../../../Common/images/Integrations/IntegtationCover/Gallery/Zoom/ZoomGallery1.jpeg';
import GoogleDriveCover from '../../../Common/images/Integrations/IntegtationCover/GoogleDrive.jpeg';
import HubspotCover from '../../../Common/images/Integrations/IntegtationCover/Hubspot.jpg';
import NotionCover from '../../../Common/images/Integrations/IntegtationCover/Notion.jpeg';
import SlackCover from '../../../Common/images/Integrations/IntegtationCover/SlackCover.jpeg';
import ZoomCover from '../../../Common/images/Integrations/IntegtationCover/Zoom.jpg';
import IntegrationCard from './IntegrationCard';
import IntegrationDialog from './IntegrationDialog';

const GET_INTEGRATIONS_LIST = gql(`
query GetIntegrationsForWorkspace($workspaceId: String!) {
    getIntegrationsForWorkspace(workspaceId: $workspaceId) {
      id
      workspaceId
      userId
      integrationType
      metadata
      createdAt
      updatedAt
      authorized
    }
  }
`);

export type GalleryImage = {
    img: string;
    title: string;
};

export type DocumentsCards = {
    title: string;
    subtitle: string;
    Icon: React.ElementType;
    galleryImages: GalleryImage[];
    description: string;
    integration?: IntegrationWithAuthorized;
};

export type LocalProcessItem = {
    id: string | null;
    url: string | null;
};


const integrationMapping = {
    GOOGLE_DRIVE: {
        title: 'Google Drive',
        subtitle: 'Processing your documents',
        Icon: () => <img src={GoogleDriveCover} width={'100%'} height='auto' alt="Google Drive" />,
        galleryImages: [
            { img: GoogleDriveGallery1, title: 'GoogleDriveGallery1' },
            { img: GoogleDriveGallery1, title: 'GoogleDriveGallery1' },
        ],
        description: `Connect Google Drive folders, which could 
        be publicly available or only available to the account you authorize. 
        We then process all the files from the folder, train our system with them, 
        and afterward, you can ask questions in chat about this data or find it in the search engine.`
    },
    NOTION: {
        title: 'Notion',
        subtitle: 'Processing guides and salesbooks',
        Icon: () => <img src={NotionCover} width={'100%'} height='auto' alt="Notion" />,
        galleryImages: [
            { img: NotionGallery1, title: 'NotionGallery1' },
            { img: NotionGallery1, title: 'NotionGallery1' },
        ],
        description: 'Coming soon..'
    },
    SLACK: {
        title: 'Slack',
        subtitle: 'Gathering messages from threads',
        Icon: () => <img src={SlackCover} width={'100%'} height='auto' alt="Slack" />,
        galleryImages: [
            { img: GallerySlack1, title: 'SlackGallery1' },
            { img: GallerySlack2, title: 'SlackGallery2' },
        ],
        description: 'Coming soon..'
    },
    HUBSPOT: {
        title: 'HubSpot',
        subtitle: 'Gathering deals information',
        Icon: () => <img src={HubspotCover} width={'100%'} height='auto' alt="Slack" />,
        galleryImages: [
            { img: HubSpotGallery1, title: 'HubSpotGallery1' },
            { img: HubSpotGallery1, title: 'HubSpotGallery1' },
        ],
        description: 'Coming soon..'
    },
    ZOOM: {
        title: 'Zoom',
        subtitle: 'Gathering meetings discussions',
        Icon: () => <img src={ZoomCover} width={'100%'} height='auto' alt="Slack" />,
        galleryImages: [
            { img: ZoomGallery1, title: 'ZoomGallery1' },
            { img: ZoomGallery1, title: 'ZoomGallery1' },

        ],
        description: 'Coming soon..'
    },
};

const Integrations = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<DocumentsCards | null>(null);

    const { workspaceId } = useParams();
    const { data, loading: getIntegrationsLoading, refetch } = useQuery<{ getIntegrationsForWorkspace: IntegrationWithAuthorized[] }>(GET_INTEGRATIONS_LIST, {
        variables: { workspaceId: workspaceId! },
    });

    const installedIntegrations = data?.getIntegrationsForWorkspace.map(integration => ({
        ...integrationMapping[integration.integrationType],
        id: integration.id,
        integration: integration,
    })) || [];

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const integrationId = searchParams.get('integrationId');
        if (integrationId && data?.getIntegrationsForWorkspace) {
            const integration = data.getIntegrationsForWorkspace.find(i => i.id === integrationId);
            if (integration) {
                const doc = {
                    ...integrationMapping[integration.integrationType],
                    id: integration.id,
                    integration: integration,
                };
                setSelectedDocument(doc);
                setDialogOpen(true);
            }
        }
    }, [searchParams, data]);

    const handleCardClick = (integration: DocumentsCards) => {
        setSelectedDocument(integration);
        setDialogOpen(true);
        setSearchParams({ integrationId: integration.integration?.id || '' });
        logEvent('integrations', 'integration_card_clicked', integration.title);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedDocument(null);
        setSearchParams({});
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="left"
                justifyContent="flex-start"
                marginTop={5}
                height="100vh"
                flexDirection={'column'}
                gap={3}
            >
                {getIntegrationsLoading && (
                    <>
                        <Typography variant='h6' fontWeight={500}>Installed</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Skeleton variant='rounded' width={'100%'} />
                            </Grid>
                        </Grid>
                    </>
                )}
                {data && data.getIntegrationsForWorkspace.length > 0 && (<Typography variant='h6' fontWeight={500}>Installed</Typography>)}
                <Grid container spacing={3}>
                    {installedIntegrations.map((integration, index) => (
                        <Grid item xs={12} sm={4} key={integration.id}>
                            <IntegrationCard info={integration} onClick={() => handleCardClick(integration)} />
                        </Grid>
                    ))}
                </Grid>

                <Typography variant='h6' fontWeight={500} marginTop={5}>Integrations Market</Typography>
                <Grid container spacing={3}>
                    {Object.values(integrationMapping).map((integration, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <IntegrationCard info={integration} onClick={() => handleCardClick(integration)} />
                        </Grid>
                    ))}
                </Grid>

                <IntegrationDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    integration={selectedDocument}
                    refetchIntegrations={refetch}
                />
            </Box>
        </>
    );
};

export default Integrations;