
const ProgressLine: React.FC<{ currentStep: number }> = ({ currentStep }) => {
  const totalSteps = 5;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          style={{
            height: '7px',
            width: '135px',
            borderRadius: '50px',
            backgroundColor: index < currentStep ? 'blue' : 'grey',
            marginLeft: index === 0 ? '0' : '5px',
            marginBottom: '10px'
          }}
        />
      ))}
    </div>
  );
};

export default ProgressLine;