import { gql, useLazyQuery, useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Avatar, Box, Card, CardContent, CircularProgress, IconButton, List, ListItem, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DocumentPart, DocumentSource, DocumentType } from "../../../../__generated__/graphql";
import { getUserAvatar } from '../../../Common/Generator';
import getSourceIcon from "../../../Common/SourcesIconTitleIdentify";
// import logo from "../../../Common/images/logo.svg";
import { FieldCard } from "./FieldCard";

const LOAD_DOC_QUERY = gql(`
  query DocLoad($docId: String!, $workspaceId: String!) {
    docLoad(docId: $docId, workspaceId: $workspaceId) {
      id
      workspaceId
      source
      type
      author
      title
      externalId
      hash
      status
      uri
      content
      version
      createdAt
      updatedAt
      parts{
        documentId
        externalId
        author
        content
        vectorStoreId
        uri
        type
        sequence
        createdAt
        updatedAt
      }
    }
  }
`);


// const LOAD_DOC_SUMMARY_MUTATION = gql(`
//   mutation GenerateDocSummary($userQuery: String!, $docId: String!, $workspaceId: String!) {
//     generateDocSummary(userQuery: $userQuery, docId: $docId, workspaceId: $workspaceId) {
//       summary
//     }
//   }
// `);

const EXTRACT_RELATIVE_CONTENT_QUERY = gql`
  query ExtractRelativeContentToTheQuery($userQuery: String!, $partContent: String!, $workspaceId: String!) {
    extractRelativeContentToTheQuery(userQuery: $userQuery, partContent: $partContent, workspaceId: $workspaceId)
  }
`;

type SentencesObject = { [key: string]: string };

export default function SearchDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const urlParts = location.pathname.split("/");
  const searchDetailsId = urlParts[urlParts.length - 1];

  const queryParams = new URLSearchParams(location.search);
  const providedDocPartId = queryParams.get('docPartId');
  let userQuery = queryParams.get('query') || '';
  userQuery = decodeURIComponent(userQuery);

  const extractSequence = (providedDocPartId: string | null) => {
    const parts = providedDocPartId ? providedDocPartId.split('-') : [];
    return parts.length > 1 ? parseInt(parts[1], 10) : -1;
  };

  const selectedSequence = extractSequence(providedDocPartId);

  const [extractRelativeContent, { data: dataExtract }] = useLazyQuery(EXTRACT_RELATIVE_CONTENT_QUERY, {
    onCompleted: (data) => {
    }
  });

  // const [generateDocSummary, { loading: loadingSummary, error: errorSummary, data: dataSummary }] = useMutation(LOAD_DOC_SUMMARY_MUTATION);

  const { loading, error, data: DataDoc } = useQuery(LOAD_DOC_QUERY, {
    variables: { docId: searchDetailsId, workspaceId },
    onCompleted: (data) => {
      if (data && data.docLoad) {
        // generateDocSummary({ variables: { docId: searchDetailsId, workspaceId, userQuery: userQuery } });

        const selectedPart = data.docLoad.parts.find((part: DocumentPart) => part.sequence === selectedSequence);
        const sentencesArray = selectedPart.content.split(/(?<=[.!?])\s+(?=[A-Z])/);
        const sentencesObject = sentencesArray.reduce((acc: SentencesObject, sentence: string, index: number) => {
          acc[String(index + 1)] = sentence.trim();
          return acc;
        }, {});
        if (userQuery) {
          extractRelativeContent({ variables: { userQuery, partContent: JSON.stringify(sentencesObject), workspaceId } });
        }
      }
    }
  });

  const [fieldsExpanded, setFieldExpanded] = useState(new Set<string>());
  const details = location.state?.data;

  const handleFieldExpand = (field: string) => {
    setFieldExpanded((fieldsExpanded) => {
      fieldsExpanded = new Set(fieldsExpanded);

      if (fieldsExpanded.has(field)) {
        fieldsExpanded.delete(field);
      } else {
        fieldsExpanded.add(field);
      }
      return fieldsExpanded;
    });
  };

  return (
    <Stack spacing={2} alignItems="center" sx={{ marginBottom: 20, marginTop: 2 }}>
      <Card sx={{ backgroundColor: "transparent", mb: -5 }}>

        <Box display="flex" alignItems="flex-start" marginBottom={3}>
          <IconButton sx={{ marginRight: "0.5rem" }} onClick={() => navigate(-1)}> <ArrowBackIcon /> </IconButton>
          <Typography
            variant={
              userQuery.length > 200 ? 'body2' : userQuery.length > 50 ? 'body1' : 'h6'
            }
            sx={{
              mb: 2,
              fontSize: 20,
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginTop: 1,
            }}
          >
            {userQuery.length > 200 ? `${userQuery.substring(0, 200)}...` : userQuery}
          </Typography>
        </Box>

      </Card>
      {loading && (<Stack alignItems="center">  <CircularProgress sx={{ position: "absolute", left: "50%", top: "50%" }} /> </Stack>)}
      {error && (<Alert sx={{ ml: 3, mr: 3, mt: 100 }} severity="error"> {error.message} </Alert>)}
      {/* {errorSummary && (<Alert sx={{ ml: 3, mr: 3, mt: 100 }} severity="error"> {errorSummary.message}</Alert>)} */}

      {/* {!loadingSummary && dataSummary && (
        <Card sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
          <Alert variant="outlined" severity="success" icon={<Avatar src={logo} alt="logo" style={{ width: 34, height: 34, marginTop: 0 }} />}>
            <Typography variant="h6" color="text.primary"> AI Summary </Typography>
            <Typography variant="subtitle1" color="text.primary" sx={{ fontSize: 17, fontWeight: 500, fontFamily: "Helvetica" }}>
              {dataSummary?.generateDocSummary?.summary || "No summary available"}
            </Typography>
          </Alert>
        </Card>
      )} */}

      {/* {loadingSummary && (
        <Card sx={{ boxShadow: "none", backgroundColor: "transparent" }}>
          <Alert
            variant="outlined"
            severity="success"
            sx={{
              width: "100%",
              alignItems: "center",
            }}
            icon={<img src={logo} alt="logo" style={{ width: 37, height: 37, marginTop: 7 }} />}
          >
            <Typography variant="subtitle1" color="text.primary" fontSize={18} fontWeight="500">
              Loading...
            </Typography>
          </Alert>
        </Card>
      )} */}

      <Card>
        <CardContent>
          <Box display="flex" alignItems="center" marginLeft="10px" justifyContent="space-between">
            <Box display="flex" alignItems={'center'}>
              <Avatar
                src={getSourceIcon({
                  source: (DataDoc?.docLoad?.source as DocumentSource || ''),
                  type: (DataDoc?.docLoad?.type as DocumentType || ''),
                  intention: 'icon'
                })}
                alt="logo"
                style={{
                  width: 30,
                  height: 'auto',
                  marginRight: "7px"
                }} />
              <Stack alignItems="center">
                <Typography variant="body1" color="text.primary" sx={{ fontSize: 18, fontWeight: 500 }}>
                  {getSourceIcon({
                    source: (DataDoc?.docLoad?.source as DocumentSource || ''),
                    type: (DataDoc?.docLoad?.type as DocumentType || ''),
                    intention: 'title'
                  })}</Typography>
              </Stack>
            </Box>

            <Box display="flex">
              {DataDoc?.docLoad?.source !== 'LOCAL' && (<IconButton sx={{ marginRight: "0.5rem" }} onClick={() => window.open(`${DataDoc.docLoad.uri}`)}>
                {" "}
                <OpenInNewIcon color="primary" fontSize="small" />{" "}
              </IconButton>)}

            </Box>
          </Box>
        </CardContent>

        <Typography sx={{ width: "100%", borderBottom: 1, borderBottomColor: "#DFDFDF" }}></Typography>

        <Box sx={{ p: 2, mt: 0, mb: 1 }}>
          <Typography variant="h6" sx={{ fontSize: 20, flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis", mt: 1, ml: 2 }}>
            {DataDoc?.docLoad?.title || <Skeleton width="200px" />}
          </Typography>

          <Stack display="flex" flexDirection="row" marginTop={0.5}>
            {/* User Profile */}
            <Box display="flex" alignItems="center" gap={1} sx={{
              borderRadius: "30px",
              backgroundColor: "#F6F6F6",
              padding: "0.4rem 0.8rem",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              whiteSpace: "nowrap",
              marginTop: "5px",
              diplay: "center"
            }}>
              {<Avatar src={getUserAvatar(DataDoc?.docLoad?.author || 'defaultAvatar')} sx={{ width: 18, height: 18 }} />}
              <Typography variant="body2" fontWeight={400}>
                {DataDoc?.docLoad?.author ? DataDoc.docLoad.author : <Skeleton width={'50px'} />}
              </Typography>

            </Box>
            <Box
              sx={{
                borderRadius: "30px",
                backgroundColor: "#F6F6F6",
                padding: "0.4rem 0.8rem",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                //maxWidth: "30%",
                whiteSpace: "nowrap",
                marginTop: "5px",
              }}
            >
              <Typography variant="body2">{DataDoc?.docLoad?.createdAt ? new Date(DataDoc.docLoad.createdAt).toLocaleString() : <Skeleton width={'50px'} />}</Typography>
            </Box>
          </Stack>

          <Box sx={{ margin: 2 }}>
            <List sx={{ display: 'flex', flexDirection: 'column' }}>
              {DataDoc && DataDoc.docLoad && DataDoc.docLoad.parts && DataDoc.docLoad.parts.length > 0 ? (
                DataDoc.docLoad.parts
                  .slice()
                  .sort((a: DocumentPart, b: DocumentPart) => a.sequence - b.sequence)
                  .map((part: DocumentPart) => {
                    const sentences = part.content.split(/(?<=[.!?])\s+(?=[A-Z])/);
                    const sentenceCount = 4;
                    const sentenceChunks = sentences.reduce((acc: string[][], sentence: string, index: number) => {
                      if (index % sentenceCount === 0) acc.push([]);
                      acc[acc.length - 1].push(sentence.trim());
                      return acc;
                    }, []);

                    let highlightSentenceIndexes: number[] = [];
                    if (userQuery && dataExtract) {
                      highlightSentenceIndexes = JSON.parse(dataExtract.extractRelativeContentToTheQuery?.replace(/'/g, '"') || '[]').map(Number);
                      // highlightSentenceIndexes = JSON.parse(dataExtract?.extractRelativeContentToTheQuery?.replace(/'/g, '"')).map(Number);
                    }

                    return (
                      <ListItem
                        key={part.sequence}
                        sx={{
                          backgroundColor: part.sequence === selectedSequence ? '#f0f2fc' : '',
                          borderRadius: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}>
                        {sentenceChunks.map((chunk, chunkIndex) => (
                          <Typography
                            component="div"
                            textAlign={'left'}
                            key={part.sequence + "-" + chunkIndex}
                          >
                            {part.sequence === selectedSequence ? (
                              chunk.map((sentence, sentenceIndex) => {
                                const globalSentenceIndex = chunkIndex * sentenceCount + sentenceIndex + 1;
                                const isHighlighted = highlightSentenceIndexes.includes(globalSentenceIndex);
                                return (
                                  <span key={sentenceIndex} style={{ backgroundColor: isHighlighted ? 'yellow' : 'transparent' }}>
                                    {sentence}{sentenceIndex < chunk.length - 1 ? ' ' : ''}
                                  </span>
                                );
                              })
                            ) : (
                              chunk.join(' ')
                            )}
                          </Typography>
                        ))}
                      </ListItem>
                    );
                  })
              ) : (
                <>
                  <Skeleton variant="rounded" width="100%" />
                </>
              )}
            </List>
          </Box>

        </Box>
      </Card>


      {details && FieldCard("Info", Object.entries(details.fields), fieldsExpanded, handleFieldExpand)}
    </Stack>
  );
}
