import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { getConfig } from "../../../../../config";
import { auth } from "../../../../../firebase";
import GoogleColoredIcon from "../../../../Common/images/GoogleColoredIcon";
import { DocumentsCards } from "../Integrations";

interface OAuthStepProps {
    onSuccess: () => void;
    integration: DocumentsCards;
}

const OAuthStep = ({ onSuccess, integration }: OAuthStepProps) => {
    const { workspaceId } = useParams();
    const [authToken, setAuthToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [user] = useAuthState(auth);

    if (user) { user.getIdToken().then(token => { setAuthToken(token) }); }

    const handleOAuthClick = async () => {
        console.log('OAuth button clicked');
        console.log('Auth Token:', authToken);

        if (!authToken) {
            console.error('Auth token is not available');
            return;
        }
        setIsLoading(true);

        try {
            const response = await fetch(`${getConfig().api.baseUrl}/auth/google/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({ workspaceId, integrationId: integration?.integration?.id })
            });

            console.log('Response received:', response);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Redirecting to:', data.url);

            window.location.href = data.url;
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Grid item xs={12} sm={4} sx={{ background: '#F5F5F5', padding: 4 }}>
                {integration?.Icon && <integration.Icon />}
                <Typography variant="h6" sx={{ mt: 1 }}>{integration?.title}</Typography>
                <Typography variant="body2">{integration?.subtitle}</Typography>
                <Button
                    variant="contained"
                    size='small'
                    disabled
                    sx={{ mt: 2, mb: 1, borderRadius: '5px' }}>
                    Install
                </Button>
            </Grid>

            <Grid item xs={12} sm={8} sx={{ padding: 6 }}>
                <Typography variant="h6">Google Drive Authentication</Typography>
                <LoadingButton
                    variant="contained"
                    size="medium"
                    onClick={handleOAuthClick}
                    loading={isLoading}
                    sx={{
                        mt: 2,
                        backgroundColor: 'white',
                        color: 'black',
                        textTransform: 'none',
                        boxShadow: '0 1px 6px rgba(32, 33, 36, 0.15)',
                        gap: 1,
                        '&:hover': {
                            backgroundColor: '#fafafc',
                        },
                    }}
                >
                    <GoogleColoredIcon style={{ width: 22, height: 22 }} />
                    Authenticate
                </LoadingButton>
                <Box position={'absolute'} bottom={30} display={'flex'} width={'580px'} justifyContent={'space-between'}>
                    <Button variant='contained' sx={{ backgroundColor: 'grey' }} onClick={onSuccess} disabled={isLoading}>Cancel</Button>
                </Box>
            </Grid>
        </>
    );
};

export default OAuthStep;