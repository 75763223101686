
import { useLazyQuery, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Avatar,
  Box, Card, CardActionArea, CardContent,
  CircularProgress, Container,
  FormHelperText, List, ListItem, Skeleton, Stack,
  Switch,
  TextField, Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import "highlight.js/styles/github.css";
import { ChangeEvent, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, useParams, useSearchParams } from "react-router-dom";
import rehypeHighlight from "rehype-highlight";
import { Pluggable } from "unified";
import { gql } from "../../../__generated__/gql";
import { DocSearchResultDoc, DocumentSource, DocumentType } from "../../../__generated__/graphql";
import { logEvent } from "../../../analytics";
import { useAuth } from "../../../context/AuthContext";
import { useUserWorkspaces } from "../../../context/WorkspaceContext";
import getSourceIcon from "../../Common/SourcesIconTitleIdentify";
import logo from "../../Common/images/logo.svg";

const DOC_SEARCH_QUERY = gql(`
query DocSearch($workspaceId: String!, $query: String!) {
  docSearch(workspaceId: $workspaceId, query: $query) {
    results {
      score
      documentParts {
        docPartId
        documentId
        content
        author
        title
        source
        type
        createdAt
      }
    }
    searchTime
  }
}
`);

const DOC_SUMMARY_QUERY = gql(`
query SearchSummary($userQuery: String!, $docPartIds: [String!]!) {
  searchSummary(userQuery: $userQuery, docPartIds: $docPartIds) {
    summary
  }
}
`);

const SHARE_QUERIES_MUTATION = gql(`
  mutation SharingQueries($sharingQueries: Boolean!) {
    sharingQueries(sharingQueries: $sharingQueries) {
      id
      uid
      sharingQueries
    }
  }
`);

const SEARCH_QUERY_PARAMETER: string = "q";

export default function Search() {
  const [performSearch, { loading: searchLoading, error: searchError, data: searchData }] = useLazyQuery(DOC_SEARCH_QUERY);
  const [getSummary, { error: summaryError, loading: summaryLoading, data: summaryData }] = useLazyQuery(DOC_SUMMARY_QUERY);
  const [shareQueries, { loading: shareQueriesLoading }] = useMutation(SHARE_QUERIES_MUTATION);
  const { workspaceId } = useParams();
  const authContext = useAuth();
  const { activeWorkspace } = useUserWorkspaces();
  const isSubscriptionActive = activeWorkspace?.subscription?.status;
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const query = currentQueryParameters.get(SEARCH_QUERY_PARAMETER);
  const newQueryParameters: URLSearchParams = new URLSearchParams();
  const [searchText, setSearchText] = useState(currentQueryParameters.get(SEARCH_QUERY_PARAMETER) ?? "");
  const [firstSearch, setFirstSearch] = useState(true);
  const [localSharingStatus] = useState(authContext?.user?.sharingQueries);

  const handleShareQueriesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSharingStatus = event.target.checked;
    try {
      await shareQueries({ variables: { sharingQueries: newSharingStatus } });
      authContext.refreshUser?.();
    } catch (error) {
      console.error("Error sharing queries:", error);
    }
  };

  useEffect(() => {
    if (workspaceId && query && query.length > 0 && isSubscriptionActive) {
      (async () => {
        await performSearch({ variables: { query, workspaceId } });
      })();
      setFirstSearch(false);
    }
  }, [query, workspaceId, performSearch, isSubscriptionActive]);

  useEffect(() => {
    if (searchData?.docSearch?.results && isSubscriptionActive) {
      const docPartIds = searchData.docSearch.results.flatMap(result =>
        result.documentParts.map(part => part.docPartId)
      );
      getSummary({ variables: { docPartIds, userQuery: query! } });

    }
  }, [query, searchData, getSummary, isSubscriptionActive]);

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      newQueryParameters.set(SEARCH_QUERY_PARAMETER, searchText);
      setSearchParams(newQueryParameters);
      logEvent("search", "initiated_search", localSharingStatus ? searchText : '');
    }
  };

  // SEARCH
  const searchResult = query && query.length > 0 && searchData?.docSearch;
  const isCentered = firstSearch && (!searchResult || searchResult.results.length === 0);
  const textFieldStyle = {
    width: isCentered ? "70%" : "100%",
    minWidth: "350px",
  };

  const noSearchResults = searchResult && searchResult.results.length === 0;
  return (
    <Box sx={{ marginTop: isCentered ? "auto" : 3, marginBottom: isCentered ? "auto" : 3 }}>
      <Container
        sx={{
          justifyContent: "center",
          alignContent: "center",
          justifyItems: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: isCentered ? "center" : "flex-start",
          height: isCentered ? "94vh" : "auto",
          userSelect: "none",
        }}
      >
        {isCentered && (
          <Box marginBottom={5} sx={{ pointerEvents: "none" }}>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <img src={logo} className="App-logo" alt="logo" style={{ marginTop: 4, width: 44, height: 40 }} />
              <Stack direction="row">
                <Typography
                  variant="h4"
                  component="h2"
                  whiteSpace="nowrap"
                  sx={{ fontFamily: "Helvetica Neue", fontWeight: 500, letterSpacing: "0.14em" }}
                >
                  VOL AI
                </Typography>
                <Typography
                  variant="h4"
                  component="h2"
                  whiteSpace="nowrap"
                  color="secondary"
                  sx={{ fontFamily: "Helvetica Neue", fontWeight: 500, ml: 2, letterSpacing: "0.1em" }}
                >
                  Search
                </Typography>
              </Stack>
            </Stack>
          </Box>
        )}

        <TextField
          autoFocus
          //fullWidth
          disabled={!isSubscriptionActive || shareQueriesLoading}
          style={textFieldStyle}
          id="search-input"
          placeholder="Search"
          variant="outlined"
          onChange={handleSearchTextChange}
          onKeyDown={handleKeyDown}
          value={searchText}
          sx={{ backgroundColor: (!isSubscriptionActive ? '#f2f3f5' : 'white') }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: -3, alignItems: "center" }}>
                {searchLoading ? (
                  <CircularProgress size={26} sx={{ ml: 1, mr: 1 }} color="primary" />
                ) : (
                  <IconButton disabled >
                    <SearchIcon color={!isSubscriptionActive ? "disabled" : "primary"} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {" "}
                {searchText && (
                  <IconButton onClick={() => setSearchText("")}>
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {!isSubscriptionActive ? (
          <FormHelperText variant='standard' sx={{ color: 'orange', fontSize: 12 }}>
            You haven't an active subscription, go to the <b>settings/plans</b>
          </FormHelperText>
        ) : (
          <Box display={'flex'} mt={1} gap={0.5}>
            <Switch
              size='small'
              disabled={shareQueriesLoading}
              checked={!!localSharingStatus}
              onChange={handleShareQueriesChange}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ opacity: localSharingStatus ? 0.5 : undefined }}
            />
            <FormHelperText variant='standard' sx={{ fontSize: 12, opacity: localSharingStatus ? 0.5 : undefined }}>
              {localSharingStatus
                ? `Queries are being shared with the VOL AI team. We can't see your data or search results.`
                : `Your search queries are not being shared. You can help us improve our AI by sharing your search queries.`}
            </FormHelperText>
          </Box>
        )}
      </Container>

      {searchError && (
        <Alert sx={{ ml: 3, mr: 3, mt: 1, }} severity="error">
          {" "}
          {searchError.message}{" "}
        </Alert>
      )}
      {summaryError && (
        <Alert sx={{ ml: 3, mr: 3, mt: 1 }} severity="error">
          {" "}
          {summaryError.message}{" "}
        </Alert>
      )}
      {noSearchResults && (
        <List>
          <ListItem>
            <Alert severity="info" icon={<img src={logo} alt="logo" style={{ width: 34, height: 34, marginTop: 0 }} />}>
              <Typography variant="h6" color="text.primary">
                No Results Found
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" sx={{ fontSize: 17, fontFamily: "Helvetica" }}>
                We couldn't find any results matching your query: <strong>{query}</strong>. Please try refining your search terms.
              </Typography>
            </Alert>
          </ListItem>
        </List>
      )}
      {/* Skeletons */}
      {searchLoading && (
        [...Array(7)].map((_, rowIndex) => (
          <List>
            <ListItem key={rowIndex}>
              <Card sx={{ "&:hover": { boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)" } }}>
                <CardContent sx={{ alignItems: "flex-start", display: "row", ml: 0.5, mr: 0.5 }}>
                  <Skeleton variant="text" width="100%" />
                  <Typography variant="body1" color="text.primary" sx={{ fontSize: 15 }}>
                    <Skeleton variant="text" width="100%" />
                  </Typography>
                </CardContent>
              </Card>
            </ListItem>
          </List>
        ))
      )}

      {searchResult && searchResult?.results.length > 0 && (
        <>
          {/* AI Summary */}
          {!summaryLoading && summaryData?.searchSummary?.summary && (
            <List>
              <ListItem>
                <Alert severity="success" icon={<img src={logo} alt="logo" style={{ width: 34, height: 34, marginTop: 0 }} />}>
                  <Typography variant="h6" color="text.primary">
                    AI Summary
                  </Typography>
                  <Typography variant="subtitle1" color="text.primary" sx={{ fontSize: 17, fontFamily: "Helvetica" }}>
                    <ReactMarkdown rehypePlugins={[rehypeHighlight as unknown as Pluggable]}>{summaryData?.searchSummary?.summary}</ReactMarkdown>
                  </Typography>
                </Alert>
              </ListItem>
            </List>
          )}

          {summaryLoading && (
            <List>
              <ListItem>
                <Alert severity="success" icon={<CircularProgress style={{ width: 34, height: 34, marginTop: 0 }} />}>
                  <Typography variant="h6" color="text.primary">
                    AI Summary
                  </Typography>
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                  <Skeleton width="100%" />
                </Alert>
              </ListItem>
            </List>
          )}

          {/* Search results */}
          <List>
            {searchResult?.results.map((doc: DocSearchResultDoc) => {
              return doc?.documentParts.map((part, index) => {
                const title = part?.title.substring(0, 80) + " ";
                return (
                  <ListItem
                    key={part.docPartId}
                    component={Link}
                    // to={`search/${part.documentId}&query=${query}`}
                    to={`search/${part.documentId}?docPartId=${part.docPartId}&query=${encodeURIComponent(query)}`}
                  >
                    <Card sx={{ "&:hover": { boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)" }, position: 'relative' }}>
                      <CardActionArea>
                        <CardContent sx={{ alignItems: "flex-start", display: "row", ml: 0.5, mr: 0.5 }}>
                          <Stack direction="row" alignItems="center" marginBottom={0}>
                            <Avatar
                              src={getSourceIcon({
                                source: part?.source as DocumentSource,
                                type: part?.type as DocumentType,
                                intention: 'icon'
                              })}
                              alt="logo"
                              style={{
                                width: 35,
                                height: 'auto',
                                marginBottom: 5,
                                marginRight: "10px"
                              }} />
                            <Box>
                              <Typography gutterBottom variant="h6" component="div" marginBottom={0.1}>
                                {" "}
                                {title}{" "}
                              </Typography>
                              <Typography variant="body2" marginBottom={0.3}>
                                {new Intl.DateTimeFormat("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  weekday: "short",
                                  year: "numeric",
                                }).format(new Date(part.createdAt))}
                              </Typography>
                            </Box>
                          </Stack>
                          <Typography variant="body1" color="text.primary" sx={{ fontSize: 15, ml: 0.5, mr: 1 }}>
                            {part?.content.substring(0, 500) + ".."}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </ListItem>
                );
              });
            })}
          </List>
        </>
      )}
    </Box>
  );
}
