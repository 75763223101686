import SendIcon from "@mui/icons-material/Send";
import StopIcon from '@mui/icons-material/Stop';
import { Box, FormHelperText, IconButton, Switch, TextField } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { logEvent } from "../../../analytics";
import { useUserWorkspaces } from "../../../context/WorkspaceContext";

const MAX_LINE_COUNT = 30;

const ChatInput: React.FC<{
    inputText: string;
    handleInputChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSendMessage: () => void;
    handleStopWaiting: () => void;
    isWaitingForReply: boolean;
    isInputStopWhileStream: boolean;
    shareQueriesLoading: boolean;
    localSharingStatus?: boolean;
    handleShareQueriesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
    inputText,
    handleInputChange,
    handleSendMessage,
    isWaitingForReply,
    isInputStopWhileStream,
    handleStopWaiting,
    shareQueriesLoading,
    localSharingStatus,
    handleShareQueriesChange
}) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [multiline, setMultiline] = useState(false);
        const [lineCount, setLineCount] = useState(1);

        const calculateLineCount = (text: string) => {
            return text.split(/\r\n|\r|\n/).length;
        };

        const checkForOverflow = () => {
            if (inputRef.current) {
                const { scrollWidth, clientWidth } = inputRef.current;
                if (scrollWidth > clientWidth) {
                    setMultiline(true);
                }
            }
        };

        useEffect(() => {
            checkForOverflow();
        }, [inputText]);

        useEffect(() => {
            const newLineCount = calculateLineCount(inputText);
            setLineCount(newLineCount > MAX_LINE_COUNT ? MAX_LINE_COUNT : newLineCount);
        }, [inputText]);

        useEffect(() => {
            const newLineCount = calculateLineCount(inputText);
            setLineCount(newLineCount > MAX_LINE_COUNT ? MAX_LINE_COUNT : newLineCount);
        }, [inputText]);

        // useEffect(() => {
        //   if (inputRef.current) {
        //     const length = inputText.length;
        //     inputRef.current.setSelectionRange(length, length);
        //   }
        // }, [inputText]);

        const handleKeyPress = (event: React.KeyboardEvent) => {
            if (event.key === "Enter") {
                event.preventDefault();
                if (!event.ctrlKey && !event.metaKey && !event.shiftKey && !isWaitingForReply) {
                    handleSendMessage();
                    logEvent("chat", "sent_chat_message", localSharingStatus ? inputText : '');
                } else if (event.ctrlKey || event.metaKey) {
                    if (!isWaitingForReply) {
                        handleSendMessage();
                    }
                } else if (event.shiftKey) {
                    if (lineCount < MAX_LINE_COUNT) {
                        const selectionStart = inputRef.current?.selectionStart || 0;
                        const beforeCursor = inputText.substring(0, selectionStart);
                        const afterCursor = inputText.substring(selectionStart);
                        const updatedText = beforeCursor + "\n" + afterCursor;
                        handleInputChange({
                            ...event,
                            target: { ...event.target, value: updatedText },
                        } as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
                        setLineCount(calculateLineCount(updatedText));
                        setTimeout(() => {
                            if (inputRef.current) {
                                inputRef.current.setSelectionRange(beforeCursor.length + 1, beforeCursor.length + 1);
                            }
                        }, 0);
                    }
                }
            }
            checkForOverflow();
        };

        const handleGlobalKeyPress = useCallback((event: KeyboardEvent) => {
            if (event.key === "Escape" && isWaitingForReply && isInputStopWhileStream) {
                handleStopWaiting();
            }
        }, [isWaitingForReply, isInputStopWhileStream, handleStopWaiting]);

        useEffect(() => {
            document.addEventListener("keydown", handleGlobalKeyPress);

            return () => {
                document.removeEventListener("keydown", handleGlobalKeyPress);
            };
        }, [handleGlobalKeyPress]);

        const { activeWorkspace } = useUserWorkspaces();
        const isSubscriptionActive = activeWorkspace?.subscription?.status;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'flex-end' }}>
                {!isSubscriptionActive && (
                    <FormHelperText variant='standard' sx={{ color: 'orange', fontSize: 12, alignSelf: 'center' }}>
                        You haven't active subscription, go to the <b>settings/plans</b>
                    </FormHelperText>
                )}
                <Box display={'flex'} gap={0.5} mb={0.5} alignSelf={'center'}>
                    <Switch
                        size='small'
                        disabled={shareQueriesLoading}
                        checked={!!localSharingStatus}
                        onChange={handleShareQueriesChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ opacity: localSharingStatus ? 0.4 : undefined }}
                    />
                    <FormHelperText variant='standard' sx={{ fontSize: 12, opacity: localSharingStatus ? 0.5 : undefined }}>
                        {localSharingStatus
                            ? `Your chat queries are being shared with the VOL AI team. We can't see your data or chat results.`
                            : `Your chat queries are not being shared. You can help us improve our AI by sharing your chat queries.`}
                    </FormHelperText>
                </Box>
                <TextField
                    inputRef={inputRef}
                    autoFocus
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    disabled={isWaitingForReply || isInputStopWhileStream || !isSubscriptionActive || shareQueriesLoading}
                    multiline={multiline || lineCount > 1}
                    variant="outlined"
                    placeholder="Type your message"
                    InputProps={{
                        style: { paddingTop: 1, paddingBottom: 1, margin: 2, paddingLeft: 1, marginRight: 2 },
                    }}
                    style={{
                        overflowY: lineCount >= MAX_LINE_COUNT ? 'auto' : 'hidden',
                        resize: 'none',
                        alignSelf: 'flex-end',
                        width: '100%',
                        backgroundColor: (!isSubscriptionActive ? '#f2f3f5' : 'white')
                    }}
                />
                {(isWaitingForReply || isInputStopWhileStream) ? (
                    <IconButton
                        onClick={handleStopWaiting}
                        style={{
                            position: 'absolute',
                            bottom: 10,
                            right: 15,
                            borderRadius: "30px",
                            border: '1px solid black',
                            width: 30,
                            height: 30
                        }}
                    >
                        <StopIcon style={{ width: 25, color: "#000" }} />
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={handleSendMessage}
                        disabled={(isInputStopWhileStream && isWaitingForReply) || !inputText}
                        style={{
                            position: 'absolute',
                            bottom: 8,
                            right: 15,
                            borderRadius: "10px",
                            backgroundColor: "transparent",
                            transform: 'rotate(-90deg)',
                            background: (inputText ? "#5C6CED" : "#E5E5E5")
                        }}
                    >
                        <SendIcon style={{ width: 19, color: inputText ? "#FFF" : "#FFF" }} />
                    </IconButton>
                )}
            </div>
        );
    };

export default ChatInput;
