import { Email } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, InputAdornment, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSignInWithEmailLink } from "react-firebase-hooks/auth";
import { Form, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { auth } from "../../firebase";
//import TextFieldStyled from "../Common/TextFieldStyled";

const AuthCode = () => {
  const [signInWithEmailLink, user, loading, err] = useSignInWithEmailLink(auth);
  const email = localStorage.getItem("emailToSignIn");
  const error = err as { code: string };
  const formik = useFormik({
    initialValues: {
      email: email || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async ({ email }) => {
      await signInWithEmailLink(email);
    },
  });

  useEffect(() => {
    if (email) {
      signInWithEmailLink(email);
    }
  }, [email, signInWithEmailLink]);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <Typography variant="h4" align="center">
          Sign in Confirmation
        </Typography>
        <Typography variant="body1" align="center">
          Please provide your email for confirmation.
        </Typography>
        {error && <Alert severity="error">{error.code}</Alert>}
        <TextField
          id="outlined-basic"
          placeholder="Enter your email"
          variant="outlined"
          name="email"
          inputMode="email"
          type="email"
          autoFocus
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={!!(formik.touched.email && formik.errors.email)}
          helperText={(formik.touched.email && formik.errors.email) || " "}
          fullWidth
          required
          disabled={loading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton variant="contained" size="large" type="submit" fullWidth loading={loading}>
          Confirm Email
        </LoadingButton>
      </Stack>
    </Form>
  );
};

export default AuthCode;
