import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface SettingsMenuItemProps {
  text: string;
  path: () => string; // Assuming path is a function that returns a string
  tabIndexS: number; // Or any other appropriate type
  setDrawerOpen: (open: boolean) => void; // Assuming it's a function taking a boolean and returning void
  currentTabIndex: number; // Or any other appropriate type
}

function SettingsMenuItem({ text, path, tabIndexS, setDrawerOpen, currentTabIndex }: SettingsMenuItemProps) {
  return (
    <Link to={path()} style={{ textDecoration: 'none' }}>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={() => setDrawerOpen(false)}
          sx={{
            minHeight: 48,
            justifyContent: "left",
            minWidth: "140px",
            px: 2.5,
          }}
        >
          <ListItemIcon sx={{ ml: 1, justifyContent: "center" }}>
            <ListItemText primary={
              <Typography variant="body1" fontWeight={500} color={tabIndexS === currentTabIndex ? "#5C6CED" : "grey"}>
                {text}
              </Typography>
            } />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <Divider sx={{ width: "70%", mx: 'auto' }} />
    </Link>
  );
}

export default SettingsMenuItem;