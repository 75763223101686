import { useMutation, useSubscription } from "@apollo/client";
import { Container, Paper } from "@mui/material";
import gql from "graphql-tag";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { auth } from "../../../firebase";
import theme from "../../../theme";
import ChatInput from "./ChatInput";
import ChatWindow from "./ChatWindow";

const CHAT_MUTATION_WITH_STREAM = gql`
mutation ChatWithSearchStream($message: MessageInput!, $history: [MessageInput!]!, $workspaceId: String!) {
  chatWithSearchStream(message: $message, history: $history, workspaceId: $workspaceId) {
    response {
      role
      content
      functionName
      functionArguments
    }
    sources {
      documentParts {
        docPartId
        documentId
        content
        author
        title
        source
        type
      }
    }
    completed
    
  }
}
`;

const CHAT_STREAM_WS = gql`
subscription Subscription($userId: String!, $workspaceId: String!) {
  chatStream(userId: $userId, workspaceId: $workspaceId) {
    role
    chunkData
  }
}
`;

const STOP_STREAM_MUTATION = gql`
mutation Mutation($userId: String!, $workspaceId: String!) {
    stopStream(userId: $userId, workspaceId: $workspaceId)
  }
`

const SHARE_QUERIES_MUTATION = gql(`
  mutation SharingQueries($sharingQueries: Boolean!) {
    sharingQueries(sharingQueries: $sharingQueries) {
      id
      uid
      sharingQueries
    }
  }
`);


const Chat: React.FC = () => {
    const { workspaceId } = useParams();
    const [user] = useAuthState(auth);
    const userId = user?.uid;
    const [history, setHistory] = useState<{ content: string; role: string; completed?: boolean; sources?: any[] }[]>([]);
    const [inputText, setInputText] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const abortController = useRef(new AbortController());
    const [isTyping, setIsTyping] = useState(false);
    const [isInputStopWhileStream, setisInputStopWhileStream] = useState(false);

    const [shareQueries, { loading: shareQueriesLoading }] = useMutation(SHARE_QUERIES_MUTATION);
    const authContext = useAuth();
    const [localSharingStatus, /*setLocalSharingStatus*/] = useState(authContext?.user?.sharingQueries);

    const handleShareQueriesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSharingStatus = event.target.checked;
        try {
            await shareQueries({ variables: { sharingQueries: newSharingStatus } });
            authContext.refreshUser?.();
        } catch (error) {
            console.error("Error sharing queries:", error);
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    };

    const [chatMutation, { error: errorMutationWithStream }] = useMutation(CHAT_MUTATION_WITH_STREAM);

    const [stopStreamMutation] = useMutation(STOP_STREAM_MUTATION);

    const handleStopWaiting = () => {
        stopStreamMutation({ variables: { userId, workspaceId } });
        setIsLoading(false);
        setisInputStopWhileStream(false);
    };

    useSubscription(CHAT_STREAM_WS, {
        variables: { userId, workspaceId },
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData?.data?.chatStream) {
                const chunk = JSON.parse(subscriptionData.data.chatStream.chunkData);
                // Handle chunk data to update the history state
                if (chunk.finish_reason === 'end') {
                    setIsTyping(false);
                    setHistory(prevHistory => prevHistory.map((message, index) =>
                        index === prevHistory.length - 1 ? { ...message, completed: true } : message
                    ));
                } else {
                    setIsTyping(true);
                    if (!chunk.content || chunk.content.trim() === '') {
                        return;
                    }
                    setHistory(prevHistory => {
                        const lastMessageIndex = prevHistory.length - 1;
                        if (lastMessageIndex >= 0 && prevHistory[lastMessageIndex].role === subscriptionData.data.chatStream.role) {
                            const updatedMessage = {
                                ...prevHistory[lastMessageIndex],
                                content: (prevHistory[lastMessageIndex].content + chunk.content).trim(),
                                completed: false
                            };
                            return [...prevHistory.slice(0, -1), updatedMessage];
                        } else {
                            return [...prevHistory, { role: subscriptionData.data.chatStream.role, content: chunk.content.trim(), completed: false }];
                        }
                    });
                }
            }
        }
    });

    const handleSendMessage = async () => {
        setIsLoading(true);
        setIsTyping(false);
        setisInputStopWhileStream(true);

        abortController.current = new AbortController();
        if (inputText && inputText.trim().length !== 0) {
            const userMessage = { role: "user", content: inputText.trim() };
            setHistory((prevHistory) => [...prevHistory, userMessage]);
            await new Promise((resolve) => setTimeout(resolve, 0));
            console.log("Input:");
            const historyCopy = history.map((el) => ({ role: el.role, content: el.content }));
            try {
                const { data } = await chatMutation({
                    variables: { workspaceId, message: userMessage, history: [...historyCopy, userMessage] },
                    context: { fetchOptions: { signal: abortController.current.signal } },
                });

                // Update Stream history with sources
                if (data.chatWithSearchStream.sources) {
                    const sources = data.chatWithSearchStream.sources;
                    setHistory(prevHistory => {
                        const lastMessageIndex = prevHistory.length - 1;
                        if (lastMessageIndex >= 0) {

                            // Attach sources to the last message
                            const updatedMessage = { ...prevHistory[lastMessageIndex], sources };
                            return [...prevHistory.slice(0, -1), updatedMessage];
                        }
                        return prevHistory;
                    });
                } else {
                    //console.log('Sources are null');
                }

            } catch (error) {
                if (error instanceof Error && error.name !== 'AbortError') {
                    //console.error('Error in chatMutation:', error);
                }
            } finally {
                setIsLoading(false);
                setIsTyping(true);
                setInputText("");
                setisInputStopWhileStream(false);
            }
        }
    };

    useEffect(() => {
        const chatWindow = document.getElementById("chat-window");
        if (chatWindow) {
            chatWindow.scrollTop = chatWindow.scrollHeight;
        }
    }, [history]);

    return (
        <Container sx={{ height: "95%", maxHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", boxShadow: "none" }}>
            <Paper sx={{ height: "100%", backgroundColor: "#F7F7F9", borderRadius: theme.shape.borderRadius * 2.6, display: "flex", flexDirection: "column", justifyContent: "center", boxShadow: "none", marginBottom: theme.spacing(0) }}>
                <ChatWindow
                    messages={history.filter((m) => m.role !== "system").map((m) => ({ isUser: m.role === "user", text: m.content, sources: m.sources, completed: m.completed }))}
                    isWaitingForReply={isLoading}
                    isTyping={isTyping}
                    errorMutationWithStream={errorMutationWithStream}
                />
            </Paper>
            <ChatInput
                inputText={inputText}
                handleInputChange={handleInputChange}
                handleSendMessage={handleSendMessage}
                handleStopWaiting={handleStopWaiting}
                isWaitingForReply={isLoading}
                isInputStopWhileStream={isInputStopWhileStream}
                handleShareQueriesChange={handleShareQueriesChange}
                shareQueriesLoading={shareQueriesLoading}
                localSharingStatus={localSharingStatus}
            />
        </Container>
    );
};

export default Chat;
