import { useQuery } from "@apollo/client";
import { Box, Button, Card, CardContent, CardMedia, FormHelperText, List, ListItem, Skeleton, Typography } from "@mui/material";
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql } from "../../../__generated__/gql";
import { useUserWorkspaces } from "../../../context/WorkspaceContext";
import DocLoadB from "../../Common/images/docLoadB.png";
import GoogleDriveIcon from "../../Common/images/integrations_logo/googledrivelogo.svg";
import HubSpotIcon from "../../Common/images/integrations_logo/hubspotlogo.svg";
import Notion from "../../Common/images/integrations_logo/notionlogo.svg";
import SlackLogo from "../../Common/images/integrations_logo/slacklogo.svg";
import ZoomLogo from "../../Common/images/integrations_logo/zoomlogo.svg";

const GET_INTEGRATIONS_LIST = gql(`
query GetIntegrationsForWorkspace($workspaceId: String!) {
  getIntegrationsForWorkspace(workspaceId: $workspaceId) {
    id
    workspaceId
    userId
    integrationType
    metadata
    createdAt
    updatedAt
    authorized
  }
}`
);

const integrationsList = [
  { logo: HubSpotIcon, title: "HubSpot", integrationType: "HUBSPOT", isAvalible: false },
  { logo: SlackLogo, title: "Slack", integrationType: "SLACK", isAvalible: false },
  { logo: ZoomLogo, title: "Zoom", integrationType: "ZOOM", isAvalible: false },
  { logo: Notion, title: "Notion", integrationType: "NOTION", isAvalible: false },
  { logo: GoogleDriveIcon, title: "Google Drive", integrationType: "GOOGLE_DRIVE", isAvalible: true },
];

const EmptyData = () => {
  const { workspaceId } = useParams();

  const { activeWorkspace } = useUserWorkspaces();
  const isSubscriptionActive = activeWorkspace?.subscription?.status;
  const navigate = useNavigate();
  // const [connected, setConnected] = useState(integrationsList.map(() => false))
  const { loading, data } = useQuery(GET_INTEGRATIONS_LIST, {
    variables: { workspaceId: workspaceId! },
  });

  const [connected] = useState(integrationsList.map(integration => {
    if (data?.getIntegrationsForWorkspace?.some(integrationData => integrationData.integrationType === integration.integrationType)) {
      return integration.integrationType === "GOOGLE_DRIVE";
    }
    return false;
  }));

  const availableIntegrations = data?.getIntegrationsForWorkspace?.map((integration: { integrationType: string }) => integration.integrationType) || [];

  return (
    <Box display="flex" sx={{ backgroundColor: 'transparent' }}>
      {/* Left */}
      <Box
        display={'flex'}
        position="absolute"
        justifyContent="center"
        alignItems={'center'}
        left={1}
        sx={{
          backgroundColor: 'white',
          height: '100%',
          width: '50%'
        }}>

        <List sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexDirection: 'column' }}>

          <Typography variant="h5" fontWeight={600} p={5}>
            Connect apps
          </Typography>
          {loading ? (
            [...Array(5)].map((_, rowIndex) => (
              <ListItem key={rowIndex}>
                <Card sx={{
                  p: 1,
                  display: 'flex',
                  justifyContent: "center",
                  alignItems: "center",
                  width: 500,
                  pl: 3,
                  pr: 3,
                  background: 'rgba(61.63, 104.17, 255, 0.05)'
                }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 45, height: 45 }}
                  />
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Skeleton width={'100%'} />
                  </CardContent>
                </Card>
              </ListItem>
            ))
          ) : (
            <>
              {integrationsList.map((integration, index) => (
                <ListItem key={index}>
                  <Card sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    width: 500,
                    pl: 3,
                    pr: 3,
                    background: 'rgba(61.63, 104.17, 255, 0.05)'
                  }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 45, height: 45 }}
                      image={integration.logo}
                      alt={integration.title}
                    />
                    <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography variant="h6">
                        {integration.title}
                      </Typography>
                    </CardContent>
                    <Button variant="contained"
                      disabled={!integration.isAvalible && !availableIntegrations.includes(integration.integrationType)}
                      onClick={() => {
                        if (integration.isAvalible && !connected[index]) {
                          navigate(`/workspaces/${workspaceId}/settings/integrations`);
                        }
                      }}
                      sx={{
                        margin: 1,
                        background: connected[index] ? '#0CC155' : '',
                        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
                        '&:hover': {
                          color: 'white'
                        },
                        fontWeight: 500,
                        fontSize: 12,
                        textTransform: 'none'
                      }}
                    >
                      {connected[index] ? 'Connected' : integration.isAvalible ? 'Connect' : 'Coming..'}
                    </Button>
                  </Card>
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Box>

      {/* Right */}
      <Box
        position="absolute"
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        flexDirection={"column"}
        right={1} gap={3}
        sx={{
          height: '100%',
          width: '50%',
        }}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h5" fontWeight={600}>
            Upload your custom data
          </Typography>
        </Box>

        <img src={DocLoadB} alt="docLoad" height={100} />

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="body2" color={'#929292'}>
            .docx, .pdf, .txt
          </Typography>

          <Button
            variant="contained"
            disabled={!isSubscriptionActive}
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => navigate(`/workspaces/${workspaceId}/load-data`)}>
            + Upload data
          </Button>
          {!isSubscriptionActive && (
            <FormHelperText variant='standard' sx={{ color: 'orange', fontSize: 12, alignSelf: 'center' }}>
              You haven't active subscription, go to the <b>settings/plans</b>
            </FormHelperText>
          )}

        </Box>
      </Box>
    </Box>
  );
};

export default EmptyData;

