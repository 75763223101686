import { Avatar, Box, List, ListItem, ListItemButton, ListItemIcon, SvgIcon, Tooltip } from "@mui/material";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useUserWorkspaces } from "../../../context/WorkspaceContext";
import { stringAvatar } from "../../Common/Generator";
import { getTabIndex } from "../../Common/NavBarUtil";
import { ReactComponent as AChat } from '../../Common/images/Menu/Chat/achat.svg';
import { ReactComponent as IChat } from '../../Common/images/Menu/Chat/ichat.svg';
import { ReactComponent as AData } from "../../Common/images/Menu/Data/adata.svg";
import { ReactComponent as IData } from "../../Common/images/Menu/Data/idata.svg";
import { ReactComponent as ASearch } from '../../Common/images/Menu/Search/asearch.svg';
import { ReactComponent as ISearch } from '../../Common/images/Menu/Search/isearch.svg';
import { ReactComponent as ASettings } from "../../Common/images/Menu/Settings/asettings.svg";
import { ReactComponent as Isettings } from '../../Common/images/Menu/Settings/isettings.svg';
import { Drawer } from "../Drawer";

export const navbarSideWidth = "64px";

export default function NavbarSide() {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { workspaceId } = useParams();
  const tabIndex = getTabIndex(pathname, drawerOpen);
  const { activeWorkspace } = useUserWorkspaces();
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: { xs: "none", sm: "flex" },
        width: navbarSideWidth,
        backgroundColor: "#ffffff",
        boxShadow: 2,
        //borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        alignItems: "center",
      }}
    >
      <Box>
        <List sx={{ position: "absolute", top: 0, p: 0 }}>
          <ListItem key="profile-btn" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{
                minHeight: 48,
                maxWidth: navbarSideWidth,
                justifyContent: "center",
                px: 2.5,
              }}
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>{<Avatar {...stringAvatar(activeWorkspace?.title ?? "WS", false)} />}</ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <List>


          <Link to={`/workspaces/${workspaceId}/data`}>
            <Tooltip title="Manage Data" placement="right">
              <ListItem key="lst-btn" disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => setDrawerOpen(false)}
                  sx={{
                    minHeight: 48,
                    maxWidth: navbarSideWidth,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    {tabIndex === 2 || tabIndex === 5 || tabIndex === 6 ? <SvgIcon component={AData} style={{ overflow: "visible" }} /> : <SvgIcon component={IData} style={{ overflow: "visible" }} />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>

          <Link to={`/workspaces/${workspaceId}/chat`}>
            <Tooltip title="AI Chat" placement="right">
              <ListItem key="list-btn" disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => setDrawerOpen(false)}
                  sx={{
                    minHeight: 48,
                    maxWidth: navbarSideWidth,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    {tabIndex === 1 ? <SvgIcon component={AChat} style={{ overflow: "visible" }} /> : <SvgIcon component={IChat} style={{ overflow: "visible" }} />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>

          <Link to={`/workspaces/${workspaceId}`}>
            <Tooltip title="AI Search" placement="right">
              <ListItem key="list-btn" disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => setDrawerOpen(false)}
                  sx={{
                    minHeight: 48,
                    maxWidth: navbarSideWidth,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    {tabIndex === 0 ? <SvgIcon component={ASearch} style={{ overflow: "visible" }} /> : <SvgIcon component={ISearch} style={{ overflow: "visible" }} />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>

          <Link to={`/workspaces/${workspaceId}/settings`}>
            <Tooltip title="Settings" placement="right">
              <ListItem key="lst-btn" disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => setDrawerOpen(false)}
                  sx={{
                    minHeight: 48,
                    maxWidth: navbarSideWidth,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon sx={{ justifyContent: "center" }}>
                    {tabIndex === 4 ? <SvgIcon component={ASettings} style={{ overflow: "visible" }} /> : <SvgIcon component={Isettings} style={{ overflow: "visible" }} />}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>
        </List>
      </Box>
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
    </Box>
  );
}
