import { useMutation, useQuery } from "@apollo/client";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { gql } from "../../../../__generated__/gql";
import { UserWorkspaceRole } from "../../../../__generated__/graphql";
import { useAuth } from "../../../../context/AuthContext";
import { useUserWorkspaces } from "../../../../context/WorkspaceContext";
import { getUserAvatar } from "../../../Common/Generator";
import { WorkspaceInviteUserDialog } from "../../../Workspaces/WorkspaceInviteUserDialog";

const GET_WORKSPACE_USERS = gql(/* GraphQL */ `
  query Workspace($workspaceId: String!) {
    workspace(workspaceId: $workspaceId) {
      id
      title
      users {
        userId
        role
        user {
          email
          name
        }
      }
    }
  }
`);

const DELETE_WORKSPACE_USER = gql(`
mutation RemoveUserFromWorkspace($data: RemoveUserFromWorkspaceInput!) {
  removeUserFromWorkspace(data: $data) {
    success
    failed {
      userId
      error
    }
  }
}
`);

const UPDATE_WORKSPACE_USER = gql(`
mutation ChangeUserByAdmin($data: ChangeUserRoleInput!) {
  changeUserByAdmin(data: $data) {
    workspaceId
    userId
    role
  }
}`);

export default function UserPage() {
  const params = useParams<{ workspaceId?: string }>();
  const workspaceId = params.workspaceId || 'defaultWorkspaceId';
  const { data, refetch, loading: getUsersLoading } = useQuery(GET_WORKSPACE_USERS, {
    variables: { workspaceId: workspaceId! },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRemovingUsers, setIsRemovingUsers] = useState(false);
  const [showInviteUserDialog, setShowInviteUserDialog] = useState(false);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false);
  const { activeWorkspace } = useUserWorkspaces();
  const isSubscriptionActive = activeWorkspace?.subscription?.status;
  const workspace = data?.workspace;
  const { user } = useAuth();
  const invintatorId = user?.id || 'Someone';
  const isAdmin = !!workspace?.users?.find((workspaceUser) => workspaceUser.userId === user?.id && workspaceUser.role === "ADMIN");

  const [removeUser, { loading: deleteLoading }] = useMutation(DELETE_WORKSPACE_USER, {
    onCompleted: () => {
      setShowRemoveUserDialog(false);
      refetch();
    }
  });

  const handleOpenRemoveDialog = () => {
    setShowRemoveUserDialog(true);
  };

  const handleRemoveUsers = async () => {
    if (selectedUsers.length > 0) {
      setIsRemovingUsers(true);
      try {
        await removeUser({
          variables: { data: { workspaceId, userIds: selectedUsers } }
        });
        refetch();
      } catch (error) {
        console.error("Error removing users", error);
      }
      setIsRemovingUsers(false);
      setShowRemoveUserDialog(false);
      setSelectedUsers([]);
    }
  };

  const [updateUserRole, { loading: updateUserLoading }] = useMutation(UPDATE_WORKSPACE_USER, {
    onCompleted: () => refetch(),
  });

  const handleChangeUserRole = (workspaceId: string, userId: string, newRole: UserWorkspaceRole) => {
    updateUserRole({
      variables: {
        data: {
          workspaceId,
          userId,
          newRole
        }
      }
    });
  };

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = workspace?.users?.filter((n) => n.userId !== user?.id).map((n) => n.userId) || [];
      setSelectedUsers(newSelecteds);
      return;
    }
    setSelectedUsers([]);
  };

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, userId: string) => {
    event.stopPropagation();
    handleClick(event, userId);
  };

  const handleClick = (
    event: React.MouseEvent<unknown> | React.ChangeEvent<HTMLInputElement>,
    userId: string
  ) => {
    if (userId === user?.id) return;
    const selectedIndex = selectedUsers.indexOf(userId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      );
    }

    setSelectedUsers(newSelected);
  };

  const isSelected = (userId: string) => selectedUsers.indexOf(userId) !== -1;

  console.log('selectedUsers:', selectedUsers)
  const usersCount = data?.workspace?.users?.length;
  const quantityProduct = activeWorkspace?.subscription?.quantity;
  const attitudeCounts = (quantityProduct ?? 0) - (usersCount ?? 0);
  return (
    <Box sx={{ mt: 5, mb: 5, p: 1 }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Stack spacing={2} direction="row" justifyContent={'space-between'} alignItems="center">
          <FormControl>
            <Typography variant="h6">Workspace Users</Typography>
            <FormHelperText sx={{
              ml: 0.5,
              fontSize: 13,
              color: (attitudeCounts >= 0) ? 'blue' : 'red',
              fontWeight: 500,
            }}>
              {getUsersLoading ? <Skeleton /> : (`${usersCount} active / ${quantityProduct || '0'} paid ${quantityProduct === 1 ? 'user' : 'users'}`)}
            </FormHelperText>
          </FormControl>
          {isAdmin && (
            <FormControl>
              <Button disabled={!isSubscriptionActive || attitudeCounts <= 0} variant="contained" size='medium' onClick={() => setShowInviteUserDialog(true)}>
                + Invite Users
              </Button>
              {!isSubscriptionActive && (
                <FormHelperText variant='standard' sx={{ m: 0, color: 'orange', fontSize: 12, alignSelf: 'center', justifySelf: 'center' }}>
                  You haven't active subscription
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Stack>
        <TableContainer component={Paper} sx={{ borderRadius: 3, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)' }}>
          <Table sx={{ minWidth: 750 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: selectedUsers.length > 0 ? '#e1e3f0' : undefined }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedUsers.length > 0 && selectedUsers.length < (workspace?.users?.filter((n) => n.userId !== user?.id).length ?? 0)
                    }
                    checked={
                      selectedUsers.length > 0 && selectedUsers.length === (workspace?.users?.filter((n) => n.userId !== user?.id).length ?? 0)
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all users' }}
                  />
                </TableCell>
                {selectedUsers.length > 0 && isAdmin && (
                  <TableCell colSpan={4}>
                    <IconButton
                      aria-label="delete"
                      onClick={handleOpenRemoveDialog}
                      sx={{ color: 'red' }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                )}
                {selectedUsers.length === 0 && (
                  <>
                    <TableCell>User</TableCell>
                    <TableCell align="left">Role</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>

              {getUsersLoading && (
                [...Array(10)].map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {[...Array(3)].map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton variant="rounded" width="100%" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}

              {workspace?.users?.map((workspaceUser) => {
                const isItemSelected = isSelected(workspaceUser.userId);
                const isCurrentUser = workspaceUser.userId === user?.id;
                const alternativeRole = workspaceUser.role === 'ADMIN' ? 'MEMBER' : 'ADMIN';


                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={workspaceUser.userId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleCheckboxClick(event, workspaceUser.userId)}
                        inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${workspaceUser.userId}` }}
                        sx={{ opacity: isCurrentUser ? 0 : 1 }}
                        disabled={isCurrentUser}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <CardHeader
                        avatar={
                          (workspaceUser.user as any)?.photoURL ? (
                            <Avatar alt="profile photo" src={(workspaceUser.user as any)?.photoURL} />
                          ) : (
                            <Avatar src={getUserAvatar((workspaceUser.user as any)?.displayName || workspaceUser.user?.email || 'P')} />
                          )
                        }
                        title={workspaceUser.user?.email}
                        subheader={workspaceUser.user?.name}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {isCurrentUser ? (
                        `${workspaceUser.role} (you)`
                      ) : (
                        <Select
                          disabled={updateUserLoading}
                          style={{ background: updateUserLoading ? '#e6e3e3' : 'white' }}
                          value={workspaceUser.role}
                          onChange={(e) => handleChangeUserRole(workspaceId, workspaceUser.userId, e.target.value as UserWorkspaceRole)}
                        >
                          <MenuItem value={workspaceUser.role}>{workspaceUser.role}</MenuItem>
                          <MenuItem value={alternativeRole}>{alternativeRole}</MenuItem>
                        </Select>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <WorkspaceInviteUserDialog
          open={showInviteUserDialog}
          onClose={() => setShowInviteUserDialog(false)}
          onSubmit={async (res) => {
            console.log("new workspace res", res);
            setShowInviteUserDialog(false);
            await refetch();
          }}
          workspaceId={workspaceId!}
        />
        <Dialog
          open={showRemoveUserDialog}
          onClose={() => setShowRemoveUserDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Users from Workspace?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to remove this user from the workspace?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button disabled={deleteLoading} onClick={() => setShowRemoveUserDialog(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={deleteLoading}
              disabled={deleteLoading}
              onClick={handleRemoveUsers}
              autoFocus
            >
              Remove Users
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
}
