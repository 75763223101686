import { useMutation } from "@apollo/client";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { gql } from "../__generated__";
import { User } from "../__generated__/graphql";
import { auth } from "../firebase";

const UPDATE_USER_LAST_SEEN = gql(/* GraphQL */ `
  mutation UpdateUserLastSeenAt {
    updateUserLastSeenAt {
      id
      role
      uid
      email
      name
      lastSeenAt
      sharingQueries
      createdAt
      updatedAt
    }
  }
`);

interface Props {
  children?: ReactNode;
}

interface ContextValue {
  user: User | null;
  userLoading: boolean;
  refreshUser: () => void;
}

function useUpdateUserLastSeenAt(): [User | null, boolean, () => void] {
  const [firebaseUser, firebaseUserLoading] = useAuthState(auth);
  const [updateUserLastSeenAt] = useMutation(UPDATE_USER_LAST_SEEN);
  const [user, setUser] = useState<null | User>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const uid = firebaseUserLoading ? undefined : firebaseUser ? firebaseUser.uid : null;
  useEffect(() => {
    if (firebaseUserLoading) {
      setLoading(true);
      setUser(null);
      return;
    }
    if (uid) {
      setLoading(true);
      updateUserLastSeenAt().then((res) => {
        setUser(res.data?.updateUserLastSeenAt || null);
        setLoading(false);
      });
      return;
    }
    setUser(null);
    setLoading(false);
  }, [uid, firebaseUserLoading, updateUserLastSeenAt]);

  const refreshUser = () => {
    if (uid) {
      setLoading(true);
      updateUserLastSeenAt().then((res) => {
        setUser(res.data?.updateUserLastSeenAt || null);
        setLoading(false);
      });
    }
  };
  return [user, loading, refreshUser];
}

export const AuthContext = createContext<ContextValue | { [key: string]: never }>({});

export default function AuthContextProvider({ children }: Props) {
  const [user, userLoading, refreshUser] = useUpdateUserLastSeenAt();
  return <AuthContext.Provider value={{ user, userLoading, refreshUser }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
