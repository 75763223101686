import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";



const spinWithTrailKeyframes = `
@keyframes spinWithTrail {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}
`;
const styleSheet = document.createElement("style");
styleSheet.innerText = spinWithTrailKeyframes;
document.head.appendChild(styleSheet);

const theme = createTheme({
  components: {

    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          color: 'transparent', 
        },
        root: {
          maxWidth: '30px',
          maxHeight: '30px',
          backgroundImage: `url("/images/logoload.svg")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: 'cover',
          animation: "spinWithTrail 1.3s infinite linear",
        },  
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
            background: "#fff",
            borderRadius: '15px',
            ':hover': {
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
                '& fieldset': {
                    borderColor: '#dfe1e5', 
                    borderRadius: '15px',
                    boxShadow: '0 1px 6px rgba(32, 33, 36, 0.15)',
                },
                '&:hover fieldset': {
                    borderColor: '#dfe1e5',
                    borderRadius: '15px'
                },
                '&.Mui-focused fieldset': {
                    boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
                    borderColor: '#dfe1e5', 
                    borderWidth: '0.1px',
                    borderRadius: '15px',
                },
            },
            '& .MuiOutlinedInput-input': {
                padding: '13px 25px 13px 25px',
            },
            '& .MuiInputBase-inputMultiline': {
              marginTop: "10px",
              marginBottom: "10px"
              
            },
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:not(.Mui-checked) .MuiSvgIcon-root': { 
            color: '#CDD7E1', 
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none',
          boxShadow: 'none'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "100%",
          marginLeft: 1,
          marginRight: 1,
          borderRadius: "8px",
          boxShadow: "none",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: 'none',
      },
    }
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.20)',
        borderRadius: '5px',
        fontSize: '0.8rem',
        padding: '6px 24px 6px 8px', 
        width: 140, 
        height: 30, 
      },
    }
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.20)',
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: '0.8rem',
      }
    },
  },
    MuiAlert: {
      styleOverrides: {
        root: {
          //margin: '1rem',
          padding: '1rem',
          backgroundColor: '#EBF2FF',
          width: '100%',
          borderRadius: '20px',
          borderColor: 'transparent'
        }
      }
    },
  },
typography: {
    h6: {
      fontSize: 18,
      lineHeight: 1.4,
    },
      fontFamily: "Helvetica Neue"
  },
  
  palette: {
    primary: {
      main: "#2e44f2",
    },
    secondary: {
      main: "#121212",
    },
    error: {
      main: red.A400,
    },
    info: {
      main: "#fff",
    },
    background: {
      default: "#F7F7F9",
    },
  },

});

export default theme;