import { Container, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import LogoTitle from "../Common/LogoTitle";

const AuthOutlet = () => {
  return (
    <Container maxWidth="xs" sx={{ display: "flex", minHeight: "100%", alignItems: "center" }}>
      <Stack spacing={4} flex={1}>
        <LogoTitle />
        <Outlet />
      </Stack>
    </Container>
  );
};

export default AuthOutlet;
