import ReactGA from 'react-ga4';
import { getConfig } from './config';

// export const initGA = (userId: string | null) => {
//   if (getConfig().baseUrl === "https://app.volai.app" && userId !== 'clu4mab5b0000m031j0d56mfc') {
export const initGA = () => {
  if (getConfig().baseUrl === "https://app.volai.app") {
    ReactGA.initialize('G-YX97KGSSN8');
  }
};

export const logPageView = (pathname: string) => {
  if (getConfig().baseUrl === "https://app.volai.app") {
    ReactGA.send({ hitType: "pageview", page: pathname });
  }
};

export const logEvent = (category: string, action: string, label?: string) => {
  if (getConfig().baseUrl === "https://app.volai.app") {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};

export const setUser = (userId: string | null) => {
  if (getConfig().baseUrl === "https://app.volai.app") {
    if (userId) {
      ReactGA.set({ user_id: userId });
    } else {
      ReactGA.set({ user_id: undefined }); 
    }
  }
};
