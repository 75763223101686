const palette = [
  "#213459", 
  "#580eed",
  "#a30eed",
  "#d41373",
  "#16dec3",
  "#6377db",
  "#de314e",
  "#41cc58",
  "#a1c90e",
  "#ed570c",
  "#171717"
];

const stringToNumber = (s: string) => {
  let number = 0;
  for (let i = 0; i < s.length; i++) {
    number += s.charCodeAt(i);
  }
  return number;
}

// Color Avatar Generator
export const stringAvatar = (name: string, rounded: boolean = true) => {
  const index = stringToNumber(name) % palette.length;
  const color = palette[index];
  const borderRadius = rounded ? '50%' : '25%';

  return {
    sx: { bgcolor: color, borderRadius },
    children: getInitials(name)
  };
}

const getInitials = (name: string) => {
  const nameParts = name.split(" ");
  return nameParts.length > 1
    ? `${nameParts[0][0]}${nameParts[1][0]}`
    : `${name[0]}`;
};

// Picture Avatar Generator
const getAvatarPath = (index: number): string => {
  return `/images/avatars/avatar_${index}.JPG`;
}

export const getUserAvatar = (identifier: string): string => {
  const index = stringToNumber(identifier) % 33;
  return getAvatarPath(index + 1);
};

// Time Generator
export const getRandomDate = (identifier: string) => {
  const today = new Date();
  const pastDate = new Date(today);
  const yearOffset = stringToNumber(identifier) % 5;
  const dayOffset = stringToNumber(identifier) % 365;
  pastDate.setFullYear(today.getFullYear() - yearOffset);
  pastDate.setDate(today.getDate() - dayOffset);
  return pastDate;
}
