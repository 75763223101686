import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView, setUser } from '../analytics';
import { useAuth } from './AuthContext';

const AnalyticsManager = () => {
    const { user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        initGA();
    }, []);

    useEffect(() => {
        logPageView(location.pathname);
        setUser(user?.id || null);
    }, [location, user]);


    return null;
};

export default AnalyticsManager;
