import { useMutation } from '@apollo/client';
import { Button, Checkbox, CheckboxProps, FormControlLabel, Grid, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql } from '../../../../../__generated__/gql';
import { IntegrationType } from '../../../../../__generated__/graphql';
import { useAuth } from '../../../../../context/AuthContext';
import IntegrationGallery from '../IntegrationGallery';
import { DocumentsCards } from '../Integrations';

const CREATE_INTEGRATION = gql(`
mutation CreateIntegration($workspaceId: String!, $integrationType: IntegrationType!) {
    createIntegration(workspaceId: $workspaceId, integrationType: $integrationType) {
      id
      workspaceId
      userId
      integrationType
      metadata
      createdAt
      updatedAt
    }
  }
`);

interface CoverProps {
    integration: DocumentsCards;
    onClose: () => void;
    refetchIntegrations: () => void;
}

const RedCheckbox = styled(Checkbox)<CheckboxProps>(({ theme, checked }) => ({
    color: checked ? theme.palette.primary.main : theme.palette.error.main,
}));

const Cover: React.FC<CoverProps> = ({ integration, onClose, refetchIntegrations }) => {
    const [createIntegration, { loading: createIntegrationLoading }] = useMutation(CREATE_INTEGRATION);
    const navigate = useNavigate();
    const { workspaceId } = useParams();
    const { user } = useAuth();
    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleInstallClick = () => {
        if (isChecked) {
            createIntegration({
                variables: { workspaceId: workspaceId!, integrationType: IntegrationType.GoogleDrive }
            }).then(({ data }) => {
                onClose();
                refetchIntegrations();
                navigate(`/workspaces/${workspaceId}/settings/integrations?integrationId=${data?.createIntegration?.id}`);

            }).catch((error) => {
                console.error("Error creating integration:", error);
            });
        } else {
            setShowError(true);
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            setShowError(false);
        }
    };

    const isInstallDisabled = user?.email !== "user.test+7d6160e9-5d42-4449-86e1-1b7bb29647f4@volai.app" && user?.email !== "alex@volai.app";
    return (
        <>
            <Grid item xs={12} sm={4} sx={{ background: '#F5F5F5', padding: 4 }}>
                {integration?.Icon && <integration.Icon />}
                <Typography variant="h6" sx={{ mt: 1 }}>{integration?.title}</Typography>
                <Typography variant="body2">{integration?.subtitle}</Typography>
                <Button
                    variant="contained"
                    size='small'
                    onClick={handleInstallClick}
                    disabled={isInstallDisabled || createIntegrationLoading}
                    sx={{
                        mt: 2, mb: 1, borderRadius: '5px',
                        backgroundColor: '#3E68FF',
                        '&.Mui-disabled': {
                            backgroundColor: '#fce700',
                        }
                    }}
                >
                    {isInstallDisabled ? "Temporarily unavailable" : "Install"}
                </Button>
                <FormControlLabel
                    disabled={isInstallDisabled}
                    control={<RedCheckbox checked={isChecked} onChange={handleCheckboxChange} />}
                    label="I accept VOL AI’s privacy policy and grant access to my VOL AI account"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: 11, color: showError ? '#ff1744' : '#A3A3A3' } }} />
            </Grid>

            <Grid item xs={12} sm={8} sx={{ padding: 6 }}>
                <IntegrationGallery images={integration?.galleryImages || []} />
                <Typography variant="h6" sx={{ mb: 1, mt: 4 }}>Description</Typography>
                <Typography variant="body1">{integration?.description}</Typography>
            </Grid>
        </>
    );
};

export default Cover;