import { Box, List } from "@mui/material";
import { useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import SettingsMenuItem from "./settings-nav-bar-item";
import { getTabIndexSettings } from "./tabIndexS";

export const navbarSideSettingsWidth = "144px";
export const navbarItemWidth = "100%";

export default function NavbarSideSettings() {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { workspaceId } = useParams();
  const tabIndexSettings = getTabIndexSettings(pathname, drawerOpen);

  //const { activeWorkspace } = useUserWorkspaces();


  const menuItems = [
    {
      text: "Integrations",
      path: () => `/workspaces/${workspaceId}/settings/integrations`,
      tabIndex: 1.1
    },
    {
      text: "Workspace",
      path: () => `/workspaces/${workspaceId}/settings/workspace`,
      tabIndex: 1.5
    },
    {
      text: "Plans",
      path: () => `/workspaces/${workspaceId}/settings/plans`,
      tabIndex: 1.2
    },
    {
      text: "Users",
      path: () => `/workspaces/${workspaceId}/settings/users`,
      tabIndex: 1.3
    },
    {
      text: "Support",
      path: () => `/workspaces/${workspaceId}/settings/support`,
      tabIndex: 1.4
    },
  ];

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: "64px",
        //zIndex: (theme) => theme.zIndex.drawer + 1,
        display: { xs: "none", sm: "flex" },
        minWidth: navbarSideSettingsWidth,
        backgroundColor: "#ffffff",
        boxShadow: 2,
        alignItems: "center",
        zIndex: +1
      }}
    >
      <Box>

        <List>
          {menuItems.map((item) => (
            <SettingsMenuItem
              key={item.text}
              text={item.text}
              path={item.path}
              tabIndexS={item.tabIndex}
              setDrawerOpen={setDrawerOpen}
              currentTabIndex={tabIndexSettings}
            />
          ))}
        </List>

      </Box>

    </Box>
  );
}
