import theme from "../../../theme";

export const chatWindowPaperStyles = {
  boxShadow: "none",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  padding: 2,
  backgroundColor: "#F7F7F9",
  "& .MuiListItem-root": {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 1,
    "&.user-message": {
      justifyContent: "flex-end",
    },
  },
  "& .MuiListItemText-primary": {
    color: "#000",
    variant: "h6",
    borderRadius: theme.shape.borderRadius,
    padding: 1,
    "&.user-message": {
      backgroundColor: "transparent",
    },
  },
  "& .vol-reply": {
    borderRadius: theme.shape.borderRadius * 1.6,
    background: "#FFF",
    padding: "16px 16px",
  },
  "&::-webkit-scrollbar": {
    width: 3,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "b5b5b5", 
  },
  "&:hover": {
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "15px"
    },
  },
};