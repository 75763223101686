
import { ApolloError } from "@apollo/client";
import { Alert, Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Paper, Skeleton } from "@mui/material";
import 'highlight.js/styles/github.css';
import React from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import ReactMarkdown from 'react-markdown';
import { useParams } from "react-router-dom";
import { Typewriter } from 'react-simple-typewriter';
import { auth } from "../../../firebase";
import { getUserAvatar } from "../../Common/Generator";
import logo from "../../Common/images/logo.svg";
import BackgroundText from "./BackgroundText";
import { chatWindowPaperStyles } from "./ChatWindowPaperStyles";
import MessageSources from "./MessageSources";

// type DocumentPart = {
//     docPartId: string;
//     documentId: string;
//     content: string;
//     title: string;
// };

type Message = {
    text: string;
    isUser: boolean;
    completed?: boolean;
    lastChunk?: string;
    sources?: any[];
};

const ChatWindow: React.FC<{
    messages: Message[];
    isWaitingForReply: boolean;
    isTyping: boolean;
    errorMutationWithStream?: ApolloError;
}> = ({ errorMutationWithStream, messages, isWaitingForReply, isTyping }) => {
    const [user] = useAuthState(auth);
    const { workspaceId } = useParams<{ workspaceId: string }>();
    return (
        <Paper
            id="chat-window" sx={chatWindowPaperStyles}>
            {/* {Background Text} */}
            {messages.length === 0 && (
                <BackgroundText />
            )}

            {/* Chat */}
            <List sx={{ scrollbarColor: "#red #green" }}>
                {errorMutationWithStream && <Alert severity="error">{errorMutationWithStream.message}</Alert>}
                {!errorMutationWithStream && (
                    <>
                        {messages.map((message, index) => (
                            <Box key={"Box_" + index}>
                                <ListItem
                                    className={`${message.isUser ? "user-message" : "vol-reply"}`}
                                    sx={{
                                        alignItems: "flex-start",
                                        overflow: 'initial'
                                    }}
                                >

                                    <ListItemAvatar sx={{ paddingTop: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {!message.isUser && (
                                            <Avatar src={logo} sx={{ width: 34, height: 34 }} />
                                        )}
                                        {message.isUser && user?.photoURL && (
                                            <Avatar alt="profile photo" src={user?.photoURL!} sx={{ width: 34, height: 34 }} />
                                        )}
                                        {message.isUser && !user?.photoURL && (
                                            <Avatar
                                                src={getUserAvatar(user?.displayName || user?.email || "P")}
                                                sx={{ width: 34, height: 34 }} />
                                        )}
                                    </ListItemAvatar>


                                    <ListItemText primary={
                                        !message.isUser && !message.completed
                                            ? <>
                                                <ReactMarkdown>{message.text.replace(message.lastChunk || '', '')}</ReactMarkdown>
                                                <Typewriter
                                                    words={[message.lastChunk || '']}
                                                    loop={false}
                                                    cursor
                                                    cursorStyle=''
                                                    typeSpeed={10}
                                                    deleteSpeed={50}
                                                    delaySpeed={2500}
                                                />
                                            </>
                                            : <ReactMarkdown>{message.text}</ReactMarkdown>
                                    } />


                                </ListItem>
                                {!message.isUser && message.sources && workspaceId && (
                                    <MessageSources sources={message.sources} query={message.text} workspaceId={workspaceId} />
                                )}
                            </Box>
                        ))}

                        {isWaitingForReply && !isTyping && (
                            <ListItem className="loader-container">
                                <CircularProgress color="primary" sx={{ ml: 1 }} />
                                <Skeleton variant="rounded" width={'20%'} sx={{ ml: 2 }} />
                            </ListItem>
                        )}
                    </>
                )}
            </List>
        </Paper>
    );
};

export default ChatWindow;