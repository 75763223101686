import { useMutation } from "@apollo/client";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { gql } from "../../../../__generated__/gql";
import { DocumentsCards } from "./Integrations";

const UNINSTALL_INTEGRATION = gql(`
    mutation DeleteIntegration($integrationId: ID!, $workspaceId: String!) {
        deleteIntegration(integrationId: $integrationId, workspaceId: $workspaceId)
    }
`);

interface LeftSideComponentProps {
    integration: DocumentsCards;
    refetchIntegrations: () => void;
    onClose: () => void;
}

const LeftSideComponent: React.FC<LeftSideComponentProps> = ({
    integration,
    refetchIntegrations,
    onClose
}) => {
    const { workspaceId } = useParams() as { workspaceId: string };
    // const buttonColor = isDisabled ? '#cccccc' : '#de2339';
    // const buttonHoverColor = isDisabled ? '#bbbbbb' : '#f20723';
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIntegration, { loading: uninstallLoading, error }] = useMutation(UNINSTALL_INTEGRATION);

    const handleUninstallClick = () => {
        setOpenDialog(true); // Open the confirmation dialog
    };

    const handleConfirmUninstall = () => {
        if (integration?.integration?.id && workspaceId) {
            deleteIntegration({
                variables: {
                    integrationId: integration.integration.id,
                    workspaceId: workspaceId
                },
            }).then(response => {
                refetchIntegrations?.();
                onClose();
                setOpenDialog(false);
            }).catch(() => {
            });
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Grid item sx={{ background: '#F5F5F5', padding: 4, maxWidth: '260px' }}>
            {integration?.Icon && <integration.Icon />}
            <Typography variant="h6" sx={{ mt: 1 }}>{integration?.title}</Typography>
            <Typography variant="body2">{integration?.subtitle}</Typography>
            <Button
                variant="contained"
                size="small"
                sx={{
                    mt: 2,
                    mb: 1,
                    borderRadius: '5px',
                    backgroundColor: '#de2339',
                    '&:hover': {
                        backgroundColor: '#f20723',
                    },
                    color: 'white'
                }}
                onClick={handleUninstallClick}
                disabled={uninstallLoading}
            >
                Uninstall
            </Button>

            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Would you like to uninstall the integration? All related information will be deleted.
                    </DialogContentText>
                    {error && <Alert severity="error">{error.message}</Alert>}
                </DialogContent>
                <DialogActions sx={{ pb: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button variant='contained' disabled={uninstallLoading} onClick={handleConfirmUninstall}
                        sx={{ backgroundColor: 'red', ':hover': { backgroundColor: '#cc0808' } }}
                        autoFocus>
                        Ununstall integration
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default LeftSideComponent;