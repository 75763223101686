import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentsCards } from '../Integrations';
import AddItemComponent from './AddItemFlow/AddItemComponent';
import Cover from './Cover';
import ItemsStep from './ItemsStep';
import OAuthStep from './OAuthStep';

const GET_ITEMS = gql(`
    query GetProcessItemsForIntegration($integrationId: ID!, $workspaceId: String!) {
        getProcessItemsForIntegration(integrationId: $integrationId, workspaceId: $workspaceId) {
        id
        title
        integrationId
        externalId
        status
        statusCode
        url
        createdAt
        updatedAt
        }
    }
`);

const GET_ITEMS_BY_ID = gql(`
    query GetProcessItemsForIntegrationById($processItemId: ID!, $workspaceId: String!, $integrationId: ID!) {
        getProcessItemsForIntegrationById(processItemId: $processItemId, workspaceId: $workspaceId, integrationId: $integrationId) {
        id
        title
        integrationId
        externalId
        status
        statusCode
        url
        createdAt
        updatedAt
        processItemDocument {
            id
            processItemId
            author
            title
            uri
            source
            type
            externalId
            status
            statusCode
            metadata
            documentId
        }
        }
    }
`);

interface GoogleDriveFlowProps {
    integration: DocumentsCards;
    refetchIntegrations: () => void;
    onClose: () => void;
}

const GoogleDriveFlow: React.FC<GoogleDriveFlowProps> = ({ onClose, integration, refetchIntegrations }) => {
    const { workspaceId } = useParams() as { workspaceId: string };
    const initialStep = integration?.integration ? 'folderSelection' : 'cover';
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [getProcessItemContinue, { data: getItemByIdData, loading: loadingContinueFlow }] = useLazyQuery(GET_ITEMS_BY_ID, {});
    const [continueStep, setContinueStep] = useState('');
    const { data: getLinksData, loading: getLinksLoading, refetch: refetchGetItems } = useQuery(GET_ITEMS, {
        variables: { integrationId: integration.integration?.id || '', workspaceId },
    });

    const handleOAuthSuccess = () => {
        setCurrentStep('folderSelection');
    };

    const oauthStep = () => {
        setCurrentStep('oauth');
    };

    const handleAddItemClick = () => {
        setCurrentStep('addItem');
    };

    const continueFlow = (id: string) => {
        getProcessItemContinue({ variables: { processItemId: id, workspaceId, integrationId: integration.integration?.id } })
            .then(({ data }) => {
                if (data) {
                    if (data.getProcessItemsForIntegrationById.status === 'UNPROCESSED' && data.getProcessItemsForIntegrationById.processItemDocument.length === 0) {
                        setContinueStep('confirmLinkAdnExtractFiles');
                    } else if (data.getProcessItemsForIntegrationById.status === 'UNPROCESSED' && data.getProcessItemsForIntegrationById.processItemDocument.length > 0) {
                        setContinueStep('confirmExtractedFilesAndExtractContent');
                    } else if (data.getProcessItemsForIntegrationById.status === 'LOADED') {
                        setContinueStep('confirmExtractedContentAndLearn');
                    }
                }
                setCurrentStep('addItem');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {currentStep === 'cover' && <Cover refetchIntegrations={refetchIntegrations} onClose={onClose} integration={integration} />}
            {currentStep === 'oauth' && <OAuthStep onSuccess={handleOAuthSuccess} integration={integration} />}
            {currentStep === 'folderSelection' && (
                <ItemsStep
                    continueFlow={continueFlow}
                    integration={integration}
                    refetchIntegrations={refetchIntegrations}
                    handleOauthStepSuccess={handleOAuthSuccess}
                    oauthStep={oauthStep}
                    onAddItemClick={handleAddItemClick}
                    getLinksLoading={getLinksLoading}
                    getLinksData={getLinksData?.getProcessItemsForIntegration}
                    refetchGetItems={refetchGetItems}
                    onClose={onClose}
                    loadingContinueFlow={loadingContinueFlow}
                />
            )}
            {currentStep === 'addItem' && (
                <AddItemComponent
                    continueStep={continueStep}
                    onClose={onClose}
                    processItemContinue={getItemByIdData?.getProcessItemsForIntegrationById || undefined}
                    integration={integration}
                    refetchIntegrations={refetchIntegrations}
                    onCancel={() => setCurrentStep('folderSelection')}
                    refetchGetItems={refetchGetItems}
                />
            )}
        </>
    );
};

export default GoogleDriveFlow;