import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

import { Container } from "@mui/material";
import NavbarBottom from "./Navigation/NavbarBottom";
import NavbarSide, { navbarSideWidth } from "./Navigation/NavbarSide";

export default function Main() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Container
        sx={{
          pl: { xs: 1, sm: "128px", md: "128px", lg: "128px" },
          pr: {
            xs: 1,
            sm: navbarSideWidth,
            md: navbarSideWidth,
            lg: navbarSideWidth,
          },
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Container>
      <NavbarSide />
      <Container
        sx={{
          height: { sm: 0, xs: 64 },
        }}
      >
        <NavbarBottom
        />
      </Container>
    </Box>
  );
}
