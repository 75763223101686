import { Box, Dialog, ImageList, ImageListItem } from '@mui/material';
import React, { useState } from 'react';

type GalleryProps = {
    images: { img: string, title: string }[];
};

const IntegrationGallery: React.FC<GalleryProps> = ({ images }) => {
    const [selectedImg, setSelectedImg] = useState(images[0].img);
    const [open, setOpen] = useState(false);

    const handleOpen = (img: string) => {
        setSelectedImg(img);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box sx={{ marginBottom: '10px', cursor: 'pointer', }} onClick={() => handleOpen(selectedImg)}>

                <img src={selectedImg} alt="Selected" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
            </Box>
            <ImageList cols={images.length} rowHeight={64} gap={8} sx={{ width: '250px', height: 'auto', overflow: 'hidden' }}>
                {images.map((item, index) => (
                    <ImageListItem key={`${item.img}-${index}`} sx={{ cursor: 'pointer', opacity: selectedImg === item.img ? 1 : 0.6 }} onClick={() => setSelectedImg(item.img)}>
                        <img
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                            style={{ width: '100%', height: '100%', borderRadius: '5px' }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                <img src={selectedImg} alt="Zoomed" style={{ maxWidth: '100%', maxHeight: '100vh', borderRadius: '10px' }} onClick={handleClose} />
            </Dialog>
        </Box>
    );
};

export default IntegrationGallery;
