/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nmutation ChatWithSearchStream($message: MessageInput!, $history: [MessageInput!]!, $workspaceId: String!) {\n  chatWithSearchStream(message: $message, history: $history, workspaceId: $workspaceId) {\n    response {\n      role\n      content\n      functionName\n      functionArguments\n    }\n    sources {\n      documentParts {\n        docPartId\n        documentId\n        content\n        author\n        title\n        source\n        type\n      }\n    }\n    completed\n    \n  }\n}\n": types.ChatWithSearchStreamDocument,
    "\nsubscription Subscription($userId: String!, $workspaceId: String!) {\n  chatStream(userId: $userId, workspaceId: $workspaceId) {\n    role\n    chunkData\n  }\n}\n": types.SubscriptionDocument,
    "\nmutation Mutation($userId: String!, $workspaceId: String!) {\n    stopStream(userId: $userId, workspaceId: $workspaceId)\n  }\n": types.MutationDocument,
    "\n  mutation SharingQueries($sharingQueries: Boolean!) {\n    sharingQueries(sharingQueries: $sharingQueries) {\n      id\n      uid\n      sharingQueries\n    }\n  }\n": types.SharingQueriesDocument,
    "\n  query GetDataList($workspaceId: String!) {\n    getDataList(workspaceId: $workspaceId) {\n      id\n      source\n      type\n      author\n      title\n      createdAt\n    }\n  }\n": types.GetDataListDocument,
    "\n  mutation DeleteDocuments($documentIds: [String!]!, $workspaceId: String!) {\n    deleteDocuments(documentIds: $documentIds, workspaceId: $workspaceId)\n  }\n": types.DeleteDocumentsDocument,
    "\nquery GetIntegrationsForWorkspace($workspaceId: String!) {\n  getIntegrationsForWorkspace(workspaceId: $workspaceId) {\n    id\n    workspaceId\n    userId\n    integrationType\n    metadata\n    createdAt\n    updatedAt\n    authorized\n  }\n}": types.GetIntegrationsForWorkspaceDocument,
    "\n  mutation UploadAndTrain($workspaceId: String!, $documents: [DocumentInput!]!) {\n    uploadAndTrain(workspaceId: $workspaceId, documents: $documents)\n  }\n": types.UploadAndTrainDocument,
    "\n    mutation ListUnprocessedLocalDocuments($workspaceId: String!) {\n        listUnprocessedLocalDocuments(workspaceId: $workspaceId) {\n            title\n            status\n            size\n            id\n        }\n    }\n": types.ListUnprocessedLocalDocumentsDocument,
    "\nmutation changeUserFields($newName: String!) {\n  changeUserFieldsByUser(newName: $newName) {\n    id\n  }\n}\n": types.ChangeUserFieldsDocument,
    "\n  query DocLoad($docId: String!, $workspaceId: String!) {\n    docLoad(docId: $docId, workspaceId: $workspaceId) {\n      id\n      workspaceId\n      source\n      type\n      author\n      title\n      externalId\n      hash\n      status\n      uri\n      content\n      version\n      createdAt\n      updatedAt\n      parts{\n        documentId\n        externalId\n        author\n        content\n        vectorStoreId\n        uri\n        type\n        sequence\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.DocLoadDocument,
    "\n  query ExtractRelativeContentToTheQuery($userQuery: String!, $partContent: String!, $workspaceId: String!) {\n    extractRelativeContentToTheQuery(userQuery: $userQuery, partContent: $partContent, workspaceId: $workspaceId)\n  }\n": types.ExtractRelativeContentToTheQueryDocument,
    "\nquery DocSearch($workspaceId: String!, $query: String!) {\n  docSearch(workspaceId: $workspaceId, query: $query) {\n    results {\n      score\n      documentParts {\n        docPartId\n        documentId\n        content\n        author\n        title\n        source\n        type\n        createdAt\n      }\n    }\n    searchTime\n  }\n}\n": types.DocSearchDocument,
    "\nquery SearchSummary($userQuery: String!, $docPartIds: [String!]!) {\n  searchSummary(userQuery: $userQuery, docPartIds: $docPartIds) {\n    summary\n  }\n}\n": types.SearchSummaryDocument,
    "\n    mutation GoogleFolderToDataBase($url: String!, $integrationId: ID!, $workspaceId: String!) {\n        googleFolderToDataBase(url: $url, integrationId: $integrationId, workspaceId: $workspaceId) {\n        url\n        updatedAt\n        title\n        statusCode\n        status\n        integrationId\n        id\n        externalId\n        createdAt\n        }\n    }\n": types.GoogleFolderToDataBaseDocument,
    "\nmutation GetGoogleFilesFromTheFolder($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n    getGoogleFilesFromTheFolder(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n      id\n      title\n      integrationId\n      externalId\n      status\n      statusCode\n      url\n      createdAt\n      updatedAt\n      processItemDocument {\n        id\n        processItemId\n        author\n        title\n        uri\n        source\n        type\n        externalId\n        status\n        statusCode\n        metadata\n        documentId\n      }\n    }\n  }\n": types.GetGoogleFilesFromTheFolderDocument,
    "\nmutation PutGoogleDocumentContentToDB($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n    putGoogleDocumentContentToDB(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n      id\n      title\n      integrationId\n      externalId\n      status\n      statusCode\n      url\n      createdAt\n      updatedAt\n      processItemDocument {\n        id\n        processItemId\n        author\n        title\n        uri\n        source\n        type\n        externalId\n        status\n        statusCode\n        metadata\n        documentId\n      }\n    }\n  }\n": types.PutGoogleDocumentContentToDbDocument,
    "\n    mutation LearnFromGoogleDocuments($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n        learnFromGoogleDocuments(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        processItemDocument {\n            id\n            processItemId\n            author\n            title\n            uri\n            source\n            type\n            externalId\n            status\n            statusCode\n            metadata\n            documentId\n        }\n        }\n    }\n": types.LearnFromGoogleDocumentsDocument,
    "\nmutation CreateIntegration($workspaceId: String!, $integrationType: IntegrationType!) {\n    createIntegration(workspaceId: $workspaceId, integrationType: $integrationType) {\n      id\n      workspaceId\n      userId\n      integrationType\n      metadata\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateIntegrationDocument,
    "\n    query GetProcessItemsForIntegration($integrationId: ID!, $workspaceId: String!) {\n        getProcessItemsForIntegration(integrationId: $integrationId, workspaceId: $workspaceId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        }\n    }\n": types.GetProcessItemsForIntegrationDocument,
    "\n    query GetProcessItemsForIntegrationById($processItemId: ID!, $workspaceId: String!, $integrationId: ID!) {\n        getProcessItemsForIntegrationById(processItemId: $processItemId, workspaceId: $workspaceId, integrationId: $integrationId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        processItemDocument {\n            id\n            processItemId\n            author\n            title\n            uri\n            source\n            type\n            externalId\n            status\n            statusCode\n            metadata\n            documentId\n        }\n        }\n    }\n": types.GetProcessItemsForIntegrationByIdDocument,
    "\n    mutation DeleteProcessItemAndDocs($processItemId: ID!, $workspaceId: String!) {\n        deleteProcessItemAndDocs(processItemId: $processItemId, workspaceId: $workspaceId)\n    }\n": types.DeleteProcessItemAndDocsDocument,
    "\nquery GetIntegrationsForWorkspace($workspaceId: String!) {\n    getIntegrationsForWorkspace(workspaceId: $workspaceId) {\n      id\n      workspaceId\n      userId\n      integrationType\n      metadata\n      createdAt\n      updatedAt\n      authorized\n    }\n  }\n": types.GetIntegrationsForWorkspaceDocument,
    "\n    mutation DeleteIntegration($integrationId: ID!, $workspaceId: String!) {\n        deleteIntegration(integrationId: $integrationId, workspaceId: $workspaceId)\n    }\n": types.DeleteIntegrationDocument,
    "\n  query Workspace($workspaceId: String!) {\n    workspace(workspaceId: $workspaceId) {\n      id\n      title\n      users {\n        userId\n        role\n        user {\n          email\n          name\n        }\n      }\n    }\n  }\n": types.WorkspaceDocument,
    "\nmutation RemoveUserFromWorkspace($data: RemoveUserFromWorkspaceInput!) {\n  removeUserFromWorkspace(data: $data) {\n    success\n    failed {\n      userId\n      error\n    }\n  }\n}\n": types.RemoveUserFromWorkspaceDocument,
    "\nmutation ChangeUserByAdmin($data: ChangeUserRoleInput!) {\n  changeUserByAdmin(data: $data) {\n    workspaceId\n    userId\n    role\n  }\n}": types.ChangeUserByAdminDocument,
    "\n  mutation DeleteWorkspace($workspaceId: String!) {\n    deleteWorkspace(workspaceId: $workspaceId)\n  }\n": types.DeleteWorkspaceDocument,
    "\nmutation ChangeWorkspaceTitle($newTitle: String!, $workspaceId: String!) {\n  changeWorkspaceTitle(newTitle: $newTitle, workspaceId: $workspaceId)\n}\n": types.ChangeWorkspaceTitleDocument,
    "\n  mutation CreateWorkspace($data: AddWorkspaceInput!) {\n    createWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.CreateWorkspaceDocument,
    "\n  mutation InviteUsersToWorkspace($emails: [String!]!, $workspaceId: String!) {\n    inviteUsersToWorkspace(emails: $emails, workspaceId: $workspaceId) {\n      workspaceId\n      userId\n      role\n      joined\n      joinedAt\n      createdAt\n    }\n  }\n": types.InviteUsersToWorkspaceDocument,
    "\n  mutation AcceptWorkspace($data: AcceptWorkspaceInput!) {\n    acceptWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.AcceptWorkspaceDocument,
    "\n  mutation RejectWorkspace($data: AcceptWorkspaceInput!) {\n    rejectWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.RejectWorkspaceDocument,
    "\n  mutation UpdateUserLastSeenAt {\n    updateUserLastSeenAt {\n      id\n      role\n      uid\n      email\n      name\n      lastSeenAt\n      sharingQueries\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateUserLastSeenAtDocument,
    "\nquery User($take: Int) {\n  user(take: $take) {\n    id\n    role\n    uid\n    email\n    name\n    createdAt\n    updatedAt\n    workspaces {\n      workspaceId\n      userId\n      role\n      createdAt\n      joined\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n        subscription {\n          id\n          status\n          trialEnd\n          quantity\n          product\n          metadata {\n            key\n            value\n          }\n          currentPeriodEnd\n        }\n      }\n    }\n  }\n}\n": types.UserDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChatWithSearchStream($message: MessageInput!, $history: [MessageInput!]!, $workspaceId: String!) {\n  chatWithSearchStream(message: $message, history: $history, workspaceId: $workspaceId) {\n    response {\n      role\n      content\n      functionName\n      functionArguments\n    }\n    sources {\n      documentParts {\n        docPartId\n        documentId\n        content\n        author\n        title\n        source\n        type\n      }\n    }\n    completed\n    \n  }\n}\n"): (typeof documents)["\nmutation ChatWithSearchStream($message: MessageInput!, $history: [MessageInput!]!, $workspaceId: String!) {\n  chatWithSearchStream(message: $message, history: $history, workspaceId: $workspaceId) {\n    response {\n      role\n      content\n      functionName\n      functionArguments\n    }\n    sources {\n      documentParts {\n        docPartId\n        documentId\n        content\n        author\n        title\n        source\n        type\n      }\n    }\n    completed\n    \n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription Subscription($userId: String!, $workspaceId: String!) {\n  chatStream(userId: $userId, workspaceId: $workspaceId) {\n    role\n    chunkData\n  }\n}\n"): (typeof documents)["\nsubscription Subscription($userId: String!, $workspaceId: String!) {\n  chatStream(userId: $userId, workspaceId: $workspaceId) {\n    role\n    chunkData\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Mutation($userId: String!, $workspaceId: String!) {\n    stopStream(userId: $userId, workspaceId: $workspaceId)\n  }\n"): (typeof documents)["\nmutation Mutation($userId: String!, $workspaceId: String!) {\n    stopStream(userId: $userId, workspaceId: $workspaceId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SharingQueries($sharingQueries: Boolean!) {\n    sharingQueries(sharingQueries: $sharingQueries) {\n      id\n      uid\n      sharingQueries\n    }\n  }\n"): (typeof documents)["\n  mutation SharingQueries($sharingQueries: Boolean!) {\n    sharingQueries(sharingQueries: $sharingQueries) {\n      id\n      uid\n      sharingQueries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetDataList($workspaceId: String!) {\n    getDataList(workspaceId: $workspaceId) {\n      id\n      source\n      type\n      author\n      title\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetDataList($workspaceId: String!) {\n    getDataList(workspaceId: $workspaceId) {\n      id\n      source\n      type\n      author\n      title\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteDocuments($documentIds: [String!]!, $workspaceId: String!) {\n    deleteDocuments(documentIds: $documentIds, workspaceId: $workspaceId)\n  }\n"): (typeof documents)["\n  mutation DeleteDocuments($documentIds: [String!]!, $workspaceId: String!) {\n    deleteDocuments(documentIds: $documentIds, workspaceId: $workspaceId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetIntegrationsForWorkspace($workspaceId: String!) {\n  getIntegrationsForWorkspace(workspaceId: $workspaceId) {\n    id\n    workspaceId\n    userId\n    integrationType\n    metadata\n    createdAt\n    updatedAt\n    authorized\n  }\n}"): (typeof documents)["\nquery GetIntegrationsForWorkspace($workspaceId: String!) {\n  getIntegrationsForWorkspace(workspaceId: $workspaceId) {\n    id\n    workspaceId\n    userId\n    integrationType\n    metadata\n    createdAt\n    updatedAt\n    authorized\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UploadAndTrain($workspaceId: String!, $documents: [DocumentInput!]!) {\n    uploadAndTrain(workspaceId: $workspaceId, documents: $documents)\n  }\n"): (typeof documents)["\n  mutation UploadAndTrain($workspaceId: String!, $documents: [DocumentInput!]!) {\n    uploadAndTrain(workspaceId: $workspaceId, documents: $documents)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ListUnprocessedLocalDocuments($workspaceId: String!) {\n        listUnprocessedLocalDocuments(workspaceId: $workspaceId) {\n            title\n            status\n            size\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ListUnprocessedLocalDocuments($workspaceId: String!) {\n        listUnprocessedLocalDocuments(workspaceId: $workspaceId) {\n            title\n            status\n            size\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation changeUserFields($newName: String!) {\n  changeUserFieldsByUser(newName: $newName) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation changeUserFields($newName: String!) {\n  changeUserFieldsByUser(newName: $newName) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query DocLoad($docId: String!, $workspaceId: String!) {\n    docLoad(docId: $docId, workspaceId: $workspaceId) {\n      id\n      workspaceId\n      source\n      type\n      author\n      title\n      externalId\n      hash\n      status\n      uri\n      content\n      version\n      createdAt\n      updatedAt\n      parts{\n        documentId\n        externalId\n        author\n        content\n        vectorStoreId\n        uri\n        type\n        sequence\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query DocLoad($docId: String!, $workspaceId: String!) {\n    docLoad(docId: $docId, workspaceId: $workspaceId) {\n      id\n      workspaceId\n      source\n      type\n      author\n      title\n      externalId\n      hash\n      status\n      uri\n      content\n      version\n      createdAt\n      updatedAt\n      parts{\n        documentId\n        externalId\n        author\n        content\n        vectorStoreId\n        uri\n        type\n        sequence\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ExtractRelativeContentToTheQuery($userQuery: String!, $partContent: String!, $workspaceId: String!) {\n    extractRelativeContentToTheQuery(userQuery: $userQuery, partContent: $partContent, workspaceId: $workspaceId)\n  }\n"): (typeof documents)["\n  query ExtractRelativeContentToTheQuery($userQuery: String!, $partContent: String!, $workspaceId: String!) {\n    extractRelativeContentToTheQuery(userQuery: $userQuery, partContent: $partContent, workspaceId: $workspaceId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery DocSearch($workspaceId: String!, $query: String!) {\n  docSearch(workspaceId: $workspaceId, query: $query) {\n    results {\n      score\n      documentParts {\n        docPartId\n        documentId\n        content\n        author\n        title\n        source\n        type\n        createdAt\n      }\n    }\n    searchTime\n  }\n}\n"): (typeof documents)["\nquery DocSearch($workspaceId: String!, $query: String!) {\n  docSearch(workspaceId: $workspaceId, query: $query) {\n    results {\n      score\n      documentParts {\n        docPartId\n        documentId\n        content\n        author\n        title\n        source\n        type\n        createdAt\n      }\n    }\n    searchTime\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery SearchSummary($userQuery: String!, $docPartIds: [String!]!) {\n  searchSummary(userQuery: $userQuery, docPartIds: $docPartIds) {\n    summary\n  }\n}\n"): (typeof documents)["\nquery SearchSummary($userQuery: String!, $docPartIds: [String!]!) {\n  searchSummary(userQuery: $userQuery, docPartIds: $docPartIds) {\n    summary\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation GoogleFolderToDataBase($url: String!, $integrationId: ID!, $workspaceId: String!) {\n        googleFolderToDataBase(url: $url, integrationId: $integrationId, workspaceId: $workspaceId) {\n        url\n        updatedAt\n        title\n        statusCode\n        status\n        integrationId\n        id\n        externalId\n        createdAt\n        }\n    }\n"): (typeof documents)["\n    mutation GoogleFolderToDataBase($url: String!, $integrationId: ID!, $workspaceId: String!) {\n        googleFolderToDataBase(url: $url, integrationId: $integrationId, workspaceId: $workspaceId) {\n        url\n        updatedAt\n        title\n        statusCode\n        status\n        integrationId\n        id\n        externalId\n        createdAt\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation GetGoogleFilesFromTheFolder($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n    getGoogleFilesFromTheFolder(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n      id\n      title\n      integrationId\n      externalId\n      status\n      statusCode\n      url\n      createdAt\n      updatedAt\n      processItemDocument {\n        id\n        processItemId\n        author\n        title\n        uri\n        source\n        type\n        externalId\n        status\n        statusCode\n        metadata\n        documentId\n      }\n    }\n  }\n"): (typeof documents)["\nmutation GetGoogleFilesFromTheFolder($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n    getGoogleFilesFromTheFolder(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n      id\n      title\n      integrationId\n      externalId\n      status\n      statusCode\n      url\n      createdAt\n      updatedAt\n      processItemDocument {\n        id\n        processItemId\n        author\n        title\n        uri\n        source\n        type\n        externalId\n        status\n        statusCode\n        metadata\n        documentId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation PutGoogleDocumentContentToDB($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n    putGoogleDocumentContentToDB(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n      id\n      title\n      integrationId\n      externalId\n      status\n      statusCode\n      url\n      createdAt\n      updatedAt\n      processItemDocument {\n        id\n        processItemId\n        author\n        title\n        uri\n        source\n        type\n        externalId\n        status\n        statusCode\n        metadata\n        documentId\n      }\n    }\n  }\n"): (typeof documents)["\nmutation PutGoogleDocumentContentToDB($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n    putGoogleDocumentContentToDB(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n      id\n      title\n      integrationId\n      externalId\n      status\n      statusCode\n      url\n      createdAt\n      updatedAt\n      processItemDocument {\n        id\n        processItemId\n        author\n        title\n        uri\n        source\n        type\n        externalId\n        status\n        statusCode\n        metadata\n        documentId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation LearnFromGoogleDocuments($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n        learnFromGoogleDocuments(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        processItemDocument {\n            id\n            processItemId\n            author\n            title\n            uri\n            source\n            type\n            externalId\n            status\n            statusCode\n            metadata\n            documentId\n        }\n        }\n    }\n"): (typeof documents)["\n    mutation LearnFromGoogleDocuments($processItemId: String!, $integrationId: ID!, $workspaceId: String!) {\n        learnFromGoogleDocuments(processItemId: $processItemId, integrationId: $integrationId, workspaceId: $workspaceId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        processItemDocument {\n            id\n            processItemId\n            author\n            title\n            uri\n            source\n            type\n            externalId\n            status\n            statusCode\n            metadata\n            documentId\n        }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateIntegration($workspaceId: String!, $integrationType: IntegrationType!) {\n    createIntegration(workspaceId: $workspaceId, integrationType: $integrationType) {\n      id\n      workspaceId\n      userId\n      integrationType\n      metadata\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\nmutation CreateIntegration($workspaceId: String!, $integrationType: IntegrationType!) {\n    createIntegration(workspaceId: $workspaceId, integrationType: $integrationType) {\n      id\n      workspaceId\n      userId\n      integrationType\n      metadata\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetProcessItemsForIntegration($integrationId: ID!, $workspaceId: String!) {\n        getProcessItemsForIntegration(integrationId: $integrationId, workspaceId: $workspaceId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        }\n    }\n"): (typeof documents)["\n    query GetProcessItemsForIntegration($integrationId: ID!, $workspaceId: String!) {\n        getProcessItemsForIntegration(integrationId: $integrationId, workspaceId: $workspaceId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetProcessItemsForIntegrationById($processItemId: ID!, $workspaceId: String!, $integrationId: ID!) {\n        getProcessItemsForIntegrationById(processItemId: $processItemId, workspaceId: $workspaceId, integrationId: $integrationId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        processItemDocument {\n            id\n            processItemId\n            author\n            title\n            uri\n            source\n            type\n            externalId\n            status\n            statusCode\n            metadata\n            documentId\n        }\n        }\n    }\n"): (typeof documents)["\n    query GetProcessItemsForIntegrationById($processItemId: ID!, $workspaceId: String!, $integrationId: ID!) {\n        getProcessItemsForIntegrationById(processItemId: $processItemId, workspaceId: $workspaceId, integrationId: $integrationId) {\n        id\n        title\n        integrationId\n        externalId\n        status\n        statusCode\n        url\n        createdAt\n        updatedAt\n        processItemDocument {\n            id\n            processItemId\n            author\n            title\n            uri\n            source\n            type\n            externalId\n            status\n            statusCode\n            metadata\n            documentId\n        }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteProcessItemAndDocs($processItemId: ID!, $workspaceId: String!) {\n        deleteProcessItemAndDocs(processItemId: $processItemId, workspaceId: $workspaceId)\n    }\n"): (typeof documents)["\n    mutation DeleteProcessItemAndDocs($processItemId: ID!, $workspaceId: String!) {\n        deleteProcessItemAndDocs(processItemId: $processItemId, workspaceId: $workspaceId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetIntegrationsForWorkspace($workspaceId: String!) {\n    getIntegrationsForWorkspace(workspaceId: $workspaceId) {\n      id\n      workspaceId\n      userId\n      integrationType\n      metadata\n      createdAt\n      updatedAt\n      authorized\n    }\n  }\n"): (typeof documents)["\nquery GetIntegrationsForWorkspace($workspaceId: String!) {\n    getIntegrationsForWorkspace(workspaceId: $workspaceId) {\n      id\n      workspaceId\n      userId\n      integrationType\n      metadata\n      createdAt\n      updatedAt\n      authorized\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteIntegration($integrationId: ID!, $workspaceId: String!) {\n        deleteIntegration(integrationId: $integrationId, workspaceId: $workspaceId)\n    }\n"): (typeof documents)["\n    mutation DeleteIntegration($integrationId: ID!, $workspaceId: String!) {\n        deleteIntegration(integrationId: $integrationId, workspaceId: $workspaceId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Workspace($workspaceId: String!) {\n    workspace(workspaceId: $workspaceId) {\n      id\n      title\n      users {\n        userId\n        role\n        user {\n          email\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Workspace($workspaceId: String!) {\n    workspace(workspaceId: $workspaceId) {\n      id\n      title\n      users {\n        userId\n        role\n        user {\n          email\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation RemoveUserFromWorkspace($data: RemoveUserFromWorkspaceInput!) {\n  removeUserFromWorkspace(data: $data) {\n    success\n    failed {\n      userId\n      error\n    }\n  }\n}\n"): (typeof documents)["\nmutation RemoveUserFromWorkspace($data: RemoveUserFromWorkspaceInput!) {\n  removeUserFromWorkspace(data: $data) {\n    success\n    failed {\n      userId\n      error\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChangeUserByAdmin($data: ChangeUserRoleInput!) {\n  changeUserByAdmin(data: $data) {\n    workspaceId\n    userId\n    role\n  }\n}"): (typeof documents)["\nmutation ChangeUserByAdmin($data: ChangeUserRoleInput!) {\n  changeUserByAdmin(data: $data) {\n    workspaceId\n    userId\n    role\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteWorkspace($workspaceId: String!) {\n    deleteWorkspace(workspaceId: $workspaceId)\n  }\n"): (typeof documents)["\n  mutation DeleteWorkspace($workspaceId: String!) {\n    deleteWorkspace(workspaceId: $workspaceId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ChangeWorkspaceTitle($newTitle: String!, $workspaceId: String!) {\n  changeWorkspaceTitle(newTitle: $newTitle, workspaceId: $workspaceId)\n}\n"): (typeof documents)["\nmutation ChangeWorkspaceTitle($newTitle: String!, $workspaceId: String!) {\n  changeWorkspaceTitle(newTitle: $newTitle, workspaceId: $workspaceId)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateWorkspace($data: AddWorkspaceInput!) {\n    createWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateWorkspace($data: AddWorkspaceInput!) {\n    createWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InviteUsersToWorkspace($emails: [String!]!, $workspaceId: String!) {\n    inviteUsersToWorkspace(emails: $emails, workspaceId: $workspaceId) {\n      workspaceId\n      userId\n      role\n      joined\n      joinedAt\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  mutation InviteUsersToWorkspace($emails: [String!]!, $workspaceId: String!) {\n    inviteUsersToWorkspace(emails: $emails, workspaceId: $workspaceId) {\n      workspaceId\n      userId\n      role\n      joined\n      joinedAt\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AcceptWorkspace($data: AcceptWorkspaceInput!) {\n    acceptWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptWorkspace($data: AcceptWorkspaceInput!) {\n    acceptWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RejectWorkspace($data: AcceptWorkspaceInput!) {\n    rejectWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RejectWorkspace($data: AcceptWorkspaceInput!) {\n    rejectWorkspace(data: $data) {\n      workspaceId\n      userId\n      role\n      createdAt\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserLastSeenAt {\n    updateUserLastSeenAt {\n      id\n      role\n      uid\n      email\n      name\n      lastSeenAt\n      sharingQueries\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserLastSeenAt {\n    updateUserLastSeenAt {\n      id\n      role\n      uid\n      email\n      name\n      lastSeenAt\n      sharingQueries\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery User($take: Int) {\n  user(take: $take) {\n    id\n    role\n    uid\n    email\n    name\n    createdAt\n    updatedAt\n    workspaces {\n      workspaceId\n      userId\n      role\n      createdAt\n      joined\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n        subscription {\n          id\n          status\n          trialEnd\n          quantity\n          product\n          metadata {\n            key\n            value\n          }\n          currentPeriodEnd\n        }\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery User($take: Int) {\n  user(take: $take) {\n    id\n    role\n    uid\n    email\n    name\n    createdAt\n    updatedAt\n    workspaces {\n      workspaceId\n      userId\n      role\n      createdAt\n      joined\n      joinedAt\n      workspace {\n        id\n        title\n        createdAt\n        updatedAt\n        subscription {\n          id\n          status\n          trialEnd\n          quantity\n          product\n          metadata {\n            key\n            value\n          }\n          currentPeriodEnd\n        }\n      }\n    }\n  }\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;