import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Container, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserWorkspaces } from "../../context/WorkspaceContext";
import SubExpireIcon from "../Common/images/sub-expire.png";

interface Props {
  children: React.ReactElement;
}

const ProtectedWorkspaceRoute = ({ children }: Props) => {
  const location = useLocation();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { data, loading: loadingWorkspaces, setDefaultWorkspace } = useUserWorkspaces();
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  if (loadingWorkspaces) {
    return (
      <Container maxWidth="sm" sx={{ display: "flex", minHeight: "100%", alignItems: "center" }}>
        <Stack spacing={8} flex={1} direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  if (!workspaceId || workspaceId.length === 0) {
    return <Navigate to="/workspaces" replace state={{ from: location }} />;
  }

  const userWorkspace = data?.user?.workspaces?.find(ws => ws.workspaceId === workspaceId);
  const subscription = userWorkspace?.workspace?.subscription;

  if (userWorkspace?.workspace) {
    setDefaultWorkspace({ workspaceId: userWorkspace.workspaceId, title: userWorkspace.workspace.title });

    const isSubscriptionActive = subscription?.status || 'trial';
    if (!isSubscriptionActive) {
      setShowPaymentDialog(true);
    }

    const handleClose = () => setShowPaymentDialog(false);
    const handleSelectPlan = () => {
      handleClose();
      navigate(`/workspaces/${workspaceId}/settings/plans`);
    };
    const handleRequestExtension = () => {
      handleClose();
      navigate(`/workspaces/${workspaceId}/settings/support`);
    };

    return (
      <>
        {children}
        <Dialog open={showPaymentDialog} onClose={handleClose}>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', right: 10, top: 10, position: 'absolute' }} />
          <DialogContent>
            <Stack direction="row" spacing={2}>
              <img src={SubExpireIcon} alt="Subscription Over" style={{ width: '20%' }} />
              <Box >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography marginBottom={1} variant='h6'>Your subscription's over</Typography>
                </Stack>
                <Typography marginBottom={2}>You can still view everything in your account. Check out our plans to discover the option that fits your business best.
                </Typography>
                <Button variant='contained'
                  onClick={handleSelectPlan}
                  sx={{ mr: 1 }}>Select a plan</Button>
                <Button
                  color='secondary'
                  onClick={handleRequestExtension}>
                  Request a trial extension</Button>
              </Box>
            </Stack>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return <Navigate to="/" replace state={{ from: location }} />;
};

export default ProtectedWorkspaceRoute;