import { createBrowserRouter, Navigate } from "react-router-dom";
import { RouterContexts } from "../../context/RouterContexts";
import AuthCode from "../Auth/AuthCode";
import AuthEmail from "../Auth/AuthEmail";
import AuthLinkSent from "../Auth/AuthLinkSent";
import AuthOutlet from "../Auth/AuthOutlet";
import VolChat from "../Home/Chat/Сhat";
import Data from "../Home/Data/DataPage";
import LoadDataScreen from "../Home/Data/LoadDataScreen";
import Profile from "../Home/Profile/Profile";
import SearchDetails from "../Home/Search/Details/SearchDetails";
import Search from "../Home/Search/Search";
import Integrations from "../Home/Settings/Integrations/Integrations";
import PricingPage from "../Home/Settings/Plans/PricingPage";
import SettingsPage from "../Home/Settings/Settings";
import SupportPage from "../Home/Settings/Support/Support";
import UsersPage from "../Home/Settings/Users/Users";
import Workspace from "../Home/Settings/Workspace/WorkspaceSettings";
import Main from "../Main/Main";
import ProtectedAuthRoute from "../ProtectedRoute/ProtectedAuthRoute";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import ProtectedWorkspaceRoute from "../ProtectedRoute/ProtectedWorkspaceRoute";
import { Workspaces } from "../Workspaces/Workspaces";

const router = createBrowserRouter([
  {
    element: <RouterContexts />,
    children: [
      {
        path: "/auth",
        element: (
          <ProtectedAuthRoute>
            <AuthOutlet />
          </ProtectedAuthRoute>
        ),
        children: [
          {
            index: true,
            element: <AuthEmail />,
          },
          {
            path: "link-sent",
            element: <AuthLinkSent />,
          },
          {
            path: "code",
            element: <AuthCode />,
          },
        ],
      },
      {
        path: "/workspaces",
        element: (
          <ProtectedRoute>
            <Workspaces />
          </ProtectedRoute>
        ),
      },
      {
        path: "/workspaces/:workspaceId",
        element: (
          <ProtectedRoute>
            <ProtectedWorkspaceRoute>
              <Main />
            </ProtectedWorkspaceRoute>
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Search />,
          },
          {
            path: "search/:searchDetailsId",
            element: <SearchDetails />,
          },
          {
            path: "chat",
            element: <VolChat />,
          },
          {
            path: "data",
            element: <Data />,
          },
          {
            path: "load-data",
            element: <LoadDataScreen />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "settings",
            element: <SettingsPage />,
            children: [
              {
                index: true,
                element: <Navigate to="integrations" />
              },
              {
                path: "integrations",
                element: <Integrations />
              },
              {
                path: "workspace",
                element: <Workspace />
              },
              {
                path: "plans",
                element: <PricingPage />
              },
              {
                path: "users",
                element: <UsersPage />
              },
              {
                path: "support",
                element: <SupportPage />
              },
            ]

          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/workspaces" />,
      },
    ],
  },
]);

export default router;
