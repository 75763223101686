type Config = {
  baseUrl: string;
  api: { baseUrl: string };
};

const configs: { dev: Config; prod: Config } = {
  dev: {
    baseUrl: "http://localhost:3001",
    api: {
      baseUrl: "http://localhost:3000",
    },
  },
  prod: {
    baseUrl: "https://app.volai.app",
    api: {
      baseUrl: "https://api.volai.app",
    },
  },
};

export function getConfig(): Config {
  return window.location.hostname === "localhost" ? configs.dev : configs.prod;
}
