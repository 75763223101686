import { useMutation } from "@apollo/client";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Avatar,
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { gql } from "../../../__generated__/gql";
import { auth } from "../../../firebase";
import { getUserAvatar } from '../../Common/Generator';
import GoogleColoredIcon from "../../Common/images/GoogleColoredIcon";

const CHANGE_USER_MUTATION = gql(`
mutation changeUserFields($newName: String!) {
  changeUserFieldsByUser(newName: $newName) {
    id
  }
}
`);


export default function Profile() {
  const [user] = useAuthState(auth);
  const [isGoogleVerified, setIsGoogleVerified] = useState(false);
  const [isOtherVerified, setIsOtherVerified] = useState(false);

  const [signOutUser] = useSignOut(auth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeUser, { loading: changeUserLoading }] = useMutation(CHANGE_USER_MUTATION);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (user) {
      const names = user?.displayName ? user?.displayName.split(' ') : [];
      setFirstName(names[0] || '');
      setLastName(names[1] || '');
      setEmail(user?.email || '');
      const providerData = user?.providerData;
      setIsGoogleVerified(providerData?.some(provider => provider.providerId === 'google.com'));
      setIsOtherVerified(providerData?.some(provider => provider.providerId !== 'google.com' && user.emailVerified));
    }
  }, [user]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setIsDirty(true);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setIsDirty(true);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsDirty(true);
  };

  const confirmSignOut = () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      signOutUser();
    }
  };

  const handleSave = () => {
    // Call the mutation with the new name
    changeUser({ variables: { newName: `${firstName} ${lastName}` } });
    setIsDirty(false);
  };

  const handleCancel = () => {
    // Reset the fields to original values
    const names = user?.displayName ? user?.displayName.split(' ') : [];
    setFirstName(names[0] || '');
    setLastName(names[1] || '');
    setEmail(user?.email || '');
    setIsDirty(false);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "white", mt: 5, borderRadius: '10px' }}>
        <Box height={100} sx={{ p: 3 }}>
          <Typography variant="h6" > Personal info </Typography>
          <Typography variant="subtitle1" > Customize how your profile </Typography>
        </Box>
        <Divider variant='fullWidth' />
        <Box sx={{ display: 'flex', p: 5, gap: 5 }}>

          {user?.photoURL && <Avatar sx={{ mt: 2, width: 55, height: 55 }} alt="profile photo" src={user?.photoURL!} />}
          {!user?.photoURL && (
            <Avatar sx={{ mt: 2, width: 55, height: 55 }} src={getUserAvatar(user?.displayName || user?.email || 'P')} />
          )}
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={1}>
              <Typography variant="h6" fontWeight={600} fontSize={15}> Name</Typography>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <TextField
                  placeholder="First name"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  sx={{ width: 300 }}
                />
                <TextField
                  placeholder="Last name"
                  value={lastName}
                  onChange={handleLastNameChange}
                  sx={{ width: 300 }}
                />
              </Box>
              <Box display={'flex'} alignItems={'flex-start'} gap={1}>
                <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={1}>
                  <Typography variant="h6" fontWeight={600} fontSize={15}> Email</Typography>
                  <TextField
                    placeholder="email"
                    disabled
                    value={email}
                    onChange={handleEmailChange}
                    sx={{
                      width: 300,
                      backgroundColor: !user?.emailVerified ? 'lightblue' : 'inherit',
                      '& .Mui-disabled': {
                        color: '#676c78',
                        fontWeight: 500
                      }

                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ mr: -2 }}>
                          {isGoogleVerified && <GoogleColoredIcon />}
                          {isOtherVerified && !isGoogleVerified && <CheckCircleIcon color="primary" />}
                        </InputAdornment>
                      ),
                    }}
                  />

                </Box>

                <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={1}>
                  <Typography variant="h6" fontWeight={600} fontSize={15}> User ID</Typography>
                  <TextField
                    placeholder="User ID"
                    disabled
                    value={user?.uid}
                    onChange={handleEmailChange}
                    sx={{
                      width: 300,
                      '& .Mui-disabled': {
                        color: '#676c78',
                        fontWeight: 500
                      }
                    }}
                  />

                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider variant='fullWidth' />
        <Box height={100} sx={{ pl: 3, pr: 3 }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Button
            variant='contained'
            onClick={confirmSignOut}
            disabled={changeUserLoading}
            sx={{
              height: 40,
              fontSize: 15,
              backgroundColor: "#ed4e4e",
              ':hover': { backgroundColor: 'red' }
            }}>
            Sign out
          </Button>

          {isDirty || changeUserLoading ? (
            <Box display={'flex'} gap={2}>
              <Button
                variant='contained'
                onClick={handleCancel}
                disabled={changeUserLoading}
                sx={{
                  height: 40,
                  fontSize: 15,
                  backgroundColor: 'transparent',
                  border: 1,
                  borderColor: '#ededed',
                  color: 'black',
                  ':hover': { backgroundColor: '#dce1e3', boxShadow: 'none', border: 1, borderColor: '#ededed' }
                }}>
                Cancel
              </Button>
              <Button
                variant='contained'
                disabled={changeUserLoading}
                onClick={handleSave}
                sx={{
                  height: 40,
                  fontSize: 15,
                }}>
                Save
              </Button>
            </Box>
          ) : null
          }
        </Box>
      </Box >

    </>
  );
}