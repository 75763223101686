import { Email } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, InputAdornment, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSendSignInLinkToEmail, useSignInWithGoogle } from "react-firebase-hooks/auth";
import { Form, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getConfig } from "../../config";
import { auth } from "../../firebase";
import GoogleColoredIcon from "../Common/images/GoogleColoredIcon";
//import TextFieldStyled from "../Common/TextFieldStyled";


const AuthEmail = () => {
  const navigate = useNavigate();
  const [sendSignInLinkToEmail, sending, error] = useSendSignInLinkToEmail(auth);
  const [signInWithGoogle, /* _user */, loading, /* _signInWithGoogleError */] = useSignInWithGoogle(auth);
  const signInError = error as { code: string };
  const [showPasswordField, setShowPasswordField] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().when("email", {
        is: (email: string) => email === "user.test+7d6160e9-5d42-4449-86e1-1b7bb29647f4@volai.app",
        then: Yup.string().required("Password is required for this email"),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: async ({ email, password }) => {
      if (showPasswordField && password) {
        try {
          await signInWithEmailAndPassword(auth, email, password);
          navigate("/dashboard");
        } catch (error) {
          console.error("Error signing in with password: ", error);
        }
      } else {
        const sent = await sendSignInLinkToEmail(email, { url: `${getConfig().baseUrl}/auth/code`, handleCodeInApp: true });
        localStorage.setItem("emailToSignIn", email);
        if (sent) {
          navigate("/auth/link-sent", { state: { email } });
        }
      }
    },
  });

  useEffect(() => {
    const email = formik.values.email;
    if (email === "user.test+7d6160e9-5d42-4449-86e1-1b7bb29647f4@volai.app") {
      setShowPasswordField(true);
    } else {
      setShowPasswordField(false);
    }
  }, [formik.values.email]);

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {signInError && <Alert severity="error">{signInError.code}</Alert>}
          <TextField
            placeholder="Enter your email"
            variant="outlined"
            name="email"
            inputMode="email"
            type="email"
            autoFocus
            sx={{ '& .MuiOutlinedInput-input': { padding: '13px 25px 13px 5px', }, }}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.submitCount > 0 && !!formik.errors.email}
            helperText={formik.submitCount > 0 && formik.errors.email}
            fullWidth
            required
            disabled={sending}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email style={{ marginLeft: '10px', color: 'rgba(37, 85, 255, 0.80)' }} />
                </InputAdornment>

              ),
            }}
          />
          {showPasswordField && (
            <TextField
              placeholder="Enter your password"
              variant="outlined"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.submitCount > 0 && !!formik.errors.password}
              helperText={formik.submitCount > 0 && formik.errors.password}
              fullWidth
              required={showPasswordField}
              disabled={sending}
            />
          )}
          {/*<LoadingButton variant="contained" size="large" type="submit" fullWidth loading={sending} disabled={!formik.isValid || !formik.dirty} style={{borderRadius:'30px'}}> */}
          <LoadingButton variant="contained" size="large" type="submit" fullWidth loading={sending}>
            Sign in / Sign up
          </LoadingButton>
        </Stack>
      </Form>

      <LoadingButton
        variant="contained"
        size="large"
        onClick={() => signInWithGoogle()}
        fullWidth
        loading={loading}
        style={{
          marginTop: 20,
          backgroundColor: 'white',
          color: 'black',
          textTransform: 'none',
          boxShadow: '0 1px 6px rgba(32, 33, 36, 0.15)',
          //fontSize: '16px',
          gap: 10,
        }}
      >
        <GoogleColoredIcon style={{ width: 22, height: 22 }} />
        With Google
      </LoadingButton>
      <Typography variant="body2" color={'GrayText'} textAlign={'center'}>
        <a href="https://volai.app/terms-of-use" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Terms of use</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://volai.app/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Privacy policy</a>
      </Typography>

    </>
  );
};

export default AuthEmail;
