import { Avatar, Card, CardActionArea, CardContent, Container, List, Stack, Typography } from "@mui/material";
import React from 'react';
import { DocumentSource, DocumentType } from "../../../__generated__/graphql";
import getSourceIcon from "../../Common/SourcesIconTitleIdentify";

type DocumentPart = {
  docPartId: string;
  documentId: string;
  content: string;
  title: string;
  source: DocumentSource;
  type: DocumentType;
};

type Props = {
  sources: Array<{ documentParts: DocumentPart[] }>;
  query: string;
  workspaceId: string;
};

const MessageSources: React.FC<Props> = ({ query, sources, workspaceId }) => {
  if (!sources || sources.length === 0) return null;
  const documentParts = sources.flatMap(source => source.documentParts);

  return (
    <Container
      sx={{
        display: "flex",
        overflowX: "scroll",
        maxWidth: "100%",
      }}
    >
      <List sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        {documentParts.map((part, i) => (
          <Card
            key={i}
            sx={{
              height: 70,
              width: 400,
            }}
            // onClick={() => window.open(`/workspaces/${workspaceId}/search/${part.documentId}`, '_blank')}
            onClick={() => window.open(`/workspaces/${workspaceId}/search/${part.documentId}?docPartId=${part.docPartId}&query=${encodeURIComponent(query)}`, '_blank')}
          >
            <CardActionArea sx={{ height: '100%' }}>
              <CardContent sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={getSourceIcon({ source: part?.source, type: part?.type, intention: 'icon' })}
                  sx={{
                    width: 30,
                    mr: 1,
                    height: 'auto'
                  }}
                />
                <Stack
                  direction="row"
                  display="flow"
                  alignItems="center"
                >
                  <Typography variant="body1" fontWeight={500} fontSize={14} >
                    {part.title?.substring(0, 45)}
                  </Typography>
                  <Typography variant="body2">
                    {part.content?.substring(0, 43) + ".."}
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </List>
    </Container>
  );
};

export default MessageSources;
