import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Navigate, useLocation } from "react-router-dom";

const AuthLinkSent = () => {
  const { state } = useLocation();

const handleGmailRedirect = () => {
  window.open('https://mail.google.com', '_blank');
  };

  if (!state?.email) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
        Check your email for a sign-in link
      </Typography>
      <Typography variant="body1" align="center">
        We've sent a sign-in link to <strong>{state.email}</strong>. The link expires shortly, so please use it soon.
      </Typography>

      <Button variant="contained" color="primary" onClick={handleGmailRedirect}>
        Go to Gmail
      </Button>
    </Stack>
  );
};

export default AuthLinkSent;
