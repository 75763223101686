import { ApolloError } from '@apollo/client';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface DeleteConfirmationPopupProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    isLoading: boolean;
    error: ApolloError | undefined;
}


const DeleteConfirmationPopup: React.FC<DeleteConfirmationPopupProps> = ({
    open,
    onClose,
    onConfirm,
    isLoading,
    error
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the selected datasets?
                </DialogContentText>
                {error && <Alert severity="error">{error.message}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus disabled={isLoading}>
                    {isLoading ? 'Deleting...' : 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationPopup;
