import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { getConfig } from "../config";
import { getCurrentUser } from "../firebase";

const config = getConfig();

// const httpLink = createHttpLink({
//   uri: `${config.api.baseUrl}/graphql`,
// });

const httpLink = new HttpLink({
  uri: `${config.api.baseUrl}/graphql`,
});

const wsLink = new GraphQLWsLink(
    createClient({
    url: `${config.api.baseUrl.replace("http", "ws")}/graphql`,
    connectionParams: async () => {
      const user = await getCurrentUser();
      const token = await user?.getIdToken();
      return {
        Authorization: token ? `Bearer ${token}` : "",
      };
    },
    // reconnect: true,
  }),
);

const authLink = setContext(async (_, { headers }) => {
  const user = await getCurrentUser();
  const token = await user?.getIdToken();
  console.log("get token", token);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);

export const apolloClient = new ApolloClient({
  //link: authLink.concat(httpLink),
  link: splitLink,
  cache: new InMemoryCache(),
  assumeImmutableResults: true,
});
