import { QueryResult, useQuery } from "@apollo/client";
import { ReactNode, createContext, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { gql } from "../__generated__/gql";
import { StripeSubscription, UserQuery, WorkspaceUsers } from "../__generated__/graphql";
import { auth } from "../firebase";

const GET_USER_WORKSPACES = gql(/* GraphQL */ `
query User($take: Int) {
  user(take: $take) {
    id
    role
    uid
    email
    name
    createdAt
    updatedAt
    workspaces {
      workspaceId
      userId
      role
      createdAt
      joined
      joinedAt
      workspace {
        id
        title
        createdAt
        updatedAt
        subscription {
          id
          status
          trialEnd
          quantity
          product
          metadata {
            key
            value
          }
          currentPeriodEnd
        }
      }
    }
  }
}
`);

interface Props {
  children?: ReactNode;
}

interface WorkplaceObj {
  workspaceId: string;
  title: string;
  subscription?: StripeSubscription | null;
}

export const WorkspaceContext = createContext<
  QueryResult<UserQuery, { take: number }> & {
    setDefaultWorkspace: (val: WorkplaceObj | null) => void;
    getDefaultWorkspace: () => WorkplaceObj | null;
    getActiveWorkspace: () => WorkplaceObj | null;
    activeWorkspace: WorkplaceObj | null;
  }
>({} as any);

function WorkspaceContextProvider({ children }: Props) {
  const query = useQuery(GET_USER_WORKSPACES, { variables: { take: 100 } });
  const { workspaceId } = useParams();
  const activeWorkspace = getActiveWorkspace();
  useAuthState(auth, {
    onUserChanged: async (user) => {
      if (user) {
        await query.refetch();
      }
    },
  });

  function setDefaultWorkspace(workspace: WorkplaceObj | null) {
    if (workspace) {
      localStorage.setItem("workspace", JSON.stringify(workspace));
    } else {
      localStorage.removeItem("workspaceId");
    }
  }

  function getDefaultWorkspace(): WorkplaceObj | null {
    const workspaceJson = localStorage.getItem("workspace");
    return workspaceJson ? JSON.parse(workspaceJson) : null;
  }

  function getActiveWorkspace(): WorkplaceObj | null {
    const workspace = query.data?.user?.workspaces?.find((ws: WorkspaceUsers) => ws.workspaceId === workspaceId);
    const workspaceObj: WorkplaceObj | null = workspace ? {
      workspaceId: workspace.workspaceId,
      title: workspace.workspace?.title || "",
      subscription: workspace.workspace?.subscription || null,
    } : null;
    return workspaceObj || getDefaultWorkspace();
  }

  const value = { ...query as unknown as QueryResult<UserQuery, { take: number }>, setDefaultWorkspace, getDefaultWorkspace, getActiveWorkspace, activeWorkspace };
  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>;
}

function useUserWorkspaces() {
  return useContext(WorkspaceContext);
}

export default WorkspaceContextProvider;

export { useUserWorkspaces };
