import { Avatar, Box, Card, CardActionArea, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { IntegrationWithAuthorized } from "../../../../__generated__/graphql";

export type OauthMetadata = {
  picture: string;
  name: string;
  email: string;
};

type CardInfo = {
  title: string;
  subtitle: string;
  Icon: React.ElementType;
  integration?: IntegrationWithAuthorized;
};

const IntegrationCard: React.FC<{ info: CardInfo; onClick?: (integration?: IntegrationWithAuthorized) => void }> = ({ info, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const metadata = info.integration?.metadata ? JSON.parse(info.integration.metadata) as OauthMetadata : null;

  return (
    <Card sx={{ width: '250px', height: 'auto', "&:hover": { boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)" } }} onClick={() => onClick?.(info.integration)}>
      <CardActionArea>
        <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
          <Grid container spacing={0} display={'flex'} flexDirection={'column'}>
            <Grid item xs={12} sx={{ display: 'flex', height: '100%', boxShadow: '0 0.1px 0px 0.1px gray', position: 'relative' }}>
              {!imageLoaded && <Skeleton variant="rectangular" width="100%" height="100%" />}
              <info.Icon onLoad={() => setImageLoaded(true)} style={{ display: imageLoaded ? "block" : "none" }} />
              {metadata && imageLoaded && (
                <Box sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.7,
                  p: 0.3,
                  paddingLeft: 1,
                  paddingRight: 1,
                  backgroundColor: '#F2F2F2',
                  borderRadius: '30px',
                  width: 'fit-content',
                }}>
                  <Avatar src={metadata.picture} sx={{ width: 13, height: 13, borderRadius: '50%' }} />
                  <Typography variant="body2" color="text.secondary" sx={{ display: { xs: 'none', sm: 'block', fontSize: 12 } }}>
                    {metadata.email}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sx={{ padding: 2 }}>
              <Typography variant="h6" component="h2">{info.title}</Typography>
              <Typography variant="body2" color="text.secondary">{info.subtitle}</Typography>

            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default IntegrationCard;