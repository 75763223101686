import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, CardActionArea, CardHeader, Collapse, Typography, useTheme } from "@mui/material";
import { ReactElement } from "react";

export default function ExpandableCard({
  expanded,
  setExpanded,
  children,
  title,
}: {
  expanded: boolean;
  setExpanded: (val: boolean) => void;
  children: ReactElement;
  title: string;
}) {
  const theme = useTheme();
  const timeout = 300;
  return (
    <Card>
      <CardActionArea onClick={() => setExpanded(!expanded)}>
        <CardHeader
          title={<Typography variant="subtitle1">{title}</Typography>}
          action={
            <ExpandMoreIcon
              sx={{
                transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
                m: "8px",
                transition: theme.transitions.create("transform", {
                  duration: timeout,
                }),
              }}
            />
          }
        />
      </CardActionArea>
      <Collapse in={expanded} timeout={timeout} unmountOnExit>
        {children}
      </Collapse>
    </Card>
  );
}
