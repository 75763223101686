export function getTabIndex(pathname: string, drawerOpen: boolean): number {
  if (drawerOpen) return 7;

  if (pathname.indexOf("/chat") >= 0) {
    return 1;
  } else if (pathname.indexOf("/data") >= 0) {
    return 2;
  } else if (pathname.startsWith("/profile")) {
    return 3;
  } else if (pathname.indexOf("/settings") >= 0) {
    return 4;
  } else if (pathname.indexOf("/load-data") >= 0) {
    return 5;
  } else if (pathname.indexOf("/integrations") >= 0) {
    return 6;
  }
  return 0;
}
