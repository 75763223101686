import { Avatar, Box, Button, Card, Grid, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { useState } from 'react';
import { ProcessItem } from '../../../../../__generated__/graphql';
import GoogleColoredIcon from "../../../../Common/images/GoogleColoredIcon";
import IntegrationAuthorisationSettings from '../IntegrationAuthorisationSettings';
import { OauthMetadata } from '../IntegrationCard';
import { DocumentsCards } from '../Integrations';
import LeftSideComponent from '../LeftSideComponent';
import ItemCardComponent from './ItemCardComponent';


interface FolderSelectionStepProps {
    integration: DocumentsCards;
    refetchIntegrations: () => void;
    onAddItemClick: () => void;
    getLinksLoading: boolean;
    getLinksData: ProcessItem[];
    refetchGetItems: () => void;
    onClose: () => void;
    oauthStep: () => void;
    handleOauthStepSuccess: () => void;
    continueFlow: (id: string) => void;
    loadingContinueFlow?: boolean;
}

type TabValue = 'settings' | 'auth';

const ItemsStep = ({
    onClose,
    getLinksLoading,
    getLinksData,
    integration,
    refetchGetItems,
    refetchIntegrations,
    onAddItemClick,
    oauthStep,
    continueFlow,
    loadingContinueFlow
}: FolderSelectionStepProps) => {

    const [tabValue, setTabValue] = useState<TabValue>('settings');

    const handleChangeTab = (event: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };

    const metadata = integration?.integration?.metadata ? JSON.parse(integration.integration.metadata) as OauthMetadata : null;
    const authorized = integration?.integration?.authorized;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <LeftSideComponent
                integration={integration}
                refetchIntegrations={refetchIntegrations}
                onClose={onClose}
            />

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 0.5 }}>
                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example" >
                        <Tab label="Settings" value="settings" style={{ textTransform: 'capitalize' }} />
                        <Tab label="Auth" value="auth" style={{ textTransform: 'capitalize' }} />
                    </Tabs>
                </Box>
                {tabValue === 'settings' && (
                    <Grid item xs={12} sm={10} sx={{ padding: 4 }}>
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            width={'570px'}
                            alignItems={'flex-start'}
                            mt={1}
                            marginBottom={2}
                            sx={{
                                overflowY: 'auto',
                                padding: '8px',
                                boxSizing: 'border-box',
                            }}
                        >
                            {!getLinksLoading && getLinksData.length > 0 && (
                                <>
                                    <Box>
                                        <Typography variant="h6">Provide Folder Links</Typography>
                                        {!authorized && (<Typography variant="body2">
                                            Authorize your google account to  <br /> process private folders
                                        </Typography>)}
                                    </Box>
                                    <Box display={'flex'} gap={2} alignItems="flex-start">
                                        {!authorized && (
                                            <Button
                                                variant="contained"
                                                disabled={getLinksLoading}
                                                size='small'
                                                onClick={oauthStep}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    textTransform: 'none',
                                                    boxShadow: '0 1px 6px rgba(32, 33, 36, 0.15)',
                                                    gap: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#fafafc',
                                                    },
                                                }}
                                            >
                                                <GoogleColoredIcon style={{ width: 15, height: 15 }} />
                                                {authorized ? 'Authorized' : 'Authenticate'}
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            disabled={getLinksLoading}
                                            size='small'
                                            sx={{ pl: 2, pr: 2 }}
                                            onClick={onAddItemClick}
                                        >
                                            + Add folder link
                                        </Button>
                                    </Box>
                                </>)}
                        </Box>
                        {getLinksLoading && (
                            [...Array(5)].map((_, rowIndex) => (
                                <Card
                                    key={rowIndex}
                                    sx={{
                                        width: '570px',
                                        background: '#FBFBFB',
                                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                                        borderRadius: 3,
                                        gap: 1,
                                        mb: 2,
                                        mt: 2,
                                        p: 3,
                                    }}
                                >
                                    <Skeleton width={'100%'} />
                                </Card>
                            ))
                        )}

                        {!getLinksLoading && getLinksData.length > 0 ? (
                            getLinksData.map((item: ProcessItem) => (
                                <ItemCardComponent loadingContinueFlow={loadingContinueFlow} key={item.id} item={item} remove={true} inCompleteFlow={true} refetchGetItems={refetchGetItems} continueFlow={continueFlow} />
                            ))
                        ) : (
                            !getLinksLoading && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={4}
                                    sx={{ width: '120%', height: 400, userSelect: 'none' }}
                                >
                                    <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                                        <Typography variant="h5" fontWeight={600}>Provide folder links</Typography>
                                        <Typography variant="body1">
                                            Authorize your google account <br /> to process private folders
                                        </Typography>
                                    </Box>
                                    <Box display={'flex'} gap={2} flexDirection={'column'}>
                                        {!authorized ? (
                                            <Button
                                                variant="contained"
                                                disabled={getLinksLoading || authorized}
                                                size='small'
                                                onClick={oauthStep}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    textTransform: 'none',
                                                    boxShadow: '0 1px 6px rgba(32, 33, 36, 0.15)',
                                                    gap: 1,
                                                    '&:hover': {
                                                        backgroundColor: '#fafafc',
                                                    },
                                                    ...(authorized ? { '&:disabled': { backgroundColor: '#e3fcef' } } : {}),
                                                }}
                                            >
                                                <GoogleColoredIcon style={{ width: 15, height: 15 }} />
                                                {authorized ? `Authorized` : `Authenticate`}
                                            </Button>
                                        ) : (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                                                <Box sx={{ position: 'relative' }}>
                                                    <Avatar src={metadata?.picture} sx={{ width: 20, height: 20, borderRadius: '50%' }} />
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        bottom: 0,
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'white',
                                                    }}>
                                                        <GoogleColoredIcon style={{ width: '100%', height: '100%', padding: 1 }} />
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">{metadata?.email}</Typography>
                                            </Box>
                                        )}
                                        <Button
                                            variant="contained"
                                            disabled={getLinksLoading}
                                            size='small'
                                            sx={{ pl: 2, pr: 2 }}
                                            onClick={onAddItemClick}
                                        >
                                            + Add folder link
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        )}
                    </Grid>
                )}
                {tabValue === 'auth' && metadata && authorized && (
                    <IntegrationAuthorisationSettings metadata={metadata} />
                )}
            </Box>
        </Box>
    );
};

export default ItemsStep;