import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useUserWorkspaces } from '../../../../context/WorkspaceContext';
import './PricingPage.css';

// Declaration for the custom Stripe element
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
                'pricing-table-id': string;
                'publishable-key': string;
                'client-reference-id'?: string;
                onSuccess?: () => void;
            };
        }
    }
}

function formatDate(dateStr: string) {
    const now = new Date();
    const date = new Date(dateStr);
    const isSameYear = now.getFullYear() === date.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return isSameYear ? `${monthNames[date.getMonth()]} ${date.getDate()}` : `Until ${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

// PricingPage component
function PricingPage() {
    const { workspaceId } = useParams();
    const PRICING_TABLE_ID = "prctbl_1OxgSqJDSJHhd96kJ4eOjIxf";
    const CLIENT_REFERENCE_ID = `${workspaceId}`;
    const { activeWorkspace } = useUserWorkspaces();
    const productPaymentName = activeWorkspace?.subscription?.product;
    const productPaymentDate = activeWorkspace?.subscription?.currentPeriodEnd;
    const productTrialEnd = activeWorkspace?.subscription?.trialEnd;
    const quantityProduct = activeWorkspace?.subscription?.quantity;
    return (
        <div className="pricing-table-wrapper">
            {productPaymentName && quantityProduct && quantityProduct && (
                <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                    <Typography
                        variant='h5'
                        fontWeight={600}
                        mb={2}
                        align='center'>
                        Your current plan: <span style={{ color: "#5C6CED" }}>
                            {productPaymentName || '-'}, on {quantityProduct || '-'} {(quantityProduct === 1 ? 'user' : 'users')}
                        </span>
                    </Typography>

                    <Typography mb={2} variant='body1'>
                        {`Until: `}
                        <b>
                            {formatDate(productPaymentDate) || formatDate(productTrialEnd)}
                        </b>
                    </Typography>
                </Box>
            )}
            <stripe-pricing-table
                pricing-table-id={PRICING_TABLE_ID}
                publishable-key="pk_live_51L4nUzJDSJHhd96kcIjgkkhocZ97enmxkGjdILRb4unEKTZoGNACUKBdHVp8rKyndJlOt0pNyNX6PY9ndREOLnGC00U0JfUOov"
                client-reference-id={CLIENT_REFERENCE_ID}
            >
            </stripe-pricing-table>
        </div>
    );
}

export default PricingPage;
