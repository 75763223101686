import AddCircleIcon from "@mui/icons-material/AddCircle";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Drawer as MaterialDrawer,
  Stack,
  Typography,
  styled,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import {
  useCallback,
  useEffect,
  useState
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useUserWorkspaces } from "../../context/WorkspaceContext";
import { auth } from "../../firebase";
import theme from "../../theme";
import { getUserAvatar, stringAvatar } from "../Common/Generator";
import { WorkspaceCreateDialog } from "../Workspaces/WorkspaceCreateDialog";
import { navbarSideWidth } from "./Navigation/NavbarSide";

const DrawerBottom = styled(MaterialDrawer)({
  "& .MuiDrawer-paper": {
    marginBottom: "56px",
    height: "50%",
  },
});

const DrawerSide = styled(MaterialDrawer)({
  "& .MuiDrawer-paper": {
    marginLeft: navbarSideWidth,
  },
});

const getDrawer = (side: "left" | "bottom") =>
  side === "left" ? DrawerSide : DrawerBottom;

interface DrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  side?: "bottom" | "left" | undefined;
}

export function Drawer({
  drawerOpen,
  setDrawerOpen,
  side = "left",
}: DrawerProps) {
  const [user] = useAuthState(auth);
  const location = useLocation();
  const windowSize = useMediaQuery(theme.breakpoints.up("sm"));
  const { activeWorkspace: workspace, data } = useUserWorkspaces();
  const { pathname } = useLocation();
  // const [showWaitlistDialog, setShowWaitlistDialog] = useState(false);
  const [showWorkspaceCreateDialog, setShowWorkspaceCreateDialog] =
    useState(false);
  const { refetch } = useUserWorkspaces();
  const navigate = useNavigate();
  const DrawerStyled = getDrawer(side);

  const setDrawerOpenCallback = useCallback(
    (isOpen: boolean) => {
      setDrawerOpen(isOpen);
    },
    [setDrawerOpen]
  );

  useEffect(() => {
    setDrawerOpenCallback(false);
  }, [location, windowSize, setDrawerOpenCallback]);
  const { workspaceId } = useParams();
  //Waitlist check
  const checkEmail = (email: string | null | undefined): boolean => {
    const manualList = ["alex@volai.app", "boulat@volai.app", "oulmachev@gmail.com", "mrsdariaderkach@gmail.com", "thomasarthur.adewoye@gmail.com"];
    return manualList.includes(email || "");
  };

  const userCheckResult = checkEmail(user?.email);
  console.log(userCheckResult);
  //

  return (
    <DrawerStyled
      anchor={side}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box flexGrow={1} overflow="scroll">
        {workspace && (
          <List
            disablePadding
            sx={{ minWidth: "280px", width: "100%" }}
            subheader={
              <ListSubheader sx={{ backgroundColor: "#F1F5FF" }}>
                <Stack direction="row">
                  <Box display="flex" flexGrow={1} height={"55px"} alignItems={'center'}>
                    <Typography variant="h6" fontWeight={500} fontSize={17} color={"#000"}>
                      {workspace ? workspace.title : "Workspace"}
                    </Typography>
                  </Box>

                </Stack>
              </ListSubheader>
            }
          >
            {data?.user?.workspaces
              ?.filter((ws) => ws.workspaceId !== workspace.workspaceId)
              .map((ws) => (
                <ListItem key={ws.workspaceId} disablePadding>
                  <ListItemButton
                    component={Link}
                    to={`/workspaces/${ws.workspaceId}`}
                  >
                    <Avatar {...stringAvatar(ws?.workspace?.title ?? "ws", false)} />
                    <ListItemText
                      primary={ws.workspace?.title}
                      primaryTypographyProps={{
                        fontWeight: "medium",
                        variant: "body1",
                        color:
                          pathname === `/workspaces/${ws.workspaceId}`
                            ? "primary"
                            : undefined,
                      }}
                      sx={{ position: "relative", left: 12 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            <ListItem key="cr-ws" disablePadding>
              <ListItemButton
                onClick={() => {
                  setShowWorkspaceCreateDialog(true);
                }}
              >
                <AddCircleIcon
                  fontSize="large"
                  sx={{
                    color: "#F0F0F0",
                    // ml: "-3px",
                  }}
                />
                <Box textOverflow="ellipsis" marginLeft={1}>
                  Create workspace
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Box>

      <ListItemButton
        sx={{ display: "flex", height: "63px", flexGrow: 0 }}
        onClick={() => navigate(`/workspaces/${workspaceId}/profile`)}
      >
        {user?.photoURL && <Avatar alt="profile photo" src={user?.photoURL!} />}
        {!user?.photoURL && (
          <Avatar src={getUserAvatar(user?.displayName || user?.email || 'P')} />
        )}
        <Typography
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 1,
          }}
          sx={{ ml: "12px" }}
        >
          {user?.displayName ?? user?.email}
        </Typography>
        <TuneIcon color="action" sx={{ mr: 1, ml: 1 }} />
      </ListItemButton>

      {/* <WaitlistDialog
        open={showWaitlistDialog}
        onClose={() => setShowWaitlistDialog(false)}
      /> */}

      <WorkspaceCreateDialog
        open={showWorkspaceCreateDialog}
        onClose={() => setShowWorkspaceCreateDialog(false)}
        onSubmit={async (res) => {
          setShowWorkspaceCreateDialog(false);
          await refetch();
          const newWorkspace = res?.createWorkspace.workspace;
          if (newWorkspace) {
            navigate(`/workspaces/${newWorkspace.id}`);
          }
        }}
      />
    </DrawerStyled>
  );
}
