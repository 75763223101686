import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import AnalyticsManager from "./AnalyticsManager";
import AuthContextProvider from "./AuthContext";
import WorkspaceContextProvider from "./WorkspaceContext";

export function RouterContexts({ children }: { children?: ReactNode }) {

  return (
    <AuthContextProvider>
      <WorkspaceContextProvider>
        <AnalyticsManager />
        <Outlet />
        {children}
      </WorkspaceContextProvider>
    </AuthContextProvider>

  );
}