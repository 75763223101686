import { Box, Stack, Typography } from "@mui/material";
import logo from "../../Common/images/logo.svg";

const BackgroundText = () => {
  return (
    <Box
    style={{
      zIndex: "0",
      opacity: "0.7",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      pointerEvents: "none",
      userSelect: "none",
      gap: 10,
    }}
  >
    <Stack direction="row" justifyContent="center" spacing={2}>
      <img src={logo} className="App-logo" alt="logo" style={{ width: 45, height: 45 }} />

      <Stack direction="row">
        {" "}
        <Typography
          variant="h4"
          component="h2"
          whiteSpace="nowrap"
          sx={{
            fontFamily: "Helvetica Neue",
            fontWeight: 500,
            letterSpacing: "0.13em",
          }}
        >
          VOL AI
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          color="secondary"
          whiteSpace="nowrap"
          sx={{
            fontFamily: "Helvetica Neue",
            fontWeight: 500,
            ml: 2,
            letterSpacing: "0.1em",
          }}
        >
          Chat
        </Typography>
      </Stack>
    </Stack>
    <Box
      display="flow"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box flex="1" textAlign="center">
        <Typography variant="h6" color="textSecondary">
          {" "}
          Memory bank build on your company's internal data.{" "}
        </Typography>
      </Box>
      <Box flex="1" textAlign="center">
        <Typography variant="h6" color="textSecondary">
          {" "}
          Type your question to get started.{" "}
        </Typography>
      </Box>
    </Box>
  </Box>
  );
};

export default BackgroundText;
