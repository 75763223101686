import { Avatar, Box, Grid, Typography } from "@mui/material";
import GoogleColoredIcon from "../../../Common/images/GoogleColoredIcon";
import { OauthMetadata } from "./IntegrationCard";

interface IntegrationAuthorisationSettingsProps {
    metadata: OauthMetadata;
}

const IntegrationAuthorisationSettings: React.FC<IntegrationAuthorisationSettingsProps> = ({ metadata }) => {
    if (!metadata) return null;

    return (
        <Grid item sx={{ padding: 4, width: '640px' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}>
                <Box sx={{ position: 'relative' }}>
                    <Avatar src={metadata.picture} sx={{ width: 40, height: 40, borderRadius: '50%' }} />
                    <Box sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: 15,
                        height: 15,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                    }}>
                        <GoogleColoredIcon style={{ width: '100%', height: '100%', padding: 2 }} />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body1">{metadata.name}</Typography>
                    <Typography variant="body2" color="text.secondary">{metadata.email}</Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default IntegrationAuthorisationSettings;