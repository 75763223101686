import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Form } from "react-router-dom";
import * as Yup from "yup";
import { gql } from "../../__generated__";
import { CreateWorkspaceMutation } from "../../__generated__/graphql";
import { logEvent } from "../../analytics";

const CREATE_WORKSPACE = gql(/* GraphQL */ `
  mutation CreateWorkspace($data: AddWorkspaceInput!) {
    createWorkspace(data: $data) {
      workspaceId
      userId
      role
      createdAt
      joinedAt
      workspace {
        id
        title
        createdAt
        updatedAt
      }
    }
  }
`);

export function WorkspaceCreateDialog({
  onSubmit,
  onClose,
  open,
}: {
  onSubmit: (meeting: CreateWorkspaceMutation | undefined | null) => Promise<void>;
  onClose: () => void;
  open: boolean;
}) {
  const [createWorkspace, { error }] = useMutation(CREATE_WORKSPACE);

  const formik = useFormik({
    initialValues: {
      workspaceName: "",
    },
    validationSchema: Yup.object({
      workspaceName: Yup.string().required("Required"),
    }),
    onSubmit: async ({ workspaceName }) => {
      console.log("workspaceName", workspaceName);
      const res = await createWorkspace({
        variables: {
          data: {
            title: workspaceName,
          },
        },
      });
      await onSubmit(res.data);
      logEvent('create_workspace', 'user_created_workspace');
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Form onSubmit={formik.handleSubmit}>
        <DialogTitle>Create new workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>To create a workspace you need to give it a name</DialogContentText>
          <TextField
            //label="Workspace Name"
            placeholder="Workspace Name"
            inputMode="text"
            type="text"
            fullWidth
            variant="standard"
            name="workspaceName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.workspaceName}
            error={!!(formik.touched.workspaceName && formik.dirty && formik.errors.workspaceName) || !!error}
            helperText={(formik.touched.workspaceName && formik.dirty && formik.errors.workspaceName) || error?.message || " "}
            disabled={formik.isSubmitting}
            autoComplete="off"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button disabled={formik.isSubmitting} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" loading={formik.isSubmitting} disabled={!formik.isValid || !formik.dirty}>
            Create Workspace
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
