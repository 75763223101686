
import { DocumentSource, DocumentType } from "../../__generated__/graphql";
import DocIcon from "../Common/images/Sources/Doc.svg";
import DocxIcon from "../Common/images/Sources/Docx.svg";
import GoogleDrive from "../Common/images/Sources/GoogleDrive.svg";
import ManualIcon from "../Common/images/Sources/Manual.svg";
import PdfIcon from "../Common/images/Sources/PDF.svg";
import TxtIcon from "../Common/images/Sources/Txt.svg";

interface IntentionProps {
    source: DocumentSource;
    type: DocumentType;
    intention: 'icon' | 'title';
}
const formatTitle = (title: string) => {
    return title
        .toLowerCase()
        .replace(/_/g, ' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const getSourceIcon = ({ source, type, intention }: IntentionProps) => {
    if (source === 'LOCAL') {
        switch (type) {
            case 'TEXT':
                return intention === 'icon' ? TxtIcon : formatTitle(type);
            case 'DOC':
                return intention === 'icon' ? DocIcon : formatTitle(type);
            case 'DOCX':
                return intention === 'icon' ? DocxIcon : formatTitle(type);
            case 'PDF':
                return intention === 'icon' ? PdfIcon : formatTitle(type);
            default:
                return intention === 'icon' ? ManualIcon : 'Manual';
        }
    } else {
        switch (source) {
            case 'STACKEXCHANGE':
                return intention === 'icon' ? ManualIcon : formatTitle(source);
            case 'GOOGLE_DRIVE':
                return intention === 'icon' ? GoogleDrive : formatTitle(source);
            // Add more cases for other sources if needed
            default:
                return intention === 'icon' ? ManualIcon : formatTitle(source);
        }
    }
};

export default getSourceIcon;