import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import ProgressLine from '../ProgressLine';

interface FirstStepItemProps {
  onLinkSubmit: (link: string) => void;
  onCancel: () => void;
  loading: boolean;
  error?: string | null;
}

const FirstStepItem = ({ onLinkSubmit, onCancel, loading, error }: FirstStepItemProps) => {
  const [link, setLink] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
    setValidationError('');
  };

  const handleSubmit = () => {
    if (isValidGoogleDriveFolderLink(link)) {
      onLinkSubmit(link);
    } else {
      setValidationError('Invalid Google Drive folder link. Please enter a correct link.');
    }
  };

  const isValidGoogleDriveFolderLink = (link: string) => {
    // eslint-disable-next-line
    return /^(https?:\/\/)?(www\.)?drive\.google\.com\/drive\/folders\/[0-9A-Za-z_-]+(?=[\/?]|$)/.test(link);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const displayError = error || validationError;

  return (
    <>
      <ProgressLine currentStep={1} />
      <Typography variant='h6'>
        Google Drive folders links for process
      </Typography>
      <Typography variant='subtitle2'>
        We process only Google Documents, PDFs, DOCX files. Other extensions will be ignored.
      </Typography>

      <TextField
        placeholder='https://drive.google.com/..'
        variant="outlined"
        fullWidth
        sx={{ marginTop: 3 }}
        value={link}
        onChange={handleChange}
        error={!!displayError}
        onKeyDown={handleKeyDown}
        helperText={displayError}
        disabled={loading}
      />
      <Box position={'absolute'} bottom={30} display={'flex'} width={'580px'} justifyContent={'space-between'}>
        <Button variant='contained' sx={{ backgroundColor: 'grey' }} onClick={onCancel} disabled={loading}>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit} disabled={loading}>{loading ? 'Adding...' : 'Add folder ->'}</Button>
      </Box>
    </>
  );
};

export default FirstStepItem;
