import { Alert, Box, Button, List, Typography } from "@mui/material";
import { useState } from "react";
import { ExtendedProcessItem } from "../../../../../../__generated__/graphql";
import ItemCardComponent from "../ItemCardComponent";
import ProgressLine from "../ProgressLine";
import FileListItem from './FilesComponent';

interface ThirdStepItemProps {
    data?: ExtendedProcessItem;
    onBack: () => void;
    loading: boolean;
    error?: string | null;
    onDocumentsSubmit: () => void;
}

const ThirdStepItem = ({ data, onBack, loading, error, onDocumentsSubmit }: ThirdStepItemProps) => {

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCopyLink = async (link: string) => {
        try {
            await navigator.clipboard.writeText(link);
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    const processItem = data;
    const filesWithError = data?.processItemDocument?.filter(file => file.status === 'ERROR');
    const filesReadyForProcessing = data?.processItemDocument?.filter(file => file.status !== 'ERROR');

    return (
        <>
            <ProgressLine currentStep={3} />

            <Typography variant='h6'>
                Get the files list
            </Typography>

            <Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
                The information updates in real-time in our system
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {processItem && (<ItemCardComponent item={processItem} remove={false} inCompleteFlow={false} loadingOnStep={loading} />)}

            {filesReadyForProcessing && filesReadyForProcessing?.length > 0 && <Typography variant='h6' fontSize={15}>Found files in the folder and subfolders</Typography>}
            <List>
                {filesReadyForProcessing?.map(file => (
                    <FileListItem key={file.id} file={file} onCopyLink={handleCopyLink} openSnackbar={openSnackbar} />
                ))}
            </List>

            {filesWithError && filesWithError?.length > 0 && <Typography variant='h6' >Files with errors</Typography>}
            <List>
                {filesWithError?.map(file => (
                    <FileListItem key={file.id} file={file} onCopyLink={handleCopyLink} openSnackbar={openSnackbar} />
                ))}
            </List>


            <Box position={'absolute'} bottom={30} display={'flex'} width={'580px'} justifyContent={'space-between'}>
                <Button
                    variant='contained'
                    disabled={loading}
                    sx={{ backgroundColor: 'grey' }}
                    onClick={() => {
                        onBack();
                    }}
                >
                    Back
                </Button>
                <Button
                    disabled={loading}
                    variant='contained'
                    onClick={onDocumentsSubmit}
                >
                    {`Extract  files content ->`}
                </Button>
            </Box>
        </>
    )

}

export default ThirdStepItem;