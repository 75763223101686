import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

//const INTERCOM_APP_ID = 'o1o21o56'; 

const IntercomChat = () => {
  const { boot, show } = useIntercom();
  // const { workspaceId } = useParams();
  // const { user } = useAuth();

  useEffect(() => {
    boot({
      // name: user?.name ?? '',
      // email: user?.email ?? '',
      // userId: user?.uid ?? '',
      // customAttributes: {
      //   workspaceId: workspaceId,
      // }
    });
    show();
  }, [boot, show]);

  return <></>;
};


export default IntercomChat;