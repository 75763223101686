import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton } from '@mui/material';
import React from 'react';

import GoogleDriveFlow from './GoogleDrive/GoogleDriveFlow';
import HubSpotFlow from './HubSpot/HubSpotFlow';
import { DocumentsCards } from './Integrations';
import NotionFlow from './Notion/NotionFlow';
import SlackFlow from './Slack/SlackFlow';
import ZoomFlow from './Zoom/ZoomFlow';

type IntegrationDialogProps = {
    open: boolean;
    onClose: () => void;
    integration: DocumentsCards | null;
    refetchIntegrations?: () => void;
};

const IntegrationDialog: React.FC<IntegrationDialogProps> = ({ open, onClose, integration, refetchIntegrations }) => {
    const handleClose = () => {
        onClose();
    };

    const renderContent = () => {
        switch (integration?.title) {
            case 'Google Drive':
                return <GoogleDriveFlow onClose={onClose} integration={integration} refetchIntegrations={refetchIntegrations || (() => { })} />;
            case 'Slack':
                return <SlackFlow onClose={onClose} integration={integration} refetchIntegrations={refetchIntegrations || (() => { })} />;
            case 'Zoom':
                return <ZoomFlow onClose={onClose} integration={integration} refetchIntegrations={refetchIntegrations || (() => { })} />;
            case 'Notion':
                return <NotionFlow onClose={onClose} integration={integration} refetchIntegrations={refetchIntegrations || (() => { })} />;
            case 'HubSpot':
                return <HubSpotFlow onClose={onClose} integration={integration} refetchIntegrations={refetchIntegrations || (() => { })} />;
            default:
                return null;
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth PaperProps={{ style: { height: '600px' } }}>
            <Grid container style={{ height: '100%' }}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {renderContent()}
            </Grid>
        </Dialog>
    );
};

export default IntegrationDialog;
