import { CircularProgress, Container, Stack } from "@mui/material";
import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

interface Props {
  children: ReactElement;
}

const ProtectedRoute = ({ children }: Props) => {
  const { user, userLoading } = useAuth();
  const location = useLocation();

  if (userLoading) {
    return (
      <Container maxWidth="sm" sx={{ display: "flex", minHeight: "100%", alignItems: "center" }}>
        <Stack spacing={8} flex={1} direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  if (!user) {
    return <Navigate to="/auth" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
