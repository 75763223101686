import { Alert, Box, Button, LinearProgress, List, Typography } from "@mui/material";
import { useState } from "react";
import { ExtendedProcessItem } from "../../../../../../__generated__/graphql";
import StartLearningImage from '../../../../../Common/images/StartLearningImage.jpg';
import ItemCardComponent from "../ItemCardComponent";
import ProgressLine from "../ProgressLine";
import FileListItem from './FilesComponent';

interface SecondStepItemProps {
    contentToDBData?: ExtendedProcessItem;
    onBack: () => void;
    learnLoading: boolean;
    learnError?: string | null;
    handleLearn: () => void;
}

const FourthStepItem = ({ onBack, learnLoading, learnError, contentToDBData, handleLearn }: SecondStepItemProps) => {

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCopyLink = async (link: string) => {
        try {
            await navigator.clipboard.writeText(link);
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    const processItem = contentToDBData;
    const filesWithError = contentToDBData?.processItemDocument?.filter(file => file.status === 'ERROR');
    const filesReadyForProcessing = contentToDBData?.processItemDocument?.filter(file => file.status !== 'ERROR');

    return (
        <>
            {learnLoading ? (
                <Box display='flex' flexDirection={'column'} alignItems={'center'} justifyContent='center' position='absolute' top="23%" left="40%" gap={2}>
                    <img src={StartLearningImage} alt="Learning" width={400} />
                    <Typography variant='h6'> Learning Process.. </Typography>
                    <LinearProgress variant="indeterminate" sx={{ width: '100%', maxWidth: 400, height: 5, borderRadius: 5 }} />
                </Box>
            ) : (
                <>
                    <ProgressLine currentStep={4} />
                    <Typography variant='h6'>
                        Content extracted
                    </Typography>

                    <Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
                        We process only Google Documents, PDFs, DOCX files. Other extensions will be ignored.
                    </Typography>
                    {learnError && <Alert severity="error">{learnError}</Alert>}
                    {processItem && (<ItemCardComponent item={processItem} remove={false} inCompleteFlow={false} />)}

                    {filesReadyForProcessing && filesReadyForProcessing?.length > 0 && <Typography variant='h6' fontSize={15}>Ready for processing</Typography>}
                    <List>
                        {filesReadyForProcessing?.map(file => (
                            <FileListItem key={file.id} file={file} onCopyLink={handleCopyLink} openSnackbar={openSnackbar} />
                        ))}
                    </List>

                    {filesWithError && filesWithError.length > 0 && <Typography variant='h6' >Files with errors</Typography>}
                    <List>
                        {filesWithError && filesWithError.map(file => (
                            <FileListItem key={file.id} file={file} onCopyLink={handleCopyLink} openSnackbar={openSnackbar} />
                        ))}
                    </List>

                    <Box position={'absolute'} bottom={30} display={'flex'} width={'580px'} justifyContent={'space-between'}>
                        <Button
                            variant='contained'
                            disabled={learnLoading}
                            sx={{ backgroundColor: 'grey' }}
                            onClick={() => {
                                onBack();
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            variant='contained'
                            disabled={learnLoading}
                            onClick={handleLearn}

                        >
                            {`Learn from documents ->`}
                        </Button>
                    </Box>
                </>
            )}
        </>
    )

}

export default FourthStepItem;