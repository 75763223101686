import { useState } from 'react';
import { DocumentsCards } from '../Integrations';
import Cover from './Cover';

interface GoogleDriveFlowProps {
    integration: DocumentsCards;
    refetchIntegrations: () => void;
    onClose: () => void;
}

const SlackFlow: React.FC<GoogleDriveFlowProps> = ({ onClose, integration, refetchIntegrations }) => {
    const initialStep = integration?.integration ? 'folderSelection' : 'cover';
    const [currentStep, setCurrentStep] = useState(initialStep);

    return (
        <>
            {currentStep === 'cover' && <Cover integration={integration} />}
        </>
    );
};

export default SlackFlow;