import { initializeApp } from "firebase/app";
import { getAuth, User } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAuQzuAMBVhmZVPf4aZsyIHsuvWOad9jrA",
  authDomain: "vol-bz.firebaseapp.com",
  projectId: "vol-bz",
  storageBucket: "vol-bz.appspot.com",
  messagingSenderId: "124493740965",
  appId: "1:124493740965:web:c53e6baf1bceda3d48c64c",
  measurementId: "G-5N5LVJ7B3Z",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);

async function getCurrentUser(): Promise<User | null> {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        unsubscribe();
        resolve(user);
      },
      () => {
        resolve(null);
      }
    );
  });
}

export { firebaseApp, auth, getCurrentUser };
