import { Button, Container, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useState } from "react";
import { Form } from "react-router-dom";
import Character from "../Common/images/Character.png";


export function WaitlistDialog({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;

}) {

  //const [user] = useAuthState(auth);

  const [isClicked, setIsClicked] = useState(false);
  const buttonLabel = isClicked ? 'Success' : 'Join the waitlist';
  const buttonColor = isClicked ? '#1ed66e' : 'primary';

  // const email = "alex@volai.app";
  // const subject = `Waitlist Status ${user?.email}`;
  // const body = isClicked ? `The user ${user?.displayName}${user?.email} has joined the waitlist` : "The user has not yet joined";

  const handleClick = () => {
    setIsClicked(true);
    //   const mailtoString = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    //   window.open(mailtoString, "_blank");
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Form style={{ padding: 20 }}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <Typography variant="h5" fontWeight={700} marginBottom={3}>
            Get Early Access to VOL AI
          </Typography>

          <Container sx={{ width: "80%", display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <Typography variant="h6" fontWeight={500} align="center">
              Sign up for early access to VOL AI and be among the first to explore our new workspace.
              We'll let you know when it's ready. Until then, you're welcome to join any workspace you're invited to.
            </Typography>
          </Container>

          <img src={Character} alt="Character" width={100} style={{ padding: 15 }} />

          {/* <Typography style={{ fontWeight: 700, fontSize: 20, color:"#5C6CED" }}>{" " + 34 + " "}</Typography> 
        <Typography variant="h6" fontWeight={500}  align="center"> 
        Visionaries already in line
        </Typography> */}


        </DialogContent>

        <DialogActions sx={{ marginBottom: 3, justifyContent: 'center' }}>
          <Button
            variant="contained"
            size="large"
            type="button"
            style={{ borderRadius: '30px', backgroundColor: buttonColor }}
            onClick={handleClick}

          >
            {buttonLabel}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>

  );
}
