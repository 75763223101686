import { Box, CardContent, Typography } from "@mui/material";
import ExpandableCard from "../../../Common/ExpandableCard";

function titleCaseWord(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1).toLowerCase();
}

export function FieldCard(
  field: string,
  content: Array<[string, unknown]>,
  fieldsExpanded: Set<string>,
  handleFieldExpand: (field: string) => any
) {
  return (
    <ExpandableCard
      key={field}
      expanded={fieldsExpanded.has(field)}
      setExpanded={() => {
        handleFieldExpand(field);
      }}
      title={titleCaseWord(field).replaceAll("_", " ")}
    >
      <CardContent>
        {content.map(([key, value], i) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6"> {key} </Typography>
            <Typography variant="body1">
              {(value as string[]).join(", ")}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </ExpandableCard>
  );
}
