import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";

type CardInfo = {
  title: string;
  subtitle: string;
  Icon: React.ElementType;
};

const SupportCard: React.FC<{ info: CardInfo }> = ({ info }) => (
  <Card sx={{ minWidth: 275 }}>
    <CardContent>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <info.Icon />
        </Grid>
        <Grid item xs>
          <Typography variant="h5" component="h2">
            {info.title}
          </Typography>
          <Typography color="text.secondary">
            {info.subtitle}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
    <CardActions sx={{ ml: '13%', mb: 1 }}>
      <Button size="small" sx={{ background: "#e1e4fc" }} disabled>soon..</Button>
    </CardActions>
  </Card>
);

export default SupportCard;